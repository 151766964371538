import axios from '@/libs/axios'
import { handleCatch } from '@/utils'

export default {
  // eslint-disable-next-line consistent-return
  async downloadExportEligibleCandidatesExam(params) {
    try {
      const response = await axios({
        method: 'get',
        url: 'uni/tnhReports/reportEligibleCandidatesExam',
        responseType: 'blob',
        params,
      })
      if (response.status.toString() === '200') {
        return response.data
      }
      return null
    } catch (e) {
      handleCatch(e)
    }
  },
  // eslint-disable-next-line consistent-return
  async downloadExport(params) {
    try {
      const response = await axios({
        method: 'get',
        url: 'uni/tnhReports/reportStudentEligibleExam',
        responseType: 'blob',
        params,
      })
      if (response.status.toString() === '200') {
        return response.data
      }
      return null
    } catch (e) {
      handleCatch(e)
    }
  },
}
