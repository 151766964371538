<template src="./index.html" />

<script>
import {
  BButton,
  BCol,
  BRow,
  BForm,
  BBreadcrumb,
  BCard,
  BAvatar,
  BBadge,
  BPagination,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BDropdown,
  BDropdownItem,
  VBTooltip,
  ModalPlugin,
  BButtonGroup,
} from 'bootstrap-vue'
import FormRooms from '@/views/admin/category/rooms/FormRooms.vue'
import { VueGoodTable } from 'vue-good-table'
import { STATUSES } from '@/const/status'
import vSelect from 'vue-select'
import { mapActions, mapGetters } from 'vuex'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import '@core/scss/vue/libs/vue-good-table.scss'
import { hasPermissionForResource, isSystemAdmin } from '@/utils'
import { PermissionCode, ResourceCode } from '@/const/code'

export default {
  name: 'Rooms',
  components: {
    VueGoodTable,
    BButton,
    BCol,
    BRow,
    BForm,
    BBreadcrumb,
    BCard,
    BAvatar,
    BBadge,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BDropdown,
    BDropdownItem,
    BButtonGroup,
    ModalPlugin,
    VBTooltip,
    vSelect,
    FormRooms,
  },
  props: {},
  data() {
    return {
      itemsBC: [
        {
          text: 'Admin',
          href: '#',
        },
        {
          text: 'Category',
          href: '#',
        },
        {
          text: 'Rooms',
          active: true,
        },
      ],
      loading: true,
      filter: {
        currentPage: 1,
        itemsPerPage: 10,
        name: null,
        code: null,
        buildingName: null,
        status: null,
      },
      columns: [
        {
          field: 'name',
          label: 'Tên phòng',
          sortable: true,
          type: 'string',
          thClass: 'text-center',
          filterOptions: {
            enabled: true,
            trigger: 'enter',
            placeholder: 'Nhập tên phòng',
          },
        },
        {
          field: 'code',
          label: 'Mã phòng',
          sortable: false,
          type: 'string',
          thClass: 'text-center',
          filterOptions: {
            enabled: true,
            trigger: 'enter',
            placeholder: 'Nhập mã phòng',
          },
        },
        {
          field: 'buildingName',
          label: 'Tòa nhà',
          sortable: true,
          type: 'string',
          thClass: 'text-center',
          filterOptions: {
            enabled: true,
            trigger: 'enter',
            placeholder: 'Nhập tên tòa nhà',
          },
        },
        {
          field: 'maxQty', label: 'Tối đa sinh viên', sortable: true, type: 'number', thClass: 'text-center',
        },
        {
          field: 'orderNo', label: 'Số thứ tự', sortable: true, thClass: 'text-center', tdClass: 'text-center', type: 'number',
        },
        {
          field: 'status',
          label: 'Trạng thái',
          sortable: false,
          thClass: 'text-center',
          filterOptions: {
            enabled: true,
            trigger: 'enter',
            filterDropdownItems: STATUSES.map(status => ({ value: status.value, text: status.label })),
            placeholder: 'Chọn trạng thái',
          },
        },
        {
          field: 'action', sortable: false, thClass: 'text-center', tdClass: 'text-center',
        },
      ],
      dataSendForm: {},
    }
  },
  computed: {
    // calculate data from init data
    ...mapGetters({
      rooms: 'rooms/rooms',
      total: 'rooms/total',
      buildings: 'building/buildings',
    }),
    creatable() {
      return this.isSystemAdmin() || hasPermissionForResource(PermissionCode.CREATE, ResourceCode.ROOMS)
    },
    updatable() {
      return this.isSystemAdmin() || hasPermissionForResource(PermissionCode.UPDATE, ResourceCode.ROOMS)
    },
    deletable() {
      return this.isSystemAdmin() || hasPermissionForResource(PermissionCode.DELETE, ResourceCode.ROOMS)
    },
  },
  async created() {
    await this.getData(this.serverParams)
  },
  methods: {
    ...mapActions({
      getRooms: 'rooms/getRooms',
      createRoom: 'rooms/createRoom',
      updateRoom: 'rooms/updateRoom',
      deleteRoom: 'rooms/deleteRoom',
    }),
    isSystemAdmin,
    updateParams(newProps) {
      this.filter = { ...this.filter, ...newProps }
    },

    async onPageChange(params) {
      this.updateParams({ currentPage: params.currentPage })
      await this.getData()
    },

    async onPerPageChange(params) {
      this.updateParams({ currentPage: 1, itemsPerPage: params.currentPerPage })
      await this.getData()
    },

    async onSortChange(param) {
      if (param.length > 0) {
        const { field, type } = param[0]
        if (field === 'name') this.updateParams({ sort: `1_${type}` })
        else if (field === 'buildingName') this.updateParams({ sort: `2_${type}` })
        else if (field === 'maxQty') this.updateParams({ sort: `3_${type}` })
        else this.updateParams({ sort: `4_${type}` })
      }
      await this.getData()
    },

    async onColumnFilter(param) {
      const { columnFilters } = param
      if (Object.hasOwn(columnFilters, 'name')) this.updateParams({ name: columnFilters.name })
      if (Object.hasOwn(columnFilters, 'code')) this.updateParams({ code: columnFilters.code })
      if (Object.hasOwn(columnFilters, 'buildingName')) this.updateParams({ buildingName: columnFilters.buildingName })
      if (Object.hasOwn(columnFilters, 'status')) this.updateParams({ status: columnFilters.status })
      await this.getData()
    },

    openModal() {
      this.dataSendForm = {
        name: '',
        code: '',
        maxQty: '',
        status: 1,
        roomTypeId: null,
        buildingId: '',
        orderNo: '',
      }
      this.$refs['modal-room'].show()
    },

    edit(data) {
      this.dataSendForm = data
      this.$refs['modal-room'].show()
    },

    deleteRow(data) {
      this.$swal
        .fire({
          title: 'Xóa bản ghi này?',
          text: 'Bạn sẽ không thể khôi phục dữ liệu này sau khi xóa !',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: '<i class="fa-solid fa-check"></i> Đồng ý',
          cancelButtonText: '<i class="fa-solid fa-times"></i> Hủy',
          customClass: {
            confirmButton: 'btn btn-primary',
            cancelButton: 'btn btn-outline-danger ml-1',
          },
          buttonsStyling: false,
        })
        .then(async result => {
          if (result.isConfirmed) {
            const result = await this.deleteRoom(data.id)
            if (result) {
              await this.getData(this.serverParams)
              this.showToast('Đã xóa thành công!', 'CheckIcon', 'success', '')
            } else {
              this.showToast('Có lỗi xẩy ra', 'XCircleIcon', 'danger', '')
            }
          }
        })
    },

    async getData() {
      this.loading = true
      try {
        await this.getRooms(this.filter)
      } catch (e) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: `[${e.code}] ${e.message}`,
            icon: 'XCircleIcon',
            variant: 'danger',
          },
        })
      } finally {
        this.loading = false
      }
    },

    async saveDataForm() {
      let result = {}
      if (this.dataSendForm.id) {
        result = await this.updateRoom(this.dataSendForm)
      } else {
        result = await this.createRoom(this.dataSendForm)
      }
      if (result.code === 1) {
        this.$refs['modal-room'].hide()
        this.$bvModal.hide('modal-room')
        this.showToast(result.message, 'CheckIcon', 'success', '')
      } else {
        this.showToast(result.message, 'XCircleIcon', 'danger', '')
      }
      await this.getData()
    },

    getStatusName(id) {
      return STATUSES.find(status => status.value === id).label
    },

    showToast(title, icon, variant, text) {
      this.$toast({
        component: ToastificationContent,
        position: 'top-right',
        props: {
          title,
          icon,
          variant,
          text,
        },
      })
    },
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
