<template>
  <b-modal
    id="studentPointModal"
    body-class="position-static"
    centered
    :title="`Danh sách điểm sinh viên điểm ${point}`"
    size="xl"
    @shown="onShown"
    :no-close-on-backdrop="true"
  >
    <b-row>
      <b-col cols="12">
        <vue-good-table
          :columns="columns"
          :rows="dataSources"
          :pagination-options="paginationOptions"
          :line-numbers="true"
        >
          <div
            slot="emptystate"
            style="text-align: center; font-weight: bold"
          >
            Không có bản ghi nào !
          </div>
          <template
            slot="table-row"
            slot-scope="props"
          >
            <span v-if="props.column.field === 'gender'">
              {{ getGender(props.row.gender) }}
            </span>

            <!-- Column: Common -->
            <span v-else>{{ props.formattedRow[props.column.field] }}</span>
          </template>

          <!-- pagination -->
          <template
            slot="pagination-bottom"
            slot-scope="props"
          >
            <div class="d-flex justify-content-between flex-wrap">
              <div class="d-flex align-items-center mb-0 mt-1">
                <span class="text-nowrap"> Hiển thị 1 đến </span>
                <b-form-select
                  v-model="itemsPerPage"
                  :options="itemsPerPageOptions"
                  class="mx-1"
                  @input="(value) => props.perPageChanged({ currentPerPage: value })"
                />
                <span class="text-nowrap">của {{ props.total }} bản ghi</span>
              </div>
              <div>
                <b-pagination
                  :value="1"
                  :total-rows="props.total"
                  :per-page="itemsPerPage"
                  class="mt-1 mb-0"
                  @input="(value) => props.pageChanged({ currentPage: value })"
                />
              </div>
            </div>
          </template>
        </vue-good-table>
      </b-col>
    </b-row>
    <template #modal-footer>
      <div class="w-100 d-flex justify-content-end">
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="primary"
          @click="exportExcel"
        >
          <span class="text-nowrap text-right">
            <feather-icon icon="DownloadIcon" /> Xuất excel
          </span>
        </b-button>
      </div>
    </template>

    <b-overlay
      variant="white"
      spinner-variant="primary"
      blur="0"
      opacity=".75"
      rounded="sm"
      no-wrap
      :show="isLoading"
    />
  </b-modal>
</template>

<script>
import {
  BButton,
  BCol,
  BFormSelect,
  BModal,
  BOverlay,
  BPagination,
  BRow,
} from 'bootstrap-vue'
import { GENDERS } from '@/const/status'
import { mapActions, mapGetters } from 'vuex'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { VueGoodTable } from 'vue-good-table'
import Ripple from 'vue-ripple-directive'
import * as XLSX from 'xlsx'

export default {
  name: 'StudentPoint',
  directives: {
    Ripple,
  },
  components: {
    BButton,
    BFormSelect,
    BOverlay,
    BPagination,
    BModal,
    BRow,
    BCol,
    VueGoodTable,
  },
  props: {
    creditClassId: {
      type: Number,
      default: null,
    },
    point: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      isLoading: false,
      itemsPerPage: 20,
      itemsPerPageOptions: [10, 20, 30, 50, 80, 100],
      columns: [
        {
          label: 'Mã sinh viên',
          field: 'code',
          filterOptions: {
            enabled: true,
            trigger: 'enter',
            placeholder: 'Nhập mã sinh viên',
          },
          sortable: true,
          thClass: 'text-center',
        },
        {
          label: 'Họ tên',
          field: 'fullName',
          filterOptions: {
            enabled: true,
            trigger: 'enter',
            placeholder: 'Nhập họ',
          },
          sortable: true,
          thClass: 'text-center',
        },
        {
          label: 'Giới tính',
          field: 'gender',
          filterOptions: {
            enabled: true,
            trigger: 'enter',
            filterDropdownItems: GENDERS.map(status => ({ value: status.value, text: status.label })),
            placeholder: 'Chọn giới tính',
          },
          sortable: false,
          thClass: 'text-center',
        },
        {
          label: 'Ngày sinh',
          field: 'birthday',
          filterOptions: {
            enabled: true,
            trigger: 'enter',
            placeholder: 'Nhập ngày sinh',
          },
          sortable: false,
          thClass: 'text-center',
        },
      ],
    }
  },
  computed: {
    ...mapGetters({
      dataSources: 'creditClassBySubject/students',
    }),
    paginationOptions() {
      return {
        enabled: true,
        perPage: this.itemsPerPage,
      }
    },
  },
  methods: {
    ...mapActions({
      getAlphabetPointCreditClass: 'creditClassBySubject/getAlphabetPointCreditClass',
    }),
    async onShown() {
      this.isLoading = true
      try {
        await this.getAlphabetPointCreditClass({ creditClassId: this.creditClassId, point: this.point })
      } catch (e) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: `[${e.code}] ${e.message}`,
            icon: 'XCircleIcon',
            variant: 'danger',
          },
        })
      } finally {
        this.isLoading = false
      }
    },
    getGender(id) {
      return GENDERS.find(gender => gender.value === id).label
    },
    exportExcel() {
      if (this.dataSources.length === 0) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Không có dữ liệu để xuất!',
            icon: 'InfoIcon',
            variant: 'warning',
          },
        })
        return
      }
      const data = []
      const header = {}
      this.columns.forEach(column => {
        header[column.field] = column.label
      })
      const headerKeys = Object.keys(header)
      const dataSourceKeys = Object.keys(this.dataSources[0])
      const duplicates = dataSourceKeys.filter(val => headerKeys.includes(val))
      duplicates.sort((a, b) => headerKeys.indexOf(a) - headerKeys.indexOf(b))
      this.dataSources.forEach(dataSource => {
        const elm = {}
        duplicates.forEach(key => {
          if (key === 'gender') {
            elm[header[key]] = GENDERS.find(element => element.value === dataSource[key]).label
          } else {
            elm[header[key]] = dataSource[key]
          }
        })
        data.push(elm)
      })
      const ws = XLSX.utils.json_to_sheet(data)
      const wb = XLSX.utils.book_new()
      XLSX.utils.book_append_sheet(wb, ws, 'SinhVien')
      XLSX.writeFile(wb, 'ChiTietSinhVien.xlsx')
    },
  },
}
</script>
