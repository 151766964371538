import axios from '@/libs/axios'
import { ApiCode } from '@/const/api'
import { handleCatch } from '@/utils'

export default {
  namespaced: true,
  state: {
    years: [],
    semesters: [],
    semesterExams: [],
  },
  getters: {
    years: state => state.years,
    semesters: state => state.semesters,
    semesterExams: state => state.semesterExams,
  },
  mutations: {
    SET_YEARS: (state, data) => { state.years = data },
    SET_SEMESTERS: (state, data) => { state.semesters = data },
    SET_SEMESTER_EXAMS: (state, data) => { state.semesterExams = data },
  },
  actions: {
    async getYears({ commit }) {
      try {
        const response = await axios.get('categories/factYears/getAllYears')
        const { data } = response
        if (data.code === ApiCode.SUCCESS) {
          commit('SET_YEARS', data.data)
        }
      } catch (e) {
        handleCatch(e)
      }
    },
    async getSemesters({ commit }) {
      try {
        const response = await axios.get('uni/semesters/getAll')
        const { data } = response
        if (data.code === ApiCode.SUCCESS) {
          commit('SET_SEMESTERS', data.data)
        }
      } catch (e) {
        handleCatch(e)
      }
    },
    async reportSemesterExam({ commit }, params) {
      try {
        const response = await axios.get('uni/reports/reportSemesterExam', { params })
        const { data } = response
        if (data.code === ApiCode.SUCCESS) {
          commit('SET_SEMESTER_EXAMS', data.data)
        }
      } catch (e) {
        handleCatch(e)
      }
    },
    async exportPdf({ commit }, params) {
      try {
        const response = await axios.get('uni/reports/reportSemesterExam/exportPdf', { params, responseType: 'blob' })
        if (response.status === 200) {
          return response.data
        }
      } catch (e) {
        handleCatch(e)
      }
      return null
    },
  },
}
