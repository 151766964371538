<template>
  <b-card no-body>
    <b-card-header>
      <b-card-title class="mb-1">Số lượng sinh viên của các ngành theo từng khóa</b-card-title>
      <b-dropdown
        dropright
        variant="link"
        toggle-class="p-0"
        no-caret
      >
        <template #button-content>
          <feather-icon
            class="text-body"
            icon="FilterIcon"
          />
        </template>
        <b-dropdown-item
          v-for="(item, index) in limitOptions"
          @click="selectOptions(item)"
          :key="index">
          {{ item.label }}
        </b-dropdown-item>
      </b-dropdown>
    </b-card-header>
    
    <b-card-body>
      <vue-apex-charts v-if="dataChart"
        ref="barChart2"
        type="area"
        :options="dataChart"
        :series="studentByMajorAndCoursesSeries"
      />
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard, BCardHeader, BCardBody, BCardTitle, BCardSubTitle, BDropdown, BDropdownItem,
} from 'bootstrap-vue'
import VueApexCharts from 'vue-apexcharts'
import { mapActions, mapGetters } from "vuex";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { LIMIT_OPTION } from "@/const/status";
export default {
  components: {
    BDropdownItem, BDropdown,
    BCard,
    VueApexCharts,
    BCardHeader,
    BCardBody,
    BCardTitle,
    BCardSubTitle,
  },
  props: {
    filter: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      limitOptions: [],
      studentByMajorAndCoursesSeries: [],
      categories: [],
      lineAreaChartDataStudentByMajorAndCoursesChartData: {
        chart: {
          toolbar: {
            show: false,
          },
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          show: false,
          curve: 'straight',
        },
        legend: {
          show: true,
          position: 'top',
          horizontalAlign: 'left',
          fontSize: '12px',
          fontFamily: 'Montserrat',
        },
        grid: {
          xaxis: {
            lines: {
              show: true,
            },
          },
        },
        xaxis: {
          categories: [],
        },
        yaxis: {},
        fill: {
          opacity: 1,
          type: 'solid',
        },
        tooltip: {
          shared: false,
        },
        colors: [chartColors.area.series5, chartColors.area.series4, chartColors.area.series3, chartColors.area.series2, chartColors.area.series1],
      },
      dataChart: {}
    }
  },
  watch: {
    'filter.trainingSystemId': async function () {
      await this.getDataFromStore()
    },
    'filter.yearId': async function () {
      await this.getDataFromStore()
    },
    'filter.semesterId': async function () {
      await this.getDataFromStore()
    }
  },
  computed: {
    ...mapGetters( {
      countStudentByMajorAndCourses1: 'chart/countStudentByMajorAndCourses1',
      countStudentByMajorAndCourses2: 'chart/countStudentByMajorAndCourses2',
      countStudentByMajorAndCourses3: 'chart/countStudentByMajorAndCourses3',
      countStudentByMajorAndCourses4: 'chart/countStudentByMajorAndCourses4',
      coursesName: 'chart/coursesName',
    }),
  },
  async created() {
    this.filter.limit = 5
    await this.getDataFromStore()
  },
  methods: {
    ...mapActions( {
      getStudentByMajorAndCourses: 'chart/getStudentByMajorAndCourses',
    } ),
    async getDataFromStore(){
      this.isLoading = true
      this.limitOptions = LIMIT_OPTION
      try {
        await this.getStudentByMajorAndCourses(this.filter)
        if (this.coursesName.length > 0) {
          
          this.categories = []
          this.lineAreaChartDataStudentByMajorAndCoursesChartData.xaxis.categories = []
          this.coursesName.forEach((item, key) => {
            this.categories.push(this.coursesName[key].name)
            this.lineAreaChartDataStudentByMajorAndCoursesChartData.xaxis.categories.push(this.categories)
          })
          this.lineAreaChartDataStudentByMajorAndCoursesChartData.xaxis.categories = this.categories.reverse()
          this.dataChart = this.lineAreaChartDataStudentByMajorAndCoursesChartData
        }
        if (this.countStudentByMajorAndCourses1.length > 0) {
          this.studentByMajorAndCoursesSeries = []
          for (let i = 0; i < this.countStudentByMajorAndCourses1.length; i++) {
            let data = []
            data.push(this.countStudentByMajorAndCourses1[i].countStudent === null ? 0 : this.countStudentByMajorAndCourses1[i].countStudent)
            data.push(this.countStudentByMajorAndCourses2[i].countStudent === null ? 0 : this.countStudentByMajorAndCourses2[i].countStudent)
            data.push(this.countStudentByMajorAndCourses3[i].countStudent === null ? 0 : this.countStudentByMajorAndCourses3[i].countStudent)
            data.push(this.countStudentByMajorAndCourses4[i].countStudent === null ? 0 : this.countStudentByMajorAndCourses4[i].countStudent)
            this.studentByMajorAndCoursesSeries.push({name: this.countStudentByMajorAndCourses1[i].majorName, data: data})
            this.lineAreaChartDataStudentByMajorAndCoursesChartData.colors.push(this.generateRandomColor())
          }
        }
      } catch (e) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: `[${e.code}] ${e.message}`,
            icon: 'XCircleIcon',
            variant: 'danger',
          },
        })
      } finally {
        this.isLoading = false
      }
    },
    async selectOptions(item) {
      if (item?.value === 0) {
        this.filter.limit = -1
      }
      if (item?.value === 1) {
        this.filter.limit = 5
      }
      if (item?.value === 2) {
        this.filter.limit = 10
      }
      await this.getDataFromStore()
    },
    generateRandomColor() {
      return '#' + Math.floor(Math.random()*16777215).toString(16);
    },
  },
}

const chartColors = {
  area: {
    series4: '#2b9bf4',
    series5: '#FFA1A1',
    series3: '#a4f8cd',
    series2: '#826bf8',
    series1: '#ffe700',
  },
}
</script>
