<template>
  <validation-observer
    ref="saveFormRef"
    #default="{invalid}"
  >
    <b-modal
      id="saveModal"
      body-class="position-static"
      centered
      :title="isCreated ? 'Thêm nhóm đối tượng thực hiện khảo sát/đánh giá' : 'Cập nhật nhóm đối tượng thực hiện khảo sát/đánh giá'"
      size="xl"
      no-close-on-backdrop
      @show="onShow"
      @hide="onHide"
    >
      <b-form>
        <b-row>
          <b-col cols="6">
            <b-form-group label-for="objectGroupType">
              <template v-slot:label>
                Loại nhóm đối tượng <span class="text-danger">*</span>
              </template>
              <validation-provider
                #default="{ errors }"
                name="Loại nhóm đối tượng"
                rules="required"
              >
                <v-select
                  v-model="targetDataSource.objectGroupType"
                  :options="OBJECT_GROUP_TYPES"
                  :reduce="option => option.value"
                  @input="objectGroupTypeChanged($event)"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
        <b-col cols="12">
          <hr>
        </b-col>
        <b-row>
          <b-col cols="6">
            <b-form-group label-for="name">
              <template v-slot:label>
                Tên nhóm đối tượng<span class="text-danger">*</span>
              </template>
              <validation-provider
                #default="{ errors }"
                name="Tên nhóm đối tượng"
                rules="required"
              >
                <b-form-input
                  id="name"
                  v-model="targetDataSource.name"
                  name="name"
                  placeholder="Nhập tên nhóm đối tượng"
                  :state="getElementState(errors)"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col cols="3">
            <b-form-group label-for="code">
              <template v-slot:label>
                Mã nhóm đối tượng<span class="text-danger">*</span>
              </template>
              <validation-provider
                #default="{ errors }"
                name="Mã nhóm đối tượng"
                rules="required"
              >
                <b-form-input
                  id="code"
                  v-model="targetDataSource.code"
                  name="code"
                  placeholder="Nhập mã nhóm đối tượng"
                  :state="getElementState(errors)"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col cols="3">
            <b-form-group label-for="status">
              <template v-slot:label>
                Trạng thái <span class="text-danger">*</span>
              </template>
              <validation-provider
                #default="{ errors }"
                name="Trạng thái"
                rules="required"
              >
                <v-select
                  v-model="targetDataSource.status"
                  :options="statuses"
                  :reduce="option => option.value"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
        <b-form-group
          v-if="targetDataSource.objectGroupType === OBJECT_GROUP_TYPES[0].value"
          label="Sinh viên lớp tín chỉ"
          class="border border-primary rounded p-50"
          label-class="pb-25"
        >
          <template v-slot:label>
            <div class="d-flex">
              Sinh viên lớp tín chỉ <span class="text-danger">*</span>
              <b-form-group
                label-for="allStudentCreditClasses"
                class="ml-2"
              >
                <b-form-checkbox
                  id="allStudentCreditClasses"
                  v-model="targetDataSource.allStudentCreditClasses"
                  value="1"
                  unchecked-value="null"
                >
                  Tất cả sinh viên các lớp chỉ
                </b-form-checkbox>
              </b-form-group>
            </div>
          </template>
          <b-row>
            <b-col
              cols="1"
              class="custom-column"
            >
              <b-form-group label-for="trainingSystemId">
                <template v-slot:label>
                  Hệ đào tạo
                </template>
                <v-select
                  v-model="filterStudentCreditClass.trainingSystemId"
                  :options="trainingSystems"
                  :reduce="option => option.value"
                  placeholder="1.Chọn hệ đào tạo"
                  @input="onChangeTrainingSystemId1($event)"
                >
                  <template #option="{ code, value, label }">
                    <b-button
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      variant="success"
                      size="sm"
                      class="padding-button-custom"
                      @mousedown="preventSelect($event, 'trainingSystem', code, label, value)"
                    >
                      <span>Thêm</span>
                    </b-button>
                    <span> {{ label }}</span>
                  </template>
                </v-select>
              </b-form-group>
            </b-col>
            <b-col
              cols="1"
              class="custom-column"
            >
              <b-form-group label-for="courseId">
                <template v-slot:label>
                  Khoá học
                </template>
                <v-select
                  v-model="filterStudentCreditClass.courseId"
                  :options="courses2Rows"
                  :reduce="option => option.value"
                  placeholder="2.Chọn khoá học"
                  @input="onChangeCourseId1($event)"
                >
                  <template #option="{ code, value, label }">
                    <b-button
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      variant="success"
                      size="sm"
                      class="padding-button-custom"
                      @mousedown="preventSelect($event, 'course', code, label, value)"
                    >
                      <span>Thêm</span>
                    </b-button>
                    <span> {{ label }}</span>
                  </template>
                </v-select>
              </b-form-group>
            </b-col>
            <b-col
              cols="1"
              class="custom-column"
            >
              <b-form-group label-for="departmentId">
                <template v-slot:label>
                  Khoa/bộ môn
                </template>
                <v-select
                  v-model="filterStudentCreditClass.departmentId"
                  :options="departments2Rows"
                  :reduce="option => option.value"
                  placeholder="3.Chọn khoa/bộ môn"
                  @input="onChangeDepartmentId1($event)"
                >
                  <template #option="{ code, value, label }">
                    <b-button
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      variant="success"
                      size="sm"
                      class="padding-button-custom"
                      @mousedown="preventSelect($event, 'department', code, label, value)"
                    >
                      <span>Thêm</span>
                    </b-button>
                    <span> {{ label }}</span>
                  </template>
                </v-select>
              </b-form-group>
            </b-col>
            <b-col
              cols="1"
              class="custom-column"
            >
              <b-form-group label-for="majorId">
                <template v-slot:label>
                  Ngành
                </template>
                <v-select
                  v-model="filterStudentCreditClass.majorId"
                  :options="majors2Rows"
                  :reduce="option => option.value"
                  placeholder="4.Chọn ngành"
                  @input="onChangeMajorId1($event)"
                >
                  <template #option="{ code, value, label }">
                    <b-button
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      variant="success"
                      size="sm"
                      class="padding-button-custom"
                      @mousedown="preventSelect($event, 'major', code, label, value)"
                    >
                      <span>Thêm</span>
                    </b-button>
                    <span> {{ label }}</span>
                  </template>
                </v-select>
              </b-form-group>
            </b-col>
            <b-col
              cols="1"
              class="custom-column"
            >
              <b-form-group label-for="programmeId">
                <template v-slot:label>
                  Chương trình đào tạo
                </template>
                <v-select
                  v-model="filterStudentCreditClass.programmeId"
                  :options="programmes2Rows"
                  :reduce="option => option.value"
                  placeholder="5.Chọn chương trình đào tạo"
                  @input="onChangeProgrammeId1($event)"
                >
                  <template #option="{ code, value, label }">
                    <b-button
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      variant="success"
                      size="sm"
                      class="padding-button-custom"
                      @mousedown="preventSelect($event, 'programme', code, label, value)"
                    >
                      <span>Thêm</span>
                    </b-button>
                    <span> {{ label }}</span>
                  </template>
                </v-select>
              </b-form-group>
            </b-col>
            <b-col
              cols="1"
              class="custom-column"
            >
              <b-form-group label-for="subjectId">
                <template v-slot:label>
                  Môn học
                </template>
                <v-select
                  v-model="filterStudentCreditClass.subjectId"
                  :options="subjects2Rows"
                  :reduce="option => option.value"
                  placeholder="6.Chọn môn học"
                  @input="onChangeSubjectId1($event)"
                >
                  <template #option="{ code, value, label }">
                    <b-button
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      variant="success"
                      size="sm"
                      class="padding-button-custom"
                      @mousedown="preventSelect($event, 'subject', code, label, value)"
                    >
                      <span>Thêm</span>
                    </b-button>
                    <span> {{ label }}</span>
                  </template>
                </v-select>
              </b-form-group>
            </b-col>
            <b-col
              cols="1"
              class="custom-column"
            >
              <b-form-group label-for="creditClassId">
                <template v-slot:label>
                  Lớp tín chỉ
                </template>
                <v-select
                  v-model="filterStudentCreditClass.creditClassId"
                  :options="creditClasses2Rows"
                  :reduce="option => option.value"
                  placeholder="7.Chọn lớp tín chỉ"
                >
                  <template #option="{ code, value, label }">
                    <b-button
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      variant="success"
                      size="sm"
                      class="padding-button-custom"
                      @mousedown="preventSelect($event, 'creditClass', code, label, value)"
                    >
                      <span>Thêm</span>
                    </b-button>
                    <span> {{ label }}</span>
                  </template>
                </v-select>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="12">
              <label for="tags-pills">Các mục đã chọn trên bộ lọc</label>
              <b-form-tags
                v-model="targetDataSource.studentCreditClassTags"
                input-id="tags-pills"
                tag-variant="primary"
                tag-pills
                size="lg"
                separator=" "
                placeholder=""
                @keydown="preventTyping($event)"
                @input="studentCreditClassTagsOnChange"
              />
            </b-col>
          </b-row>
        </b-form-group>
        <b-form-group
          v-if="targetDataSource.objectGroupType === OBJECT_GROUP_TYPES[1].value"
          label="Sinh viên lớp cố định"
          class="border border-primary rounded p-50"
          label-class="pb-25"
        >
          <template v-slot:label>
            <div class="d-flex">
              Sinh viên lớp cố định <span class="text-danger">*</span>
              <b-form-group
                label-for="allStudentClasses"
                class="ml-2"
              >
                <b-form-checkbox
                  id="allStudentClasses"
                  v-model="targetDataSource.allStudentClasses"
                  value="1"
                  unchecked-value="null"
                >
                  Tất cả sinh viên các lớp cố định
                </b-form-checkbox>
              </b-form-group>
            </div>
          </template>
          <b-row
            v-for="(item, index) in targetDataSource.studentClasses"
            :key="index"
          >
            <b-col
              cols="1"
              class="custom-column-2"
            >
              <b-form-group label-for="trainingSystemId">
                <template v-slot:label>
                  Hệ đào tạo
                </template>
                <v-select
                  v-model="item.trainingSystemId"
                  :options="trainingSystems"
                  :reduce="option => option.value"
                  placeholder="1.Chọn hệ đào tạo"
                  @input="onChangeTrainingSystemId2(index, $event)"
                />
              </b-form-group>
            </b-col>
            <b-col
              cols="1"
              class="custom-column-2"
            >
              <b-form-group label-for="courseId">
                <template v-slot:label>
                  Khoá học
                </template>
                <v-select
                  v-model="item.courseId"
                  :options="courses1Rows[index]"
                  :reduce="option => option.value"
                  placeholder="2.Chọn khoá học"
                  @input="onChangeCourseId2(index, $event)"
                />
              </b-form-group>
            </b-col>
            <b-col
              cols="1"
              class="custom-column-2"
            >
              <b-form-group label-for="departmentId">
                <template v-slot:label>
                  Khoa/bộ môn
                </template>
                <v-select
                  v-model="item.departmentId"
                  :options="departments1Rows[index]"
                  :reduce="option => option.value"
                  placeholder="3.Chọn khoa/bộ môn"
                  @input="onChangeDepartmentId2(index, $event)"
                />
              </b-form-group>
            </b-col>
            <b-col
              cols="1"
              class="custom-column-2"
            >
              <b-form-group label-for="majorId">
                <template v-slot:label>
                  Ngành
                </template>
                <v-select
                  v-model="item.majorId"
                  :options="majors1Rows[index]"
                  :reduce="option => option.value"
                  placeholder="4.Chọn ngành"
                  @input="onChangeMajorId2(index, $event)"
                />
              </b-form-group>
            </b-col>
            <b-col
              cols="1"
              class="custom-column-2"
            >
              <b-form-group label-for="classId">
                <template v-slot:label>
                  Lớp cố định
                </template>
                <v-select
                  v-model="item.classId"
                  :options="classes1Rows[index]"
                  :reduce="option => option.value"
                  placeholder="5.Chọn lớp cố định"
                />
              </b-form-group>
            </b-col>
            <b-col
              class="d-flex align-items-center"
            >
              <feather-icon
                icon="Trash2Icon"
                class="ml-50"
                size="18"
                @click="removeStudentClass(index)"
              />
            </b-col>
          </b-row>
          <div>
            <b-col cols="12">
              <hr>
            </b-col>
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="success"
              @click="repeatAgainStudentClass"
            >
              <feather-icon
                icon="PlusIcon"
                class="mr-25"
              />
              <span>Thêm mới</span>
            </b-button>
          </div>
        </b-form-group>
        <b-form-group
          v-if="targetDataSource.objectGroupType === OBJECT_GROUP_TYPES[1].value"
          label="Cán bộ / Giảng viên"
          class="border border-primary rounded p-50"
          label-class="pb-25"
        >
          <template v-slot:label>
            <div class="d-flex">
              Cán bộ / Giảng viên <span class="text-danger">*</span>
              <b-form-checkbox
                id="allEmployees"
                v-model="targetDataSource.allEmployees"
                value="1"
                unchecked-value="null"
                class="ml-2"
              >
                Tất cả các cán bộ (trừ giảng viên)
              </b-form-checkbox>
              <b-form-checkbox
                id="allTeacher"
                v-model="targetDataSource.allTeacher"
                value="1"
                unchecked-value="null"
                class="ml-2"
              >
                Tất cả các giảng viên
              </b-form-checkbox>
            </div>
          </template>
          <b-row
            v-for="(item, index) in targetDataSource.employees"
            :key="index"
          >
            <b-col
              class="custom-column-3"
            >
              <b-form-group label-for="departmentId">
                <template v-slot:label>
                  Khoa/bộ môn
                </template>
                <v-select
                  v-model="item.departmentId"
                  :options="departments"
                  :reduce="option => option.value"
                  placeholder="1.Chọn khoa/bộ môn"
                  @input="onChangeDepartmentId3(index, $event)"
                />
              </b-form-group>
            </b-col>
            <b-col
              class="custom-column-3"
            >
              <b-form-group label-for="teacherTeamId">
                <template v-slot:label>
                  Tổ bộ môn
                </template>
                <v-select
                  v-model="item.teacherTeamId"
                  :options="teacherTeams3Rows[index]"
                  :reduce="option => option.value"
                  placeholder="2.Chọn tổ bộ môn"
                />
              </b-form-group>
            </b-col>
            <b-col
              class="d-flex align-items-center"
            >
              <feather-icon
                icon="Trash2Icon"
                class="ml-50"
                size="18"
                @click="removeEmployee(index)"
              />
            </b-col>
          </b-row>
          <div>
            <b-col cols="12">
              <hr>
            </b-col>
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="success"
              @click="repeatAgainEmployee"
            >
              <feather-icon
                icon="PlusIcon"
                class="mr-25"
              />
              <span>Thêm mới</span>
            </b-button>
          </div>
        </b-form-group>
      </b-form>
      <template #modal-footer>
        <div class="w-100 d-flex justify-content-end">
          <b-button
            v-show="isCreated"
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="mr-1"
            :disabled="invalid"
            @click="onSave"
          >
            <span class="text-right">
              <feather-icon icon="CheckIcon" /> Lưu và tiếp tục
            </span>
          </b-button>

          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="mr-1"
            :disabled="invalid"
            @click="onSave('hide')"
          >
            <span class="text-right">
              <feather-icon icon="CheckIcon" /> Lưu lại
            </span>
          </b-button>

          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="outline-secondary"
            @click="$bvModal.hide('saveModal')"
          >
            <span class="text-right">
              <feather-icon icon="XIcon" /> Hủy
            </span>
          </b-button>
        </div>
      </template>
      <b-overlay
        variant="white"
        spinner-variant="primary"
        blur="0"
        opacity=".75"
        rounded="sm"
        :show="isLoading"
        no-wrap
      />
    </b-modal>
  </validation-observer>
</template>

<script>
import {
  BForm, BModal, BOverlay, BButton, BFormGroup, BFormInput, BRow, BCol, BFormTags, BFormCheckbox,
} from 'bootstrap-vue'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { required } from '@validations'
import { mapMutations, mapActions, mapGetters } from 'vuex'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import Ripple from 'vue-ripple-directive'
import { Flag } from '@/const/flag'
import { STATUSES, CREDIT_CLASS_STATUSES } from '@/const/status'
import { OBJECT_GROUP_TYPES } from '@/const/type'
import vSelect from 'vue-select'
import { getUser } from '@/auth/utils'

export default {
  name: 'WardSave',
  components: {
    BFormCheckbox,
    vSelect,
    BFormInput,
    ValidationProvider,
    BFormGroup,
    BModal,
    BForm,
    BOverlay,
    BButton,
    BRow,
    BCol,
    ValidationObserver,
    BFormTags,
  },
  directives: {
    Ripple,
  },
  props: {
    dataSource: {
      type: Object,
      default: undefined,
    },
  },
  data() {
    return {
      user: getUser(),
      isLoading: false,
      targetDataSource: {
        organizationId: getUser().orgId,
        objectGroupType: OBJECT_GROUP_TYPES[0].value,
        status: Flag.ACTIVE,
        studentClasses: [],
        studentCreditClassTags: [],
        studentCreditClasses: [],
        employees: [],
        allStudentCreditClasses: null,
        allStudentClasses: null,
        allEmployees: null,
        allTeacher: null,
      },
      courses1Rows: [],
      departments1Rows: [],
      majors1Rows: [],
      classes1Rows: [],
      courses2Rows: [],
      departments2Rows: [],
      majors2Rows: [],
      programmes2Rows: [],
      subjects2Rows: [],
      creditClasses2Rows: [],
      teacherTeams3Rows: [],
      required,
      selectedDepartment: null,
      filterStudentCreditClass: {
        courseId: null,
        departmentId: null,
        majorId: null,
        programmeId: null,
        subjectId: null,
        creditClassId: null,
      },
      isInputTagsOnChange: false,
      OBJECT_GROUP_TYPES,
    }
  },
  computed: {
    ...mapGetters({
      trainingSystems: 'dropdown/trainingSystems',
      courses: 'dropdown/courses',
      departments: 'dropdown/departments',
      majors: 'dropdown/majors',
      classes: 'dropdown/classes',
      programmes: 'dropdown/programmes',
      subjects: 'dropdown/subjects',
      creditClasses: 'dropdown/creditClasses',
      teacherTeams: 'dropdown/teacherTeams',
    }),
    statuses() {
      return STATUSES
    },
    isCreated() {
      return !this.dataSource
    },
  },
  methods: {
    ...mapActions({
      createDataSource: 'evaluateObjectGroup/createDataSource',
      updateDataSource: 'evaluateObjectGroup/updateDataSource',
      getTrainingSystems: 'dropdown/getTrainingSystems',
      getCourses: 'dropdown/getCourses',
      getDepartments: 'dropdown/getDepartments',
      getMajors: 'dropdown/getMajors',
      getClasses: 'dropdown/getClasses',
      getProgrammes: 'dropdown/getProgrammes',
      getSubjects: 'dropdown/getSubjects',
      getCreditClasses: 'dropdown/getCreditClasses',
      getTeacherTeams: 'dropdown/getTeacherTeams',
    }),
    ...mapMutations({
      setCourses: 'dropdown/SET_COURSES',
      setDepartments: 'dropdown/SET_DEPARTMENTS',
      setMajors: 'dropdown/SET_MAJORS',
      setClasses: 'dropdown/SET_CLASSES',
      setProgrammes: 'dropdown/SET_PROGRAMMES',
      setSubjects: 'dropdown/SET_SUBJECTS',
      setCreditClasses: 'dropdown/SET_CREDIT_CLASSES',
      setTeacherTeams: 'dropdown/SET_TEACHER_TEAMS',
    }),
    repeatAgainStudentClass() {
      this.targetDataSource.studentClasses.push({
        trainingSystemId: null,
        courseId: null,
        departmentId: null,
        majorId: null,
        classId: null,
      })
    },
    repeatAgainEmployee() {
      this.targetDataSource.employees.push({
        departmentId: null,
        teacherTeamId: null,
      })
    },
    removeStudentClass(index) {
      this.targetDataSource.studentClasses.splice(index, 1)
      this.courses1Rows.splice(index, 1)
      this.departments1Rows.splice(index, 1)
      this.majors1Rows.splice(index, 1)
      this.classes1Rows.splice(index, 1)
    },
    removeEmployee(index) {
      this.targetDataSource.employees.splice(index, 1)
      this.teacherTeams3Rows.splice(index, 1)
    },
    getElementState(errors) {
      return errors.length > 0 ? false : null
    },
    async onChangeTrainingSystemId1(event, onShowPopup = false) {
      this.setCourses(null)
      this.courses2Rows = []
      if (!onShowPopup) {
        this.filterStudentCreditClass.courseId = null
      }
      this.departments2Rows = []
      if (!onShowPopup) {
        this.filterStudentCreditClass.departmentId = null
      }
      this.majors2Rows = []
      if (!onShowPopup) {
        this.filterStudentCreditClass.majorId = null
      }
      this.programmes2Rows = []
      if (!onShowPopup) {
        this.filterStudentCreditClass.programmeId = null
      }
      this.subjects2Rows = []
      if (!onShowPopup) {
        this.filterStudentCreditClass.subjectId = null
      }
      this.creditClasses2Rows = []
      if (!onShowPopup) {
        this.filterStudentCreditClass.creditClassId = null
      }
      if (event != null) {
        await this.getCourses({ organizationId: getUser().orgId, trainingSystemId: event })
        this.courses2Rows = this.courses
        this.$set(this.courses2Rows, this.courses)
      }
    },
    async onChangeCourseId1(event, onShowPopup = false) {
      this.setDepartments(null)
      this.departments2Rows = []
      if (!onShowPopup) {
        this.filterStudentCreditClass.departmentId = null
      }
      this.majors2Rows = []
      if (!onShowPopup) {
        this.filterStudentCreditClass.majorId = null
      }
      this.programmes2Rows = []
      if (!onShowPopup) {
        this.filterStudentCreditClass.programmeId = null
      }
      this.subjects2Rows = []
      if (!onShowPopup) {
        this.filterStudentCreditClass.subjectId = null
      }
      this.creditClasses2Rows = []
      if (!onShowPopup) {
        this.filterStudentCreditClass.creditClassId = null
      }
      if (event != null) {
        await this.getDepartments({ organizationId: getUser().orgId })
        this.departments2Rows = this.departments
        this.$set(this.departments2Rows, this.departments)
      }
    },
    async onChangeDepartmentId1(event, onShowPopup = false) {
      this.setMajors(null)
      this.majors2Rows = []
      if (!onShowPopup) {
        this.filterStudentCreditClass.majorId = null
      }
      this.programmes2Rows = []
      if (!onShowPopup) {
        this.filterStudentCreditClass.programmeId = null
      }
      this.subjects2Rows = []
      if (!onShowPopup) {
        this.filterStudentCreditClass.subjectId = null
      }
      this.creditClasses2Rows = []
      if (!onShowPopup) {
        this.filterStudentCreditClass.creditClassId = null
      }
      if (event != null) {
        await this.getMajors({ organizationId: getUser().orgId, departmentId: event })
        this.majors2Rows = this.majors
        this.$set(this.majors2Rows, this.majors)
      }
    },
    async onChangeMajorId1(event, onShowPopup = false) {
      this.setProgrammes(null)
      this.programmes2Rows = []
      if (!onShowPopup) {
        this.filterStudentCreditClass.programmeId = null
      }
      this.subjects2Rows = []
      if (!onShowPopup) {
        this.filterStudentCreditClass.subjectId = null
      }
      this.creditClasses2Rows = []
      if (!onShowPopup) {
        this.filterStudentCreditClass.creditClassId = null
      }
      if (event != null) {
        await this.getProgrammes({
          organizationId: getUser().orgId,
          trainingSystemId: this.filterStudentCreditClass.trainingSystemId,
          courseId: this.filterStudentCreditClass.courseId,
          departmentId: this.filterStudentCreditClass.departmentId,
          majorId: event,
        })
        this.programmes2Rows = this.programmes
        this.$set(this.programmes2Rows, this.programmes)
      }
    },
    async onChangeProgrammeId1(event, onShowPopup = false) {
      this.setSubjects(null)
      this.subjects2Rows = []
      if (!onShowPopup) {
        this.filterStudentCreditClass.subjectId = null
      }
      this.creditClasses2Rows = []
      if (!onShowPopup) {
        this.filterStudentCreditClass.creditClassId = null
      }
      if (event != null) {
        await this.getSubjects({
          organizationId: getUser().orgId,
          programmeId: event,
        })
        this.subjects2Rows = this.subjects
        this.$set(this.subjects2Rows, this.subjects)
      }
    },
    async onChangeSubjectId1(event, onShowPopup = false) {
      this.setCreditClasses(null)
      this.creditClasses2Rows = []
      if (!onShowPopup) {
        this.filterStudentCreditClass.creditClassId = null
      }
      if (event != null) {
        await this.getCreditClasses({
          organizationId: getUser().orgId,
          subjectId: event,
          status: CREDIT_CLASS_STATUSES[5].value,
          maxStatus: CREDIT_CLASS_STATUSES[5].value,
        })
        this.creditClasses2Rows = this.creditClasses
        this.$set(this.creditClasses2Rows, this.creditClasses)
      }
    },
    async onChangeTrainingSystemId2(index, event, onShowPopup = false) {
      this.setCourses(null)
      this.courses1Rows[index] = []
      if (!onShowPopup) {
        this.targetDataSource.studentClasses[index].courseId = null
      }
      this.departments1Rows[index] = []
      if (!onShowPopup) {
        this.targetDataSource.studentClasses[index].departmentId = null
      }
      this.majors1Rows[index] = []
      if (!onShowPopup) {
        this.targetDataSource.studentClasses[index].majorId = null
      }
      this.classes1Rows[index] = []
      if (!onShowPopup) {
        this.targetDataSource.studentClasses[index].classId = null
      }
      if (event != null) {
        await this.getCourses({ organizationId: getUser().orgId, trainingSystemId: event })
        this.courses1Rows[index] = this.courses
        this.$set(this.courses1Rows, index, this.courses)
      }
    },
    async onChangeCourseId2(index, event, onShowPopup = false) {
      this.setDepartments(null)
      this.departments1Rows[index] = []
      if (!onShowPopup) {
        this.targetDataSource.studentClasses[index].departmentId = null
      }
      this.majors1Rows[index] = []
      if (!onShowPopup) {
        this.targetDataSource.studentClasses[index].majorId = null
      }
      this.classes1Rows[index] = []
      if (!onShowPopup) {
        this.targetDataSource.studentClasses[index].classId = null
      }
      if (event != null) {
        await this.getDepartments({ organizationId: getUser().orgId })
        this.departments1Rows[index] = this.departments
        this.$set(this.departments1Rows, index, this.departments)
      }
    },
    async onChangeDepartmentId2(index, event, onShowPopup = false) {
      this.setMajors(null)
      this.majors1Rows[index] = []
      if (!onShowPopup) {
        this.targetDataSource.studentClasses[index].majorId = null
      }
      this.classes1Rows[index] = []
      if (!onShowPopup) {
        this.targetDataSource.studentClasses[index].classId = null
      }
      if (event != null) {
        await this.getMajors({ organizationId: getUser().orgId, departmentId: event })
        this.majors1Rows[index] = this.majors
        this.$set(this.majors1Rows, index, this.majors)
      }
    },
    async onChangeMajorId2(index, event, onShowPopup = false) {
      this.setClasses(null)
      this.classes1Rows[index] = []
      if (!onShowPopup) {
        this.targetDataSource.studentClasses[index].classId = null
      }
      if (event != null) {
        await this.getClasses({
          organizationId: getUser().orgId,
          trainingSystemId: this.targetDataSource.studentClasses[index].trainingSystemId,
          courseId: this.targetDataSource.studentClasses[index].courseId,
          departmentId: this.targetDataSource.studentClasses[index].departmentId,
          majorId: event,
        })
        this.classes1Rows[index] = this.classes
        this.$set(this.classes1Rows, index, this.classes)
      }
    },
    async onChangeDepartmentId3(index, event, onShowPopup = false) {
      this.setTeacherTeams(null)
      this.teacherTeams3Rows[index] = []
      if (!onShowPopup) {
        this.targetDataSource.employees[index].teacherTeamId = null
      }
      if (event != null) {
        await this.getTeacherTeams({ organizationId: getUser().orgId, departmentId: event })
        this.teacherTeams3Rows[index] = this.teacherTeams
        this.$set(this.teacherTeams3Rows, index, this.teacherTeams)
      }
    },
    async onShow() {
      await this.getTrainingSystems({ organizationId: getUser().orgId })

      if (this.dataSource) {
        this.targetDataSource = structuredClone(this.dataSource)
        this.targetDataSource.studentClasses.forEach((item, index) => {
          if (item.trainingSystemId != null) {
            this.onChangeTrainingSystemId2(index, item.trainingSystemId, true)
          }
          if (item.courseId != null) {
            this.onChangeCourseId2(index, item.courseId, true)
          }
          if (item.departmentId != null) {
            this.onChangeDepartmentId2(index, item.departmentId, true)
          }
          if (item.majorId != null) {
            this.onChangeMajorId2(index, item.majorId, true)
          }
        })
        if (this.targetDataSource.employees?.length > 0) {
          this.setDepartments([])
          await this.getDepartments({ organizationId: getUser().orgId })
        }
        this.targetDataSource.employees.forEach((item, index) => {
          if (item.departmentId != null) {
            this.onChangeDepartmentId3(index, item.departmentId, true)
          }
        })
      } else {
        this.targetDataSource = {
          organizationId: getUser().orgId,
          objectGroupType: OBJECT_GROUP_TYPES[0].value,
          status: Flag.ACTIVE,
          studentClasses: [],
          studentCreditClassTags: [],
          studentCreditClasses: [],
          employees: [],
        }
      }
    },
    onHide() {
      this.$refs
        .saveFormRef
        .reset()
    },
    async objectGroupTypeChanged(event) {
      if(event === OBJECT_GROUP_TYPES[0].value) {
        this.targetDataSource.studentClasses = []
        this.targetDataSource.employees = []
        this.targetDataSource.allStudentClasses = null
        this.targetDataSource.allEmployees = null
        this.targetDataSource.allTeacher = null
      } else {
        this.targetDataSource.studentCreditClassTags = []
        this.targetDataSource.studentCreditClasses = []
        this.targetDataSource.allStudentCreditClasses = null
        this.setDepartments([])
        await this.getDepartments({ organizationId: getUser().orgId })
      }
    },
    checkValidate() {
      let isValid = true

      const arrStudentClass = []
      this.targetDataSource.studentClasses.forEach(item => {
        if (item.trainingSystemId !== null) {
          arrStudentClass.push(`${item.trainingSystemId}/${item.courseId}/${item.departmentId}/${item.majorId}/${item.classId}`)
        }
      })
      if (arrStudentClass.length > 0) {
        const duplicatesStudentClass = arrStudentClass.filter((item, index) => arrStudentClass.indexOf(item) !== index)
        if (duplicatesStudentClass.length > 0) {
          isValid = false
          this.showToast('Sinh viên lớp cố định không thể có 2 nhóm trùng nhau!', 'XCircleIcon', 'danger', 3000)
          return isValid
        }
      }

      const arrEmployee = []
      this.targetDataSource.employees.forEach(item => {
        if (item.departmentId !== null) {
          arrEmployee.push(`${item.departmentId}/${item.teacherTeamId}`)
        }
      })
      if (arrEmployee.length > 0) {
        const duplicatesEmployee = arrEmployee.filter((item, index) => arrEmployee.indexOf(item) !== index)
        if (duplicatesEmployee.length > 0) {
          isValid = false
          this.showToast('Cán bộ / giảng viên không thể có 2 nhóm trùng nhau!', 'XCircleIcon', 'danger', 3000)
          return isValid
        }
      }
      if (this.targetDataSource.objectGroupType === OBJECT_GROUP_TYPES[0].value && this.targetDataSource.allStudentCreditClasses != 1 && this.targetDataSource.studentCreditClassTags.length === 0) {
        isValid = false
        this.showToast('Nhóm đối tượng thực hiện khảo sát/đánh giá bắt buộc phải thiết lập ít nhất 1 nhóm đối tượng con!', 'XCircleIcon', 'danger', 3000)
        return isValid
      }

      if (this.targetDataSource.objectGroupType === OBJECT_GROUP_TYPES[1].value && this.targetDataSource.allStudentClasses != 1 && this.targetDataSource.allEmployees != 1 && this.targetDataSource.allTeacher != 1 && arrStudentClass.length === 0 && arrEmployee.length === 0) {
        isValid = false
        this.showToast('Nhóm đối tượng thực hiện khảo sát/đánh giá bắt buộc phải thiết lập ít nhất 1 nhóm đối tượng con!', 'XCircleIcon', 'danger', 3000)
        return isValid
      }
      return isValid
    },
    async onSave(type = null) {
      if (!this.checkValidate()) return
      const valid = this.$refs
        .saveFormRef
        .validate()
      if (valid) {
        this.isLoading = true
        try {
          const response = this.isCreated
            ? await this.createDataSource(this.targetDataSource)
            : await this.updateDataSource(this.targetDataSource)
          if (response) {
            const { isSuccessful, message } = response
            if (isSuccessful) {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: message,
                  icon: 'CheckIcon',
                  variant: 'success',
                },
              })
              if (type === 'hide') {
                this.$bvModal.hide('saveModal')
              }
              this.$emit('succeed')
            } else {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: message,
                  icon: 'XCircleIcon',
                  variant: 'danger',
                },
              })
            }
          }
        } catch (e) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: `[${e.code}] ${e.message}`,
              icon: 'XCircleIcon',
              variant: 'danger',
            },
          })
        } finally {
          this.isLoading = false
        }
      }
    },
    showToast(title, icon, variant, autoHideDelay = 1000) {
      this.$toast({
        component: ToastificationContent,
        position: 'top-right',
        props: {
          title,
          icon,
          variant,
          autoHideDelay,
        },
      })
    },
    preventSelect(event, subject, code, name, id) {
      // Ngăn chặn sự kiện click lan ra đối tượng cha (v-select)
      event.stopPropagation() // Ngăn sự kiện truyền lên
      event.preventDefault() // Ngăn hành vi mặc định
      const subjectCode = subject
      let item
      switch (subject) {
        case 'trainingSystem':
          item = 'Hệ đào tạo'
          break
        case 'course':
          item = 'Khoá học'
          break
        case 'department':
          item = 'Khoa/bộ phận'
          break
        case 'major':
          item = 'Ngành'
          break
        case 'programme':
          item = 'Chương trình đào tạo'
          break
        case 'subject':
          item = 'Môn học'
          break
        case 'creditClass':
          item = 'Lớp tín chỉ'
          break
        default:
          this.showToast('Loại chủ đề này không xác định!', 'XCircleIcon', 'danger', 3000)
      }
      const itemTag = code && item !== 'Môn học' ? `${item}-${name} [${code}]` : `${item}-${name}`
      // Kiểm tra xem itemTag đã tồn tại trong mảng chưa
      const tagExists = this.targetDataSource.studentCreditClassTags.includes(itemTag)
      if (!tagExists) {
        this.targetDataSource.studentCreditClassTags.push(itemTag)
        this.targetDataSource.studentCreditClasses.push({ subject: subjectCode, id, name })
      }
    },
    preventTyping(event) {
      event.preventDefault() // Ngăn chặn mọi phím nhập
    },
    studentCreditClassTagsOnChange() {
      this.isInputTagsOnChange = true
    },
  },
  watch: {
    'targetDataSource.studentCreditClassTags': async function (newArray, oldArray) {
      if (this.isInputTagsOnChange) {
        // Xác định phần tử bị thiếu (phần tử đã bị xóa)
        const removedElement = oldArray.filter(item => !newArray.includes(item))
        // Nếu có một phần tử bị xóa, tìm chỉ số của nó trong mảng cũ
        if (removedElement.length > 0) {
          const removedElementIndex = oldArray.findIndex(item => item === removedElement[0])

          // Xóa phần tử có chỉ số tương ứng trong mảng `targetArray`
          if (removedElementIndex >= 0 && removedElementIndex < this.targetDataSource.studentCreditClasses.length) {
            this.targetDataSource.studentCreditClasses.splice(removedElementIndex, 1) // Xóa một phần tử tại vị trí `removedElementIndex`
          }
        }
      }
      this.isInputTagsOnChange = false
    },
  },
}
</script>

<style lang="scss" scoped>
::v-deep {
  .custom-column {
    width: 14%;
    flex: 0 0 14%;
    max-width: 14%;
  }

  .custom-column-2 {
    width: 18.5%;
    flex: 0 0 18.5%;
    max-width: 18.5%;
  }

  .custom-column-3 {
    width: 46.5%;
    flex: 0 0 46.5%;
    max-width: 46.5%;
  }

  #tags-pills {
    display: none;
  }

  .vs__dropdown-menu {
    display: flex;         /* Sử dụng Flexbox để điều chỉnh độ rộng */
    flex-direction: column; /* Điều chỉnh hướng của các phần tử con */
    min-width: 100%;       /* Đảm bảo độ rộng tối thiểu bằng 100% của phần tử cha */
    width: auto;           /* Đặt độ rộng tự động */
    box-sizing: border-box; /* Đảm bảo padding và border được tính vào tổng độ rộng */
  }

  /* Điều chỉnh vị trí của menu để nó trông đẹp hơn */
  .vs__dropdown-option {
    white-space: nowrap; /* Ngăn chặn việc xuống dòng */
  }

  /* Định nghĩa hiệu ứng hover cho feather-icon */
    .icon-hover {
      transition: color 0.3s ease; /* Thêm hiệu ứng chuyển màu */
    }

    .icon-hover:hover {
      color: blue; /* Màu khi hover */
    }

    /* Định nghĩa hiệu ứng cho feather-icon khi click */
    .icon-active {
      transition: color 0.1s ease; /* Thêm hiệu ứng chuyển màu */
    }

    .icon-active:active {
      color: red; /* Màu khi click */
    }

    .padding-button-custom {
      padding: 5px;
    }
}
</style>
