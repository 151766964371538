import axios from '@/libs/axios'
import { ApiCode, ApiExtRoutes } from '@/const/api'
import { handleCatch } from '@/utils'
import { COURSE_SEMESTER_STATUSES } from '@/const/status'

export const baseAttr = Object.freeze({
  basePath: ApiExtRoutes.courseSemester,
  maxOrderNoPath: ApiExtRoutes.courseSemesterMaxOrderNo,
})
export default {
  namespaced: true,
  state: {
    dataLists: [],
    totalRows: 0,
    statuses: COURSE_SEMESTER_STATUSES,
    resourceName: {
      fullName: 'Học kỳ theo khóa học',
      shortName: 'Học kỳ',
    },
    maxOrderNo: 0,
    courseSemesters: [],
    total: 0,
    yearWeeks: [],
  },
  getters: {
    dataLists: state => state.dataLists,
    totalRows: state => state.totalRows,
    statuses: state => state.statuses,
    resourceName: state => state.resourceName,
    maxOrderNo: state => state.maxOrderNo,
    courseSemesters: state => state.courseSemesters,
    total: state => state.total,
    yearWeeks: state => state.yearWeeks,
  },
  mutations: {
    SET_LISTS: (state, { total, records }) => {
      state.totalRows = total
      state.dataLists = records
    },
    SET_MAX_ORDER_NO: (state, maxOrderNo) => {
      state.maxOrderNo = maxOrderNo
    },

    SET_COURSE_SEMESTERS: (state, courseSemesters) => {
      state.courseSemesters = courseSemesters
    },

    SET_TOTAL: (state, total) => {
      state.total = total
    },
    SET_YEAR_WEEK: (state, yearWeeks) => {
      state.yearWeeks = yearWeeks
    },
  },

  actions: {
    async getData({ commit }, params) {
      try {
        const response = await axios.get(baseAttr.basePath, { params })
        const { data } = response
        if (data.code === ApiCode.SUCCESS) {
          const {
            total,
            records,
          } = data.data
          commit('SET_LISTS', {
            total,
            records,
          })
        }
      } catch (e) {
        handleCatch(e)
      }
    },

    async getCourseSemesters({ commit }, params) {
      try {
        const response = await axios.get(`${baseAttr.basePath}/read_config_register`, { params })
        const { data } = response
        if (data.code === ApiCode.SUCCESS) {
          const {
            total,
            records,
          } = data.data
          commit('SET_COURSE_SEMESTERS', records)
          commit('SET_TOTAL', total)
        }
      } catch (e) {
        handleCatch(e)
      }
    },
    // eslint-disable-next-line no-unused-vars
    async createData(commit, params) {
      try {
        const response = await axios.post(baseAttr.basePath, params)
        const { data } = response
        return {
          isSuccessful: data.code === ApiCode.SUCCESS,
          message: data.message,
        }
      } catch (e) {
        handleCatch(e)
      }
      return null
    },
    // eslint-disable-next-line no-unused-vars
    async updateData({ commit }, params) {
      try {
        const response = await axios.put(`${baseAttr.basePath}/${params.id}`, params)
        const { data } = response
        return {
          isSuccessful: data.code === ApiCode.SUCCESS,
          message: data.message,
        }
      } catch (e) {
        handleCatch(e)
      }
      return null
    },
    // eslint-disable-next-line no-unused-vars
    async deleteData({ commit }, id) {
      try {
        const response = await axios.delete(`${baseAttr.basePath}/${id}`)
        const { data } = response
        return {
          isSuccessful: data.code === ApiCode.SUCCESS,
          message: data.message,
        }
      } catch (e) {
        handleCatch(e)
      }
      return null
    },
    async getMaxOrderNo({ commit }, id) {
      try {
        const response = await axios.get(`${baseAttr.maxOrderNoPath}${id || ''}`)
        const { data } = response
        if (data.code === ApiCode.SUCCESS) {
          const { maxOrderNo } = data.data
          commit('SET_MAX_ORDER_NO', maxOrderNo)
        }
      } catch (e) {
        handleCatch(e)
      }
      return null
    },
    // eslint-disable-next-line no-unused-vars
    async bulkUpdateSchoolYears({ commit }, params) {
      try {
        const response = await axios.put(`${baseAttr.basePath}/school_years/`, params)
        const { data } = response
        return {
          isSuccessful: data.code === ApiCode.SUCCESS,
          message: data.message,
        }
      } catch (e) {
        handleCatch(e)
      }
      return null
    },
    async getYearWeek({ commit }, params) {
      try {
        const response = await axios.get(`${baseAttr.basePath}/get_week`, { params })
        const { data } = response
        if (data.code === ApiCode.SUCCESS) {
          commit('SET_YEAR_WEEK', data.data)
        }
      } catch (e) {
        handleCatch(e)
      }
    },
  },
}
