<template>
  <div class="row">
    <b-container fluid>
      <b-card no-body>
        <b-overlay
          :show="isLoading"
          rounded="sm"
        >
          <div class="m-2">
            <b-row>
              <b-col cols="5">
                <b-row>
                  <b-col cols="12">
                    <b-form-group
                      label-cols="3"
                      label-cols-lg="3"
                      label="1.Đợt xét tuyển:"
                      label-for="admissionRoundSelected"
                    >
                      <v-select
                        v-model="admissionRoundSelected"
                        :options="admissionRounds"
                        :reduce="(option) => option.value"
                        :clearable="false"
                        placeholder="1.Đợt xét tuyển"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col cols="12">
                    <b-form-group
                      label-cols="3"
                      label-cols-lg="3"
                      label="2.Phương thức xét tuyển:"
                      label-for="admissionFormSelected"
                    >
                      <v-select
                        v-model="admissionFormSelected"
                        :options="dataListAdmissionForm"
                        :reduce="(option) => option.value"
                        :clearable="false"
                        placeholder="2.Phương thức xét tuyển"
                      />
                    </b-form-group>
                  </b-col>
                </b-row>
              </b-col>
              <b-col cols="7">
                <b-row>
                  <b-col cols="12">
                    <b-form-group
                      label-cols="2"
                      label-cols-lg="2"
                      label="3.Ngành xét tuyển:"
                      label-for="admissionMajorSelected"
                    >
                      <v-select
                        v-model="admissionMajorSelected"
                        :options="dataListMajorByAdmissionForm"
                        :reduce="(option) => option.value"
                        placeholder="3.Ngành xét tuyển:"
                      />
                    </b-form-group>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>

            <validation-observer
              ref="studentExaminationPointFormRef"
              #default="{invalid}"
            >
              <b-row>
                <b-col cols="12">
                  <div class="text-right">
                    <b-button
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      variant="success"
                      style="margin-right: 5px"
                      @click="onSelectStudentExaminationRoom"
                    >
                      <span class="text-nowrap text-right">Xem danh sách</span>
                    </b-button>
                    <b-button
                      v-show="!checkedShowSave"
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      variant="primary"
                      :disabled="invalid"
                      @click="onSave(codeByAdmissionMajor)"
                    >
                      <span class="text-nowrap text-right">
                        <feather-icon icon="CheckIcon" /> Lưu lại
                      </span>
                    </b-button>
                  </div>
                </b-col>
              </b-row>
              <b-row class="mt-2">
                <b-col cols="12">
                  <vue-good-table
                    mode="remote"
                    :columns="visibleColumns"
                    :rows="codeByAdmissionMajor"
                    :pagination-options="paginationOptions"
                    :line-numbers="true"
                    class="my-table"
                    style-class="my-table vgt-table striped bordered"
                  >
                    <div
                      slot="emptystate"
                      style="text-align: center; font-weight: bold"
                    >
                      Không có bản ghi nào !
                    </div>
                    <template slot="table-row" slot-scope="props">
                      <span
                        v-show="!(props.row.admissionFormTypeCode === 'TUYENTHANG')"
                        v-if="['standardScore', 'acceptedCountTemp'].includes(props.column.field)"
                      >
                        <validation-provider
                          #default="{ errors }"
                          :rules="getValidationRules(props.column.field)"
                          :name="getFieldName(props.column.field)"
                        >
                          <b-form-input
                            v-model="props.row[props.column.field]"
                            :tabindex="props.row.originalIndex + 1"
                            @change="
                              changeCell(
                                props.row[props.column.field],
                                props.column.field,
                                props.row.originalIndex
                              )
                            "
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </span>
                      <span v-else>{{ props.formattedRow[props.column.field] }}</span>
                    </template>
                  </vue-good-table>
                </b-col>
              </b-row>
            </validation-observer>
          </div>
        </b-overlay>
      </b-card>
    </b-container>
  </div>
</template>

<script>
import {
  BButton,
  BCard,
  BCol,
  BContainer,
  BFormGroup,
  BOverlay,
  BRow,
  BFormInput,
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import vSelect from 'vue-select'
import { mapActions, mapGetters } from 'vuex'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import '@core/scss/vue/libs/vue-good-table.scss'
import Ripple from 'vue-ripple-directive'
import { hasPermissionForResource, isSystemAdmin } from '@/utils'
import { PermissionCode, ResourceCode } from '@/const/code'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { between, required } from '@core/utils/validations/validations'
import { getUser } from '@/auth/utils'

export default {
  name: 'StudentExaminationPoint',
  directives: {
    Ripple,
  },
  components: {
    ValidationObserver,
    ValidationProvider,
    BButton,
    BFormGroup,
    BCard,
    BCol,
    BContainer,
    BOverlay,
    BRow,
    VueGoodTable,
    vSelect,
    BFormInput,
  },
  data() {
    return {
      admissionRoundSelected: undefined,
      admissionFormSelected: undefined,
      admissionMajorSelected: undefined,
      isLoading: false,
      filter: {
        admissionRoundId: null,
        admissionFormId: null,
        admissionMajorId: null,
        isBlock: 0,
      },
      paginationOptions: {
        enabled: false,
      },
      columns: [
        {
          label: 'Phương thức xét tuyển',
          field: 'admissionFormName',
          sortable: false,
          thClass: 'text-center',
          tdClass: 'text-center',
        },
        {
          label: 'Ngành xét tuyển',
          field: 'majorName',
          sortable: false,
          thClass: 'text-center',
          tdClass: 'text-center',
        },
        {
          label: 'Ngành - phương thức tuyển sinh',
          field: 'id',
          sortable: false,
          thClass: 'text-center',
          tdClass: 'text-center',
          hide: true
        },
        {
          label: 'Chỉ tiêu đưa ra',
          field: 'quota',
          sortable: false,
          thClass: 'text-center',
          tdClass: 'text-center',
        },
        {
          label: 'Số thí sinh trúng tuyển',
          field: 'acceptedCount',
          sortable: false,
          thClass: 'text-center',
          tdClass: 'text-center',
        },
        {
          label: 'Số thí sinh trúng tuyển tương ứng điểm chuẩn',
          field: 'acceptedCountTemp',
          sortable: false,
          thClass: 'text-center',
          tdClass: 'text-center',
        },
        {
          label: 'Điểm chuẩn',
          field: 'standardScore',
          sortable: false,
          thClass: 'text-center',
          tdClass: 'text-center',
        },
      ],
      required,
      between,
      checkedShowSave: false,
    }
  },
  computed: {
    visibleColumns() {
      return this.columns.filter(column => !column.hide);
    },
    ...mapGetters({
      codeByAdmissionMajor: 'admissionStandardScore/codeByAdmissionMajor',
      dataListMajorByAdmissionForm: 'admissionMajor/dataListMajorByAdmissionForm',
      admissionRounds: 'admissionRound/admissionRoundsByOrganization',
      dataListAdmissionForm: 'admissionForm/admissionFormByAdmissionRoundId',
      codeOfAdmissionForm: 'admissionForm/codeAdmissionForm',
      countAcceptTemp: 'admissionStandardScore/countAcceptTemp',
    }),
    standardScoreUpdate() {
      return isSystemAdmin() || hasPermissionForResource(PermissionCode.UPDATE, ResourceCode.ADMISSION_STANDARD_SCORE)
    },
  },
  watch: {
    async admissionRoundSelected(val) {
      this.admissionFormSelected = null
      this.admissionMajorSelected = null
      if (!val) {
        this.reset()
        this.$store.commit('admissionExaminationRoom/SET_ADMISSION_FORMS', { data: [] })
        this.$store.commit('admissionMajor/SET_ADMISSION_MAJORS', { data: [] })
      } else {
        await this.readAdmissionFormByAdmissionRoundId({ admissionRoundId: val })
        if (this.admissionFormId) {
          const found = this.dataListAdmissionForm.find(element => +element.id === +this.admissionFormId)
          if (found) this.admissionFormSelected = this.admissionFormId
        }
      }
    },
    async admissionFormSelected(val) {
      this.admissionMajorSelected = null
      if (!val) {
        this.filter.admissionFormId = null
        this.filter.admissionMajorId = null
        this.$store.commit(
          'admissionStandardScore/SET_CODE_BY_ADMISSION_MAJOR',
          { data: [] },
        )
        this.$store.commit('admissionMajor/SET_ADMISSION_MAJORS', { data: [] })
      } else {
        await this.getMajorByAdmissionFormId({
          admissionFormId: this.admissionFormSelected,
        })
      }
      this.filter.admissionRoundId = this.admissionRoundSelected
      this.filter.admissionFormId = this.admissionFormSelected
      this.filter.admissionMajorId = this.admissionMajorSelected
      await this.codeAdmissionForm({ admissionFormId: this.filter.admissionFormId })
      this.checkedShowSave = this.codeOfAdmissionForm[0].factAdmissionFormTypeCode === 'TUYENTHANG'
      await this.getCodeByAdmissionMajor(this.filter)
    },
    async admissionMajorSelected(val) {
      if (!val) {
        this.filter.admissionMajorId = null
        this.$store.commit(
          'admissionStandardScore/SET_CODE_BY_ADMISSION_MAJOR',
          { data: [] },
        )
      } else {
        this.filter.admissionRoundId = this.admissionRoundSelected
        this.filter.admissionFormId = this.admissionFormSelected
        this.filter.admissionMajorId = this.admissionMajorSelected
        await this.getCodeByAdmissionMajor(this.filter)
      }
    },
  },
  async created() {
    this.reset()
    this.isLoading = true
    try {
      await this.getAdmissionRoundsByOrganization({ organizationId: getUser().orgId })
      if (this.admissionRoundId) {
        this.admissionRoundSelected = this.admissionRoundId
      }
    } catch (e) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: `[${e.code}] ${e.message}`,
          icon: 'XCircleIcon',
          variant: 'danger',
        },
      })
    } finally {
      this.isLoading = false
    }
  },
  methods: {
    ...mapActions({
      getCodeByAdmissionMajor: 'admissionStandardScore/getCodeByAdmissionMajor',
      getMajorByAdmissionFormId: 'admissionMajor/getMajorByAdmissionFormId',
      readAdmissionFormByAdmissionRoundId: 'admissionForm/readAdmissionFormByAdmissionRoundId',
      getAdmissionRoundsByOrganization: 'admissionRound/readAdmissionRoundsByOrganization',
      updateData: 'admissionStandardScore/updateData',
      codeAdmissionForm: 'admissionForm/getCodeByAdmissionForm',
      getCountAcceptTemp: 'admissionStandardScore/getCountAcceptTemp',
    }),
    reset() {
      this.filter.admissionRoundId = 0
      this.filter.admissionFormId = 0
      this.filter.admissionMajorId = 0
      this.$store.commit(
        'admissionStandardScore/SET_CODE_BY_ADMISSION_MAJOR',
        { data: [] },
      )
    },
    getValidationRules(field) {
      if (field === 'standardScore' || field === 'acceptedCountTemp') {
        return 'required|between:0,30'
      }
      return ''
    },
    getFieldName(field) {
      if (field === 'standardScore' || field === 'acceptedCountTemp') {
        return 'Điểm'
      }
      return ''
    },
    async onSelectStudentExaminationRoom() {
      this.isLoading = true
      try {
        await this.getCodeByAdmissionMajor(this.filter)
      } catch (e) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: `[${e.code}] ${e.message}`,
            icon: 'XCircleIcon',
            variant: 'danger',
          },
        })
      } finally {
        this.isLoading = false
      }
    },
    async changeCell(data, column, row) {
      this.codeByAdmissionMajor[row][column] = data
      if ( column === 'standardScore' ) {
        this.codeByAdmissionMajor[row]['acceptedCountTemp'] = await this.caculateAcceptCountTemp(this.codeByAdmissionMajor[row]['id'], data);
      }
    },
    async caculateAcceptCountTemp(admissionMajorFormId, standardScore) {
      await this.getCountAcceptTemp({
        id: admissionMajorFormId,
        acceptedCountTemp: standardScore,
      })
      return this.countAcceptTemp;
    },
    async onSave(data) {
      if (!this.standardScoreUpdate) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Bạn không có quyền nhập điểm!',
            icon: 'InfoIcon',
            variant: 'warning',
          },
        })
        return
      }
      if (data.some(element => element.standardScore === null || element.standardScore === undefined)) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Vui lòng nhập đầy đủ dữ liệu điểm!',
            icon: 'InfoIcon',
            variant: 'warning',
          },
        })
        return
      }
      if (data.some(element => isNaN(element.standardScore))) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Vui lòng nhập đúng kiểu dữ liệu điểm!',
            icon: 'InfoIcon',
            variant: 'warning',
          },
        })
        return
      }
      if (data.some(element => element.standardScore < 0 || element.standardScore > 30)) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Điểm chỉ được nằm trong khoảng từ 0 đến 30!',
            icon: 'InfoIcon',
            variant: 'warning',
          },
        })
        return
      }
      this.isLoading = true
      const updateStandardScoreEntityReqDtos = data.map(element => ({
        admissionMajorFormId: element.id,
        standardScore: element.standardScore
      }))
      try {
        const response = await this.updateData({
          updateStandardScoreEntityReqDtos
        })
        if (response) {
          const { isSuccessful, message } = response
          if (isSuccessful) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: message,
                icon: 'CheckIcon',
                variant: 'success',
              },
            })
            await this.getCodeByAdmissionMajor(this.filter)
          } else {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: message,
                icon: 'XCircleIcon',
                variant: 'danger',
              },
            })
          }
        }
      } catch (e) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: `[${e.code}] ${e.message}`,
            icon: 'XCircleIcon',
            variant: 'danger',
          },
        })
      } finally {
        this.isLoading = false
      }
    },
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
