import axios from '@/libs/axios'
import {handleCatch} from '@/utils'

export default {
  // eslint-disable-next-line consistent-return
  async downloadExportTNH(params) {
    try {
      return await axios({
        method: 'get',
        url: 'uni/tnhReports/reportGraduationTNH',
        responseType: 'blob',
        params,
      })
    } catch (e) {
      handleCatch(e)
    }
  },
}
