<template>
  <b-modal
    id="planCreditClassModal"
    body-class="position-static"
    centered
    title="Tạo danh sách lớp độc lập"
    size="xl"
    no-close-on-backdrop
    @show="onShow"
  >
    <b-row class="mt-2">
      <b-col cols="12">
        <vue-good-table
          :columns="columns"
          :rows="dataSources"
          :pagination-options="paginationOptions"
          :select-options="selectOptions"
          :line-numbers="true"
          @on-selected-rows-change="selectionChanged"
        >
          <div
            slot="emptystate"
            style="text-align: center; font-weight: bold"
          >
            Không có bản ghi nào !
          </div>
          <template
            slot="table-row"
            slot-scope="props"
          >
            <span v-if="props.column.field === 'copyNum'">
              <validation-provider
                #default="{ errors }"
                rules="required|between:0,10"
                name="Số lớp"
              >
                <b-form-input
                  v-model="props.row.copyNum"
                  :tabindex="props.row.originalIndex + 1"
                  type="number"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </span>
            <span v-else-if="props.column.field === 'creditClassNum'">
              <b-form-input
                v-model="props.row.creditClassNum"
                :tabindex="props.row.originalIndex + 1"
                type="number"
              />
            </span>
            <span v-else-if="props.column.field === 'shouldAddPracticeClass'">
              <template v-if="props.row.shouldAddPracticeClass === 1">
                <b-form-input
                  v-model="props.row.practiceCreditClassNum"
                  :tabindex="props.row.originalIndex + 1"
                  type="number"
                />
              </template>
              <template v-else>
                <span>0</span>
              </template>
            </span>
            <span
              v-else-if="props.column.field === 'syncLms'"
              class="d-flex align-items-center"
            >
              <b-form-checkbox
                v-model="props.row.syncLms"
                :value="1"
                :unchecked-value="0"
              >
                Lớp chính
              </b-form-checkbox>
              <b-form-checkbox
                v-if="props.row.practiceCreditClassNum > 0"
                v-model="props.row.practiceSyncLms"
                class="ml-1"
                :value="1"
                :unchecked-value="0"
              >
                Thực hành
              </b-form-checkbox>
            </span>
            <span v-else-if="props.column.field === 'className'">
              <Select2
                v-model="props.row.classIds"
                :options="classOptions"
                :settings="select2Settings"
              />
            </span>
            <!-- Column: Common -->
            <span v-else>{{ props.formattedRow[props.column.field] }}</span>
          </template>

          <!-- pagination -->
          <template
            slot="pagination-bottom"
            slot-scope="props"
          >
            <div class="d-flex justify-content-between flex-wrap">
              <div class="d-flex align-items-center mb-0 mt-1">
                <span class="text-nowrap"> Hiển thị 1 đến </span>
                <b-form-select
                  v-model="itemsPerPage"
                  :options="itemsPerPageOptions"
                  class="mx-1"
                  @input="(value) => props.perPageChanged({ currentPerPage: value })"
                />
                <span class="text-nowrap">của {{ props.total }} bản ghi</span>
              </div>
              <div>
                <b-pagination
                  :value="1"
                  :total-rows="props.total"
                  :per-page="itemsPerPage"
                  class="mt-1 mb-0"
                  @input="(value) => props.pageChanged({ currentPage: value })"
                />
              </div>
            </div>
          </template>
        </vue-good-table>
      </b-col>
    </b-row>
    <template #modal-footer>
      <div class="w-100 d-flex justify-content-end">
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="primary"
          class="mr-1"
          @click="onCreateCreditClasses"
        >
          <span class="text-right">
            <feather-icon icon="CheckIcon" /> Tạo lớp độc lập
          </span>
        </b-button>

        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="outline-secondary"
          @click="$bvModal.hide('planCreditClassModal')"
        >
          <span class="text-right">
            <feather-icon icon="XIcon" /> Hủy
          </span>
        </b-button>
      </div>
    </template>
    <b-overlay
      no-wrap
      variant="white"
      spinner-variant="primary"
      blur="0"
      opacity=".75"
      rounded="sm"
      :show="isLoading"
    />
  </b-modal>
</template>

<script>
import {
  BButton,
  BCol,
  BFormInput,
  BFormSelect, BModal,
  BOverlay,
  BPagination,
  BRow,
  BFormCheckbox,
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import { mapActions, mapGetters } from 'vuex'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import '@core/scss/vue/libs/vue-good-table.scss'
import Ripple from 'vue-ripple-directive'
import { ValidationProvider } from 'vee-validate'
import Select2 from 'v-select2-component'

export default {
  name: 'CreditClassBulkSave',
  directives: {
    Ripple,
  },
  components: {
    BButton,
    BModal,
    BFormInput,
    ValidationProvider,
    BCol,
    BFormSelect,
    BOverlay,
    BPagination,
    BRow,
    VueGoodTable,
    BFormCheckbox,
    Select2,
  },
  props: {
    courseId: {
      type: Number,
      default: null,
    },
    courseSemesterId: {
      type: Number,
      default: null,
    },
    majorId: {
      type: Number,
      default: null,
    },
    programmeId: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      isLoading: false,
      itemsPerPage: 10,
      itemsPerPageOptions: [10, 20, 30, 50, 80, 100],
      isCopy: false,
      selectedRows: [],
      selectOptions: {
        enabled: true,
        selectOnCheckboxOnly: true,
        selectionText: 'lớp được chọn',
        clearSelectionText: 'Bỏ chọn',
        defaultChecked: true,
      },
      select2Settings: {
        multiple: true,
      },
    }
  },
  computed: {
    ...mapGetters({
      dataSources: 'creditClass/planDataLists',
      resourceName: 'creditClass/resourceName',
      classes: 'creditClass/classes',
    }),
    classOptions() {
      return this.classes.map(element => ({ id: element.id, text: element.name }))
    },
    columns() {
      return [
        {
          label: 'Tên môn học',
          field: 'subjectName',
          filterOptions: {
            enabled: false,
          },
          sortable: false,
          thClass: 'text-center',
        },
        {
          label: 'Khóa học',
          field: 'courseName',
          filterOptions: {
            enabled: false,
          },
          sortable: false,
          thClass: 'text-center',
        },
        {
          label: 'Học kỳ',
          field: 'semesterName',
          filterOptions: {
            enabled: false,
          },
          sortable: false,
          thClass: 'text-center',
        },
        {
          label: 'Thêm sinh viên từ lớp ưu tiên',
          field: 'className',
          filterOptions: {
            enabled: false,
          },
          sortable: false,
          thClass: 'text-center',
        },
        {
          label: 'Số lượng lớp chính',
          width: '8%',
          field: 'creditClassNum',
          filterOptions: {
            enabled: false,
          },
          sortable: false,
          thClass: 'text-center',
        },
        {
          label: 'Số lượng lớp thực hành',
          width: '8%',
          field: 'shouldAddPracticeClass',
          filterOptions: {
            enabled: false,
          },
          sortable: false,
          thClass: 'text-center',
        },
        {
          label: 'Đồng bộ lớp LMS',
          field: 'syncLms',
          sortable: false,
          thClass: 'text-center',
        },
      ]
    },
    paginationOptions() {
      return {
        enabled: true,
        perPage: this.itemsPerPage,
      }
    },
  },
  methods: {
    ...mapActions({
      getClassesByCourseIdAndMajorId: 'creditClass/getClassesByCourseIdAndMajorId',
      getDataSources: 'creditClass/getPlanData',
      createPlanData: 'creditClass/createPlanData',
    }),
    async onShow() {
      this.isLoading = true
      try {
        await Promise.all([
          this.getClassesByCourseIdAndMajorId({ courseId: this.courseId, majorId: this.majorId }),
          this.getDataSources({ courseSemesterId: this.courseSemesterId, programmeId: this.programmeId }),
        ])
        for (let i = 0; i < this.dataSources.length; i++) {
          this.$set(this.dataSources[i], 'vgtSelected', true)
        }
      } catch (e) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: `[${e.code}] ${e.message}`,
            icon: 'XCircleIcon',
            variant: 'danger',
          },
        })
      } finally {
        this.isLoading = false
      }
    },
    async getDataSourcesFromStore() {
      this.isLoading = true
      try {
        await this.getDataSources({ courseSemesterId: this.courseSemesterId, programmeId: this.programmeId })
      } catch (e) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: `[${e.code}] ${e.message}`,
            icon: 'XCircleIcon',
            variant: 'danger',
          },
        })
      } finally {
        this.isLoading = false
      }
    },
    selectionChanged(params) {
      this.selectedRows = params ? params.selectedRows : []
    },
    onCreateCreditClasses() {
      if (this.selectedRows.length <= 0) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Vui lòng chọn lớp cần tạo theo danh sách!',
            icon: 'InfoIcon',
            variant: 'warning',
          },
        })
        return
      }
      this.$swal({
        title: 'Bạn chắc chắn muốn tạo lớp độc lập theo danh sách dự kiến này?',
        text: 'Bạn có thể cập nhật thông tin và sinh viên trong lớp sau khi tạo!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: '<i class="fa-solid fa-check"></i> Đồng ý',
        cancelButtonText: '<i class="fa-solid fa-times"></i> Hủy',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(async result => {
        if (result.value) {
          this.isLoading = true
          try {
            const response = await this.createPlanData({ creditClasses: this.selectedRows })
            if (response) {
              const { isSuccessful, message } = response
              if (isSuccessful) {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: message,
                    icon: 'CheckIcon',
                    variant: 'success',
                  },
                })
                this.$bvModal.hide('planCreditClassModal')
                this.$emit('succeed')
              } else {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: message,
                    icon: 'XCircleIcon',
                    variant: 'danger',
                  },
                })
              }
            }
          } catch (e) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: `[${e.code}] ${e.message}`,
                icon: 'XCircleIcon',
                variant: 'danger',
              },
            })
          } finally {
            this.isLoading = false
          }
        }
      })
    },
  },
}
</script>
