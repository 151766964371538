<template>
  <validation-observer
      ref="saveFormRef"
      #default="{invalid}"
  >
    <b-modal
        id="credentialSampleSaveModal"
        body-class="position-static"
        centered
        :title="isCreated ? 'Thêm mới dữ liệu' : 'Cập nhật dữ lệu'"
        no-close-on-backdrop
        @show="onShow"
        @hidden="onHide"
    >
      <b-form>
        <b-form-group>
          <template v-slot:label>
            Tên khung thời gian triển khai <span class="text-danger">*</span>
          </template>
          <validation-provider
              #default="{ errors}"
              name="Tên mẫu văn bằng/chứng chỉ"
              rules="required"
          >
            <b-form-input
                id="name"
                v-model="targetItem.name"
                placeholder="Tên mẫu văn bằng/chứng chỉ"
                :state="getElementState(errors)"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>

        <b-form-group>
          <template v-slot:label>
            Mô tả chi tiết <span class="text-danger">*</span>
          </template>
          <validation-provider
              #default="{ errors}"
              name="Mô tả chi tiết"
              rules="required"
          >
            <b-form-input
                id="description"
                v-model="targetItem.description"
                placeholder="Mô tả chi tiết"
                :state="getElementState(errors)"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>

        <b-form-group label-for="certificateBlankId">
          <template v-slot:label>
            Loại phôi <span class="text-danger">*</span>
          </template>
          <validation-provider
              #default="{ errors }"
              name="Loại phôi"
              rules="required"
          >
            <v-select
                v-model="targetItem.certificateBlankId"
                :options="allCertificateBlanks"
                :reduce="option => option.value"
            />
            <b-form-invalid-feedback :state="getElementState(errors)">
              {{ errors[0] }}
            </b-form-invalid-feedback>
          </validation-provider>
        </b-form-group>

        <b-form-group label-for="status">
          <template v-slot:label>
            Trạng thái <span class="text-danger">*</span>
          </template>
          <validation-provider
              #default="{ errors }"
              name="Trạng thái"
              rules="required"
          >
            <v-select
                v-model="targetItem.status"
                :options="statusOptions"
                :reduce="option => option.value"
            />
            <b-form-invalid-feedback :state="getElementState(errors)">
              {{ errors[0] }}
            </b-form-invalid-feedback>
          </validation-provider>
        </b-form-group>
      </b-form>

      <template #modal-footer>
        <div class="w-100 d-flex justify-content-end">
          <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-1"
              :disabled="invalid"
              @click="onSave('hide')"
          >
            <span class="text-right">
              <feather-icon icon="CheckIcon" /> Lưu lại
            </span>
          </b-button>

          <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="outline-secondary"
              @click="$bvModal.hide('credentialSampleSaveModal')"
          >
            <span class="text-right">
              <feather-icon icon="XIcon" /> Hủy
            </span>
          </b-button>
        </div>
      </template>
    </b-modal>
  </validation-observer>
</template>
<script>
import {
  BButton, BForm, BFormDatepicker, BFormGroup, BFormInput, BFormInvalidFeedback, BModal, BOverlay,
} from 'bootstrap-vue'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import { getUser } from '@/auth/utils'
import { mapActions, mapGetters } from 'vuex'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import SampleCertificateBlank from '@/views/certificate-blank/SampleCertificateBlank.vue'
import CredentialSampleConfig from '@/views/credential-sample/CredentialSampleConfig.vue'

export default {
  name: 'CredentialSampleSave',
  components: {
    CredentialSampleConfig,
    SampleCertificateBlank,
    BFormDatepicker,
    BFormGroup,
    BFormInput,
    BModal,
    BForm,
    BOverlay,
    BFormInvalidFeedback,
    BButton,
    BFormDatepicker,
    vSelect,
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  props: {
    item: {
      type: Object,
      default: undefined,
    },
    currentCredentialId: {
      type: Number,
      default: undefined,
    },
  },
  data() {
    return {
      user: getUser(),
      idLoading: false,
      targetItem: {
        name: '',
        description: '',
        credentialId: this.currentCredentialId,
        certificateBlankId: 0,
        credentialSampleFileCoverId: 0,
        credentialSampleFileMainId: 0,
        status: 1,
      },
    }
  },
  computed: {
    ...mapGetters({
      statuses: 'credentialImplement/statuses',
      allCertificateBlanks: 'dropdown/allCertificateBlanks',
    }),
    statusOptions() {
      return this.statuses
    },
    isCreated() {
      return !this.item
    },
  },
  async created() {
  },
  methods: {
    ...mapActions({
      getAllCertificateBlanks: 'dropdown/getAllCertificateBlanks',
      createCredentialSample: 'credentialSample/createCredentialSample',
      updateCredentialSample: 'credentialSample/updateCredentialSample',
    }),
    async onShow() {
      if (this.item) {
        this.targetItem = { ...this.item }
      }
      await this.getAllCertificateBlanks()
    },
    async onSave(type = null) {
      const valid = this.$refs
        .saveFormRef
        .validate()
      if (valid) {
        this.isLoading = true
        try {
          const response = this.isCreated
            ? await this.createCredentialSample(this.targetItem)
            : await this.updateCredentialSample(this.targetItem)
          if (response) {
            const { isSuccessful, message } = response
            if (isSuccessful) {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: message,
                  icon: 'CheckIcon',
                  variant: 'success',
                },
              })
              if (type === 'hide') {
                this.$bvModal.hide('credentialSampleSaveModal')
              }
              this.$emit('succeed')
            } else {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: message,
                  icon: 'XCircleIcon',
                  variant: 'danger',
                },
              })
            }
          }
        } catch (e) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: `[${e.code}] ${e.message}`,
              icon: 'XCircleIcon',
              variant: 'danger',
            },
          })
        } finally {
          this.isLoading = false
        }
      }
    },
    onHide() {
      this.targetItem = {
        name: '',
        description: '',
        credentialId: this.currentCredentialId,
        certificateBlankId: this.item.certificateBlankId,
        credentialSampleFileCoverId: 0,
        credentialSampleFileMainId: 0,
        status: 1,
      }
    },
    getElementState(errors) {
      return errors.length > 0 ? false : null
    },
  },
}
</script>

<style scoped lang="scss">
</style>
