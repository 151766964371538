<template>
  <validation-observer
    ref="examinationRoomSaveFormRef"
    #default="{invalid}"
  >
    <b-modal
      id="examinationRoomSaveModal"
      body-class="position-static"
      centered
      :title="isCreated ? 'Thêm phòng thi theo môn thi' : 'Cập nhật phòng thi theo môn thi'"
      @show="onShow"
      @hide="onHide"
      no-close-on-backdrop
    >
      <b-form>
        <b-form-group label-for="admissionSubjectId">
          <template v-slot:label>
            Môn thi <span class="text-danger">*</span>
          </template>
          <validation-provider
            #default="{ errors }"
            name="Môn thi"
            rules="required"
          >
            <v-select
              v-model="targetExaminationRoom.admissionSubjectId"
              :options="admissionSubjectOptions"
              :reduce="option => option.value"
              disabled
            />
            <b-form-invalid-feedback :state="getElementState(errors)">
              {{ errors[0] }}
            </b-form-invalid-feedback>
          </validation-provider>
        </b-form-group>
        <b-form-group label-for="name">
          <template v-slot:label>
            Tên phòng thi <span class="text-danger">*</span>
          </template>
          <validation-provider
            #default="{ errors }"
            name="Tên phòng thi"
            rules="required"
          >
            <b-form-input
              id="name"
              v-model="targetExaminationRoom.name"
              name="name"
              placeholder="Nhập tên phòng thi theo môn học"
              :state="getElementState(errors)"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
        <b-form-group label-for="roomId">
          <template v-slot:label>
            Phòng thi <span class="text-danger">*</span>
          </template>
          <validation-provider
            #default="{ errors }"
            name="Phòng thi"
            rules="required"
          >
            <v-select
              v-model="targetExaminationRoom.roomId"
              :options="roomOptions"
              :reduce="option => option.value"
            />
            <b-form-invalid-feedback :state="getElementState(errors)">
              {{ errors[0] }}
            </b-form-invalid-feedback>
          </validation-provider>
        </b-form-group>
        <b-form-group
          label="Thời gian bắt đầu"
          label-for="startAt"
        >
          <flat-pickr
            v-model="targetExaminationRoom.startAt"
            :config="config"
            class="form-control"
            placeholder="Thời gian bắt đầu"
            name="startAt"
          />
        </b-form-group>

        <b-form-group
          label="Thời gian kết thúc"
          label-for="endAt"
        >
          <flat-pickr
            v-model="targetExaminationRoom.endAt"
            :config="config"
            class="form-control"
            placeholder="Thời gian kết thúc"
            :min-date="targetExaminationRoom.startAt"
            name="endAt"
            @on-change="valTime"
          />
        </b-form-group>

        <b-form-group label-for="status">
          <template v-slot:label>
            Trạng thái <span class="text-danger">*</span>
          </template>
          <validation-provider
            #default="{ errors }"
            name="Trạng thái"
            rules="required"
          >
            <v-select
              v-model="targetExaminationRoom.status"
              :options="statusOptions"
              :reduce="option => option.value"
            />
            <b-form-invalid-feedback :state="getElementState(errors)">
              {{ errors[0] }}
            </b-form-invalid-feedback>
          </validation-provider>
        </b-form-group>
      </b-form>
      <template #modal-footer>
        <div class="w-100 d-flex justify-content-end">
          <b-button
            v-show="isCreated"
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="mr-1"
            :disabled="invalid"
            @click="onSave"
          >
            <span class="text-right">
              <feather-icon icon="CheckIcon" /> Lưu và tiếp tục
            </span>
          </b-button>

          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="mr-1"
            :disabled="invalid"
            @click="onSave('hide')"
          >
            <span class="text-right">
              <feather-icon icon="CheckIcon" /> Lưu lại
            </span>
          </b-button>

          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="outline-secondary"
            @click="$bvModal.hide('examinationRoomSaveModal')"
          >
            <span class="text-right">
              <feather-icon icon="XIcon" /> Hủy
            </span>
          </b-button>
        </div>
      </template>
      <b-overlay
        no-wrap
        variant="white"
        spinner-variant="primary"
        blur="0"
        opacity=".75"
        rounded="sm"
        :show="isLoading"
      />
    </b-modal>
  </validation-observer>
</template>

<script>
import {
  BForm, BFormGroup, BFormInput, BModal, BOverlay, BFormInvalidFeedback, BButton,
} from 'bootstrap-vue'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { required } from '@validations'
import vSelect from 'vue-select'
import { mapActions, mapGetters } from 'vuex'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import Ripple from 'vue-ripple-directive'
import flatPickr from 'vue-flatpickr-component'
import { EXAMINATION_STATUS } from '@/const/status'
import FlatpickrLanguages from 'flatpickr/dist/l10n'

export default {
  name: 'ExaminationRoomSave',
  components: {
    BFormGroup,
    BFormInput,
    BModal,
    BForm,
    BOverlay,
    BFormInvalidFeedback,
    BButton,
    vSelect,
    ValidationProvider,
    ValidationObserver,
    flatPickr,
  },
  directives: {
    Ripple,
  },
  props: {
    examinationRoom: {
      type: Object,
      default: undefined,
    },
  },
  data() {
    return {
      isLoading: false,
      targetExaminationRoom: {
        name: '',
        code: '',
        admissionSubjectId: null,
        roomId: null,
        status: 1,
        orderNo: 1,
        startAt: null,
        endAt: null,
      },
      config: {
        enableTime: true,
        altInput: true,
        altFormat: 'd/m/Y H:i',
        dateFormat: 'd/m/Y H:i',
        locale: FlatpickrLanguages.vn,
        time_24hr: true,
      },
      statusOptions: EXAMINATION_STATUS,
      required,
    }
  },
  computed: {
    ...mapGetters({
      allRoom: 'examinationRoom/allRoom',
      listAdmissionSubject: 'admissionExaminationRoom/listAdmissionSubject',
    }),
    isCreated() {
      return !this.examinationRoom
    },
    roomOptions() {
      return this.allRoom.map(item => ({ value: item.id, label: item.name }))
    },
    admissionSubjectOptions() {
      return this.listAdmissionSubject.map(item => ({
        value: item.id,
        label: item.name,
      }))
    },
  },
  methods: {
    ...mapActions({
      createExaminationRoom: 'admissionExaminationRoom/createAdmissionExaminationRoom',
      updateExaminationRoom: 'admissionExaminationRoom/updateAdmissionExaminationRoom',
      getAllRooms: 'examinationRoom/getAllRooms',
      getAdmissionSubject: 'admissionExaminationRoom/getAdmissionSubject',
    }),
    getElementState(errors) {
      return errors.length > 0 ? false : null
    },
    async onShow() {
      this.isLoading = true
      try {
        await Promise.all([
          this.getAllRooms(),
        ])
      } catch (e) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: `[${e.code}] ${e.message}`,
            icon: 'XCircleIcon',
            variant: 'danger',
          },
        })
      } finally {
        this.isLoading = false
      }
      if (this.examinationRoom) {
        this.targetExaminationRoom = { ...this.examinationRoom }
      }
    },
    onHide() {
      this.$refs
        .examinationRoomSaveFormRef
        .reset()
      this.targetExaminationRoom = {
        name: '',
        code: '',
        admissionSubjectId: null,
        roomId: null,
        status: 1,
        orderNo: 1,
        startAt: null,
        endAt: null,
      }
    },
    async onSave(type = null) {
      const valid = this.$refs
        .examinationRoomSaveFormRef
        .validate()
      if (valid) {
        this.isLoading = true
        try {
          const response = this.isCreated
            ? await this.createExaminationRoom(this.targetExaminationRoom)
            : await this.updateExaminationRoom(this.targetExaminationRoom)
          if (response) {
            const { isSuccessful, message } = response
            if (isSuccessful) {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: message,
                  icon: 'CheckIcon',
                  variant: 'success',
                },
              })
              if (type === 'hide') {
                this.$bvModal.hide('examinationRoomSaveModal')
              }
              this.$emit('succeed')
            } else {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: message,
                  icon: 'XCircleIcon',
                  variant: 'danger',
                },
              })
            }
          }
        } catch (e) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: `[${e.code}] ${e.message}`,
              icon: 'XCircleIcon',
              variant: 'danger',
            },
          })
        } finally {
          this.isLoading = false
        }
      }
    },
    valTime(selectedDates, dateStr, instance) {
      if (dateStr <= this.targetExaminationRoom.startAt) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Thời gian kết thúc phải lớn hơn bắt đầu!',
            icon: 'XCircleIcon',
            variant: 'danger',
          },
        })
      }
    },
  },
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
