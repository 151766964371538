import { PermissionCode, ResourceCode } from '@/const/code'

export default [
  {
    path: '/examination_rooms',
    name: 'examinationRoom',
    component: () => import('@/views/examination-room/ExaminationRoom.vue'),
    meta: {
      pageTitle: 'Danh sách phòng thi theo môn học',
      breadcrumb: [
        {
          text: 'Quản lý thi',
          active: false,
        },
        {
          text: 'Danh sách phòng thi',
          active: true,
        },
      ],
      action: PermissionCode.READ,
      resource: ResourceCode.EXAMINATION_ROOM,
    },
  },
  {
    path: '/examination_schedulers',
    name: 'examinationScheduler',
    component: () => import('@/views/examination-scheduler/ExaminationScheduler.vue'),
    meta: {
      pageTitle: 'Dự kiến lịch thi',
      breadcrumb: [
        {
          text: 'Quản lý thi',
          active: false,
        },
        {
          text: 'Dự kiến lịch thi',
          active: true,
        },
      ],
      action: PermissionCode.READ,
      resource: ResourceCode.EXAMINATION_SCHEDULER,
    },
  },
  {
    path: '/examination/supervisor-rooms',
    name: 'supervisorExaminationRoom',
    component: () => import('@/views/examination-room/supervisor-room/SupervisorRoom.vue'),
    meta: {
      pageTitle: 'Danh sách phòng thi được phân công',
      breadcrumb: [
        {
          text: 'Quản lý thi',
          active: false,
        },
        {
          text: 'Công tác Giám thị',
          active: true,
        },
      ],
      action: PermissionCode.READ,
      resource: ResourceCode.SUPERVISOR_EXAMINATION_ROOM,
    },
  },
  {
    path: '/deduct/level',
    name: 'examinationDeductLevel',
    component: () => import('@/views/examination-room/deduct-level/DeductLevel.vue'),
    meta: {
      pageTitle: 'Cấu hình Mức xử lý vi phạm thi',
      breadcrumb: [
        {
          text: 'Hệ thống',
          active: false,
        },
        {
          text: 'Mức xử lý vi phạm thi',
          active: true,
        },
      ],
      action: PermissionCode.READ,
      resource: ResourceCode.EXAMINATION_DEDUCT_LEVEL,
    },
  },
]
