<template>
  <div>
    <b-container fluid>
      <b-card no-body>
        <b-overlay
          :show="isLoading"
          rounded="sm"
        >
          <div class="m-2">
            <b-row>
              <b-col cols="12">
                <div class="text-right">
                  <b-button
                    v-if="updatable"
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="primary"
                    @click="onUpdateSchoolYears"
                  >
                    <span class="text-nowrap text-right">
                      <feather-icon icon="CalendarIcon" /> Cập nhật năm học cho học kỳ
                    </span>
                  </b-button>
                </div>
              </b-col>
            </b-row>
            <b-row class="mt-2">
              <b-col cols="12">
                <vue-good-table
                  mode="remote"
                  class="my-table"
                  :columns="columns"
                  :rows="mainData"
                  :pagination-options="paginationOptions"
                  :total-rows="totalRows"
                  @on-page-change="onPageChange"
                  @on-sort-change="onSortChange"
                  @on-column-filter="onColumnFilter"
                  @on-per-page-change="onPerPageChange"
                >
                  <div
                    slot="emptystate"
                    style="text-align: center; font-weight: bold"
                  >
                    Không có bản ghi nào !
                  </div>
                  <template
                    slot="table-row"
                    slot-scope="props"
                  >
                    <span
                      v-if="props.column.field === 'status'"
                      :class="props.row.status==1?'text-success':''"
                    >
                      {{ getStatusName(props.row.status) }}
                    </span>
                    <span v-else-if="props.column.field === 'startDate'">
                      {{ formatDateReadable(props.row.startDate) }}
                    </span>
                    <span v-else-if="props.column.field === 'endDate'">
                      {{ formatDateReadable(props.row.endDate) }}
                    </span>
                    <span
                      v-else-if="props.column.field === 'action'"
                      class="thead-action"
                    >
                      <b-button
                        v-if="updatable"
                        v-b-modal.courseSemesterSaveModal
                        variant="primary"
                        class="btn-icon"
                        size="sm"
                        title="Chỉnh sửa"
                        @click="onEditMainData(props.row)"
                      >
                        <feather-icon icon="Edit2Icon" />
                      </b-button>
                    </span>
                    <!-- Column: Common -->
                    <span v-else>{{ props.formattedRow[props.column.field] }}</span>
                  </template>

                  <!-- pagination -->
                  <template
                    slot="pagination-bottom"
                    slot-scope="props"
                  >
                    <div class="d-flex justify-content-between flex-wrap">
                      <div class="d-flex align-items-center mb-0 mt-1">
                        <span class="text-nowrap"> Hiển thị {{ filter.itemsPerPage * (filter.currentPage-1) +1 }}
                          đến {{ filter.itemsPerPage * (filter.currentPage) }} &nbsp; của {{ totalRows }} bản ghi
                        </span>
                      </div>
                      <div class="d-flex align-items-center mb-0 mt-1">
                        <span class="text-nowrap"> Hiển thị </span>
                        <b-form-select
                          v-model="filter.itemsPerPage"
                          :options="itemsPerPageOptions"
                          class="mx-1"
                          @input="(value) => props.perPageChanged({ currentPerPage: value })"
                        />
                        <span class="text-nowrap"> bản ghi/trang</span>
                      </div>
                      <div>
                        <b-pagination
                          :value="1"
                          :total-rows="totalRows"
                          :per-page="filter.itemsPerPage"
                          class="mt-1 mb-0"
                          @input="(value) => props.pageChanged({ currentPage: value })"
                        />
                      </div>
                    </div>
                  </template>
                </vue-good-table>
              </b-col>
            </b-row>
          </div>
        </b-overlay>
      </b-card>
    </b-container>
    <CourseSemesterSave
      ref="courseSemesterSaveFormRef"
      :main-data="currentMainData"
      :ext-data="dataCourse"
      @succeed="onSucceed"
    />
  </div>
</template>

<script>
import {
  BButton, BCard, BCol, BContainer, BFormSelect, BOverlay, BPagination, BRow,
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import { mapActions, mapGetters } from 'vuex'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import '@core/scss/vue/libs/vue-good-table.scss'
import Ripple from 'vue-ripple-directive'
import CourseSemesterSave from '@/views/admin/category/course-semester/CourseSemesterSave.vue'
import { getSemesterLevel, hasPermissionForResource, isSystemAdmin } from '@/utils'
import { PermissionCode, ResourceCode } from '@/const/code'
import moment from 'moment'

export const Attr = Object.freeze({
  store: 'courseSemester',
})
export default {
  name: 'CourseSemester',
  directives: {
    Ripple,
  },
  components: {
    CourseSemesterSave,
    BButton,
    BCard,
    BCol,
    BContainer,
    BFormSelect,
    BOverlay,
    BPagination,
    BRow,
    VueGoodTable,
  },
  props: {
    dataCourse: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      isLoading: false,
      filter: {
        currentPage: 1,
        itemsPerPage: 20,
        courseId: this.$route.params.id || this.dataCourse.id,
        sort: '1_asc',
      },
      currentMainData: undefined,
      paginationOptions: {
        enabled: true,
      },
      itemsPerPageOptions: [10, 20, 30, 50, 80, 100],
    }
  },
  computed: {
    ...mapGetters({
      mainData: `${Attr.store}/dataLists`,
      totalRows: `${Attr.store}/totalRows`,
      statuses: `${Attr.store}/statuses`,
      resourceName: `${Attr.store}/resourceName`,
      schoolYears: 'dropdown/schoolYears',
    }),
    statusFilterDropdownItems() {
      return this.statuses.map(item => ({ value: item.value, text: item.label }))
    },
    columns() {
      return [
        {
          label: '#',
          field: 'rowNum',
          width: '30px',
          sortable: false,
          thClass: 'text-center',
          tdClass: 'text-right',
        },
        {
          label: `Tên ${this.resourceName.shortName}`,
          field: 'name',
          filterOptions: {
            enabled: false,
            trigger: 'enter',
          },
          sortable: false,
          thClass: 'text-center',
        },
        {
          label: `Mã ${this.resourceName.shortName}`,
          field: 'code',
          filterOptions: {
            enabled: false,
            trigger: 'enter',
          },
          sortable: false,
          thClass: 'text-center',
        },
        {
          label: 'Ngày bắt đầu',
          field: 'startDate',
          filterOptions: {
            enabled: false,
            trigger: 'enter',
          },
          sortable: false,
          thClass: 'text-center',
        },
        {
          label: 'Ngày kết thúc',
          field: 'endDate',
          filterOptions: {
            enabled: false,
            trigger: 'enter',
          },
          sortable: false,
          thClass: 'text-center',
        },
        {
          label: 'Khoá học',
          field: 'courseName',
          filterOptions: {
            enabled: false,
            trigger: 'enter',
          },
          sortable: false,
          thClass: 'text-center',
        },
        {
          label: 'Năm học',
          field: 'yearName',
          filterOptions: {
            enabled: false,
            trigger: 'enter',
          },
          sortable: false,
          thClass: 'text-center',
        },
        {
          label: 'Trạng thái',
          field: 'status',
          filterOptions: {
            enabled: false,
            trigger: 'enter',
            filterDropdownItems: this.statusFilterDropdownItems,
          },
          sortable: false,
          thClass: 'text-center',
          tdClass: 'text-center',
        },
        {
          label: '',
          field: 'action',
          sortable: false,
          tdClass: 'text-center',
        },
      ]
    },
    updatable() {
      return isSystemAdmin() || hasPermissionForResource(PermissionCode.UPDATE, ResourceCode.COURSE_SEMESTER)
    },
  },
  async created() {
    this.isLoading = true
    try {
      await this.getMainData(this.filter)
    } catch (e) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: `[${e.code}] ${e.message}`,
          icon: 'XCircleIcon',
          variant: 'danger',
        },
      })
    } finally {
      this.isLoading = false
    }
  },
  methods: {
    ...mapActions({
      getMainData: `${Attr.store}/getData`,
      deleteMainData: `${Attr.store}/deleteData`,
      bulkUpdateSchoolYears: `${Attr.store}/bulkUpdateSchoolYears`,
      getSchoolYears: 'dropdown/getSchoolYears',
    }),
    async onUpdateSchoolYears() {
      const firstYearsCode = this.mainData[0].yearCode
      const originalMainData = { ...this.mainData }
      if (firstYearsCode) {
        // Lay thong tin danh sach nam hoc
        await this.getSchoolYears({})
        // Build du lieu
        const firstYearValue = firstYearsCode.substring(0, 4)
        this.mainData.forEach((e, index) => {
          const schoolLevel = Math.floor(index / 3)
          const semesterOrder = index + 1
          const semesterCategory = getSemesterLevel(semesterOrder)
          // eslint-disable-next-line no-nested-ternary
          const startMonth = (semesterCategory === 1) ? '09' : ((semesterCategory === 2) ? '02' : '07')
          // eslint-disable-next-line no-nested-ternary
          const endMonth = (semesterCategory === 1) ? '01' : ((semesterCategory === 2) ? '06' : '08')
          e.yearCode = `${parseInt(firstYearValue, 10) + schoolLevel}${parseInt(firstYearValue, 10) + schoolLevel + 1}`
          e.yearName = `${parseInt(firstYearValue, 10) + schoolLevel}-${parseInt(firstYearValue, 10) + schoolLevel + 1}`

          e.startDate = `${parseInt(((semesterCategory === 1) ? firstYearValue : parseInt(firstYearValue, 10) + 1), 10) + schoolLevel}-${startMonth}-01`
          e.endDate = `${parseInt(firstYearValue, 10) + 1 + schoolLevel}-${endMonth}-31`

          e.yearId = this.schoolYears.find(year => year.label === e.yearName).value
        })

        const response = await this.bulkUpdateSchoolYears(this.mainData)
        if (response) {
          const { isSuccessful, message } = response
          if (isSuccessful) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: message,
                icon: 'CheckIcon',
                variant: 'success',
              },
            })
          } else {
            this.mainData = { ...originalMainData }
            this.$toast({
              component: ToastificationContent,
              props: {
                title: message,
                icon: 'XCircleIcon',
                variant: 'danger',
              },
            })
          }
        }
      } else {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Vui lòng chọn năm học cho kỳ đầu tiên!',
            icon: 'XCircleIcon',
            variant: 'danger',
          },
        })
      }
    },
    onEditMainData(mainData) {
      const {
        rowNum,
        originalIndex,
        vgt_id,
        ...rest
      } = mainData
      this.currentMainData = rest
    },
    onDeleteMainData(mainData) {
      this.$swal({
        title: `Bạn chắc chắn muốn xóa môn học <span class="text-danger">${mainData.name}</span> khỏi ${this.resourceName.fullName}?`,
        text: 'Bạn sẽ không thể khôi phục lại dữ liệu này!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: '<i class="fa-solid fa-check"></i> Đồng ý',
        cancelButtonText: '<i class="fa-solid fa-times"></i> Hủy',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(async result => {
        if (result.value) {
          this.isLoading = true
          try {
            const response = await this.deleteMainData(mainData.id)
            if (response) {
              const { isSuccessful, message } = response
              if (isSuccessful) {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: message,
                    icon: 'CheckIcon',
                    variant: 'success',
                  },
                })
                try {
                  await this.getMainData(this.filter)
                } catch (e) {
                  this.$toast({
                    component: ToastificationContent,
                    props: {
                      title: `[${e.code}] ${e.message}`,
                      icon: 'XCircleIcon',
                      variant: 'danger',
                    },
                  })
                }
              } else {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: message,
                    icon: 'XCircleIcon',
                    variant: 'danger',
                  },
                })
              }
            }
          } catch (e) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: `[${e.code}] ${e.message}`,
                icon: 'XCircleIcon',
                variant: 'danger',
              },
            })
          } finally {
            this.isLoading = false
          }
        }
      })
    },
    updateParams(newProps) {
      this.filter = { ...this.filter, ...newProps }
    },
    async onPageChange(param) {
      this.updateParams({ currentPage: param.currentPage })
      await this.getMainDataFromStore()
    },
    async getMainDataFromStore() {
      this.isLoading = true
      try {
        await this.getMainData(this.filter)
      } catch (e) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: `[${e.code}] ${e.message}`,
            icon: 'XCircleIcon',
            variant: 'danger',
          },
        })
      } finally {
        this.isLoading = false
      }
    },
    async onPerPageChange(param) {
      this.updateParams({ currentPage: 1, itemsPerPage: param.currentPerPage })
      await this.getMainDataFromStore()
    },
    async onSortChange(param) {
      if (param.length > 0) {
        const { field, type } = param[0]
        const selectedColumn = this.columns.findIndex(e => e.field === field)

        this.updateParams({ sort: `${selectedColumn}_${type}` })
      }
      await this.getMainDataFromStore()
    },
    async onColumnFilter(param) {
      const { columnFilters } = param
      if (Object.hasOwn(columnFilters, 'name')) this.updateParams({ name: columnFilters.name })
      if (Object.hasOwn(columnFilters, 'code')) this.updateParams({ code: columnFilters.code })
      if (Object.hasOwn(columnFilters, 'status')) this.updateParams({ status: columnFilters.status })
      await this.getMainDataFromStore()
    },
    getStatusName(id) {
      return this.statuses.find(status => status.value === id).label
    },
    async onSucceed() {
      await this.getMainDataFromStore()
    },
    formatDateReadable(date) {
      return date ? moment(date).format('DD/MM/YYYY') : ''
    },
  },
}
</script>
