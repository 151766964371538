import { ApiCode } from '@/const/api'
import { handleCatch } from '@/utils'
import axios from '@axios'

export default {
  namespaced: true,
  state: {
    planClasses: [],
    totalPlan: 0,
    classes: [],
    total: 0,
    statuses: [],
    courses: [],

    // ConductPoint
    studentConducts: [],
    cpCourses: [],
    resourceName: {
      fullName: 'Lớp cố định',
      shortName: 'Lớp',
    },
    rankConducts: [],
    dataExportStudentConductSemester: null,
    dataExportStudentConductYear: null,
  },
  getters: {
    planClasses: state => state.planClasses,
    totalPlan: state => state.totalPlan,
    classes: state => state.classes,
    total: state => state.total,
    statuses: state => state.statuses,
    courses: state => state.courses,
    studentConducts: state => state.studentConducts,
    cpCourses: state => state.cpCourses,
    rankConducts: state => state.rankConducts,
    dataExportStudentConductSemester: state => state.dataExportStudentConductSemester,
    dataExportStudentConductYear: state => state.dataExportStudentConductYear,
  },
  mutations: {
    SET_PLAN_CLASSES: (state, planClasses) => { state.classes = planClasses },
    SET_CLASSES: (state, classes) => { state.classes = classes },
    SET_TOTAL: (state, total) => { state.total = total },
    SET_TOTAL_PLAN: (state, totalPlan) => { state.totalPlan = totalPlan },
    SET_STATUS: (state, statuses) => { state.statuses = statuses },
    SET_COURSES: (state, courses) => { state.courses = courses },
    SET_STUDENT_CONDUCTS: (state, data) => {
      state.studentConducts = data
    },
    SET_CP_COURSES: (state, data) => {
      state.cpCourses = data
    },
    SET_RANK_CONDUCTS: (state, data) => {
      state.rankConducts = data
    },
    SET_DATA_EXPORT_STUDENT_CONDUCT_SEMESTER: (state, { dataExportStudentConductSemester }) => {
      state.dataExportStudentConductSemester = dataExportStudentConductSemester
    },
    SET_DATA_EXPORT_STUDENT_CONDUCT_YEAR: (state, { dataExportStudentConductYear }) => {
      state.dataExportStudentConductYear = dataExportStudentConductYear
    },
  },
  actions: {

    async getInit({ commit }) {
      try {
        const response = await axios.get('uni/classes/begin')
        const { data } = response
        if (data.code === ApiCode.SUCCESS) {
          const { statuses } = data.data
          commit('SET_STATUS', statuses)
        }
      } catch (e) {
        handleCatch(e)
      }
    },

    async getClasses({ commit }, params) {
      try {
        const response = await axios.get('uni/classes', { params })
        const { data } = response
        if (data.code === ApiCode.SUCCESS) {
          const { records } = data.data
          const { total } = data.data
          commit('SET_CLASSES', records)
          commit('SET_TOTAL', total)
        }
      } catch (e) {
        handleCatch(e)
      }
    },

    async updateClass({ commit }, dataObj) {
      try {
        const response = await axios.put('uni/classes', dataObj)
        const { data } = response
        return {
          isSuccessful: data.code === ApiCode.SUCCESS,
          message: data.message,
        }
      } catch (e) {
        handleCatch(e)
      }
    },

    async createClass({ commit }, dataObj) {
      try {
        const response = await axios.post('uni/classes', dataObj)
        const { data } = response
        return {
          isSuccessful: data.code === ApiCode.SUCCESS,
          message: data.message,
        }
      } catch (e) {
        handleCatch(e)
      }
    },

    async deleteClass({ commit }, id) {
      try {
        const response = await axios.delete(`uni/classes/${id}`)
        const { data } = response
        return data.code === ApiCode.SUCCESS
      } catch (e) {
        handleCatch(e)
      }
    },

    async getCourses({ commit }, param) {
      try {
        // const response = await axios.get('courses?currentPage=1&itemsPerPage=1000&trainingSystemId=1&status=1&sort=1_asc')
        const response = await axios.get('uni/courses', { param })
        const { data } = response
        if (data.code === ApiCode.SUCCESS) {
          const { records } = data.data
          const coursesOption = records.map(item => ({ value: item.id, text: item.name }))
          commit('SET_COURSES', coursesOption)
        }
      } catch (e) {
        handleCatch(e)
      }
      return null
    },
    async getStudentsConducts({ commit }, params) {
      try {
        const response = await axios.get('uni/studentConducts', { params })
        const { data } = response
        if (data.code === ApiCode.SUCCESS) {
          commit('SET_STUDENT_CONDUCTS', data.data)
        }
      } catch (e) {
        handleCatch(e)
      }
    },
    async getCourseSemestersByClassId({ commit }, params) {
      try {
        const response = await axios.get('uni/studentConducts/courseSemesters', { params })
        const { data } = response
        if (data.code === ApiCode.SUCCESS) {
          commit('SET_CP_COURSES', data.data)
        }
      } catch (e) {
        handleCatch(e)
      }
    },
    async getConductRanks({ commit }, params) {
      try {
        const response = await axios.get('uni/rankConducts', { params })
        const { data } = response
        if (data.code === ApiCode.SUCCESS) {
          commit('SET_RANK_CONDUCTS', data.data)
        }
      } catch (e) {
        handleCatch(e)
      }
    },
    async downloadExportStudentConductSemester({ commit }, params) {
      try {
        const responseStudentInfo = await axios({
          method: 'get',
          url: 'uni/tnhReports/reportStudentConductSemester',
          responseType: 'blob',
          params,
        })
        commit('SET_DATA_EXPORT_STUDENT_CONDUCT_SEMESTER', { dataExportStudentConductSemester: responseStudentInfo })
      } catch (e) {
        handleCatch(e)
      }
    },
    async downloadExportStudentConductYear({ commit }, params) {
      try {
        const responseStudentInfo = await axios({
          method: 'get',
          url: 'uni/tnhReports/reportStudentConductYear',
          responseType: 'blob',
          params,
        })
        commit('SET_DATA_EXPORT_STUDENT_CONDUCT_YEAR', { dataExportStudentConductYear: responseStudentInfo })
      } catch (e) {
        handleCatch(e)
      }
    },
    async saveStudentConducts({ commit }, params) {
      try {
        const response = await axios.post('uni/studentConducts', params)
        const { data } = response
        return {
          isSuccessful: data.code === ApiCode.SUCCESS,
          message: data.message,
        }
      } catch (e) {
        handleCatch(e)
      }
      return null
    },
    async getPlanClasses({ commit }, params) {
      try {
        const response = await axios.get('uni/classes/plan_by_programme', { params })
        const { data } = response
        if (data.code === ApiCode.SUCCESS) {
          const { total, records } = data.data
          commit('SET_PLAN_CLASSES', records)
          commit('SET_TOTAL_PLAN', total)
        }
      } catch (e) {
        handleCatch(e)
      }
    },
    async savePlanClassAndStudentsByDiploma({ commit }, params) {
      try {
        const response = await axios.post('uni/classes/plan_by_diploma', params)
        const { data } = response
        return {
          isSuccessful: data.code === ApiCode.SUCCESS,
          message: data.message,
        }
      } catch (e) {
        handleCatch(e)
      }
      return null
    },
  },
}
