<template>
  <validation-observer
    ref="saveFormRef"
    #default="{invalid}"
  >
    <b-modal
      id="saveModal"
      body-class="position-static"
      centered
      :title="isCreated ? 'Thêm mới' : 'Cập nhật'"
      @show="onShow"
      @hide="onHide"
    >
      <b-form>
        <b-form-group label-for="code">
          <template v-slot:label>
            Mã <span class="text-danger">*</span>
          </template>
          <validation-provider
            #default="{ errors }"
            name="Mã"
            rules="required"
          >
            <b-form-input
              id="code"
              v-model="targetDataSource.code"
              name="code"
              placeholder="Nhập mã"
              :state="getElementState(errors)"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
        <CategorySave :target-data-source="targetDataSource" />
        <b-form-group label-for="link">
          <template v-slot:label>
            Đường dẫn <span class="text-danger">*</span>
          </template>
          <validation-provider
            #default="{ errors }"
            name="Đường dẫn"
            rules="required"
          >
            <b-form-input
              id="link"
              v-model="targetDataSource.externalLink"
              name="link"
              placeholder="Đường link"
              aria-describedby="input-1-live-feedback"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
        <b-form-group label-for="file">
          <template v-slot:label>
            Tệp tin đính kèm <span class="text-danger">*</span>
          </template>
          <b-form-file
            ref="inputFileRef"
            :placeholder="targetDataSource.mediaName"
            accept="image/*"
            @change="uploadFile"
          />
        </b-form-group>
      </b-form>
      <template #modal-footer>
        <div class="w-100 d-flex justify-content-end">
          <b-button
            v-show="isCreated"
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="mr-1"
            :disabled="invalid"
            @click="onSave"
          >
            <span class="text-right">
              <feather-icon icon="CheckIcon" /> Lưu và tiếp tục
            </span>
          </b-button>

          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="mr-1"
            :disabled="invalid"
            @click="onSave('hide')"
          >
            <span class="text-right">
              <feather-icon icon="CheckIcon" /> Lưu lại
            </span>
          </b-button>

          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="outline-secondary"
            @click="$bvModal.hide('saveModal')"
          >
            <span class="text-right">
              <feather-icon icon="XIcon" /> Hủy
            </span>
          </b-button>
        </div>
      </template>
      <b-overlay
        variant="white"
        spinner-variant="primary"
        blur="0"
        opacity=".75"
        rounded="sm"
        :show="isLoading"
        no-wrap
      />
    </b-modal>
  </validation-observer>
</template>

<script>
import {
  BForm, BModal, BOverlay, BButton, BFormGroup, BFormInput, BFormFile,
} from 'bootstrap-vue'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { required } from '@core/utils/validations/validations'
import { mapActions, mapGetters } from 'vuex'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import Ripple from 'vue-ripple-directive'
import CategorySave from '@/components/category-save/CategorySave.vue'
import { Flag } from '@/const/flag'
import { getUser } from '@/auth/utils'

export default {
  name: 'Carouse',
  components: {
    BFormFile,
    BFormInput,
    ValidationProvider,
    BFormGroup,
    CategorySave,
    BModal,
    BForm,
    BOverlay,
    BButton,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  props: {
    dataSource: {
      type: Object,
      default: undefined,
    },
  },
  data() {
    return {
      isLoading: false,
      organizationId: getUser().orgId,
      mediaIds: [],
      targetDataSource: { status: Flag.ACTIVE, orderNo: 1 },
      required,
    }
  },
  computed: {
    ...mapGetters({
      maxOrderNo: 'carousel/maxOrderNo',
    }),
    isCreated() {
      return !this.dataSource
    },
  },
  methods: {
    ...mapActions({
      uploadDocument: 'carousel/uploadCarousel',
      createDataSource: 'carousel/createDataSource',
      updateDataSource: 'carousel/updateDataSource',
      getMaxOrderNo: 'carousel/getMaxOrderNo',
    }),
    getElementState(errors) {
      return errors.length > 0 ? false : null
    },
    async onShow() {
      if (this.dataSource) {
        this.targetDataSource = { ...this.dataSource }
        // await this.getMaxOrderNo( { organizationId: getUser().orgId })
      } else {
        await this.getMaxOrderNo({ organizationId: getUser().orgId })
        this.targetDataSource.orderNo = this.maxOrderNo + 1
        this.targetDataSource.organizationId = getUser().orgId
      }
    },
    onHide() {
      this.$refs
        .saveFormRef
        .reset()
      this.targetDataSource = { status: Flag.ACTIVE, orderNo: 1 }
      this.targetDataSource.organizationId = getUser().orgId
    },

    async uploadFile(e) {
      const { files } = e.target
      if (files.length === 0) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'File tài liệu đang bị trống',
            icon: 'InfoIcon',
            variant: 'warning',
          },
        })
        return
      }

      const formData = new FormData()
      files.forEach(file => formData.append('files', file))
      this.isLoading = true
      try {
        this.mediaIds = await this.uploadDocument(formData)
        if (this.mediaIds.length > 0) {
          this.showToast('Upload file thành công', 'CheckIcon', 'success')
        } else {
          this.showToast('Có lỗi xẩy ra', 'XCircleIcon', 'danger')
        }
      } catch (ex) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: `[${ex.code}] ${ex.message}`,
            icon: 'XCircleIcon',
            variant: 'danger',
          },
        })
      } finally {
        this.isLoading = false
      }
    },

    showToast(title, icon, variant) {
      this.$toast({
        component: ToastificationContent,
        position: 'top-right',
        props: {
          title,
          icon,
          variant,
        },
      })
    },

    async onSave(type = null) {
      const valid = this.$refs
        .saveFormRef
        .validate()
      if (valid) {
        this.isLoading = true
        if (this.targetDataSource.mediaId) {
          this.mediaIds.push(this.targetDataSource.mediaId)
          this.targetDataSource.mediaIds = this.mediaIds
        }
        try {
          const response = this.isCreated
            ? await this.createDataSource(this.targetDataSource)
            : await this.updateDataSource(this.targetDataSource)
          if (response) {
            const { isSuccessful, message } = response
            if (isSuccessful) {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: message,
                  icon: 'CheckIcon',
                  variant: 'success',
                },
              })
              if (type === 'hide') {
                this.$bvModal.hide('saveModal')
              }
              this.$emit('succeed')
            } else {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: message,
                  icon: 'XCircleIcon',
                  variant: 'danger',
                },
              })
            }
          }
        } catch (e) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: `[${e.code}] ${e.message}`,
              icon: 'XCircleIcon',
              variant: 'danger',
            },
          })
        } finally {
          this.isLoading = false
          this.mediaIds = []
        }
      }
    },
  },
}
</script>
