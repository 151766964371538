<template>
  <div>
    <b-container fluid>
      <b-card no-body>
        <b-overlay
          :show="isLoading"
          rounded="sm"
        >
          <div class="m-2">
            <b-row>
              <b-col cols="6">
                <b-form-group
                  label-cols="3"
                  label-cols-lg="3"
                  label="Hệ đào tạo:"
                  label-for="trainingSystemSelected"
                >
                  <v-select
                    v-model="trainingSystemSelected"
                    :options="trainingSystemsOptions"
                    :reduce="(option) => option.value"
                    @input="onChangeTrainingSystem"
                  />
                </b-form-group>
              </b-col>
              <b-col cols="6">
                <b-form-group
                  label-cols="3"
                  label-cols-lg="3"
                  label="Khóa học:"
                  label-for="courseSelected"
                >
                  <v-select
                    v-model="courseSelected"
                    :options="courseOptions"
                    :reduce="(option) => option.value"
                    @input="onChangeCourse"
                  />
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col cols="6">
                <b-form-group
                  label-cols="3"
                  label-cols-lg="3"
                  label="Ngành học:"
                  label-for="majorSelected"
                >
                  <v-select
                    v-model="majorSelected"
                    :options="majorOptions"
                    :reduce="(option) => option.value"
                    @input="onChangeMajor"
                  />
                </b-form-group>
              </b-col>
              <b-col cols="6">
                <b-form-group
                  label-cols="3"
                  label-cols-lg="3"
                  label="Lớp học:"
                  label-for="classSelect"
                >
                  <v-select
                    v-model="classSelect"
                    :options="classOptions"
                    :reduce="(option) => option.value"
                  />
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col cols="6">
                <b-form-group
                  label-cols="3"
                  label-cols-lg="3"
                  label="Năm:"
                  label-for="yearSelected"
                >
                  <v-select
                    v-model="yearSelected"
                    :options="yearOptions"
                    :reduce="(option) => option.value"
                    @input="onChangeYear"
                  />
                </b-form-group>
              </b-col>
              <b-col cols="6">
                <b-form-group
                  label-cols="3"
                  label-cols-lg="3"
                  label="Học kỳ:"
                  label-for="semesterSelected"
                >
                  <v-select
                    v-model="semesterSelected"
                    :options="semesterOptions"
                    :reduce="(option) => option.value"
                  />
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col cols="12">
                <div class="mr-auto">
                  <b-form-checkbox
                      v-model="firstExam"
                      :value="1"
                      :unchecked-value="0"
                  >
                    Tính theo điểm thi lần 1
                  </b-form-checkbox>
                </div>
                <div class="text-right">
                  <b-button
                    v-show="this.classSelect > 0 && this.yearSelected > 0"
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="primary"
                    style="margin-right: 5px"
                    @click="onSelect"
                  >
                    <span class="text-nowrap text-right">Danh sách</span>
                  </b-button>
                  <b-button
                    v-show="this.itemsTable.length > 0"
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="primary"
                    style="margin-right: 5px"
                    @click="exportBangDiemKetQuaHocTap"
                  >
                    <span class="text-nowrap text-right">Xuất excel</span>
                  </b-button>
                </div>
              </b-col>
            </b-row>
            <b-row class="mt-2">
              <b-col cols="12">
                <div style="overflow: auto;">
                  <b-table
                    ref="table"
                    :small="true"
                    :hover="true"
                    :items="itemsTable"
                    :fields="fieldsTemp"
                  >
                    <template #cell(index)="data">
                      {{ data.index + 1 }}
                    </template>
                  </b-table>
                </div>
              </b-col>
            </b-row>
          </div>
        </b-overlay>
      </b-card>
    </b-container>
    <!--    <vue-html2pdf-->
    <!--      ref="html2Pdf"-->
    <!--      :show-layout="controlValue.showLayout"-->
    <!--      :float-layout="controlValue.floatLayout"-->
    <!--      :enable-download="controlValue.enableDownload"-->
    <!--      :preview-modal="controlValue.previewModal"-->
    <!--      :filename="controlValue.filename"-->
    <!--      :paginate-elements-by-height="controlValue.paginateElementsByHeight"-->
    <!--      :pdf-quality="controlValue.pdfQuality"-->
    <!--      :pdf-format="controlValue.pdfFormat"-->
    <!--      :pdf-orientation="controlValue.pdfOrientation"-->
    <!--      :pdf-content-width="controlValue.pdfContentWidth"-->
    <!--      :manual-pagination="controlValue.manualPagination"-->
    <!--      :html-to-pdf-options="htmlToPdfOptions"-->
    <!--      @hasDownloaded="hasDownloaded($event)"-->
    <!--    >-->
    <!--      <pdf-content-->
    <!--        slot="pdf-content"-->
    <!--        :content-pdf="contentPdf"-->
    <!--        :list-student-examination-room="this.avgStudentClasss"-->
    <!--      />-->
    <!--    </vue-html2pdf>-->
  </div>
</template>

<script>
import {
  BButton,
  BCard,
  BCol,
  BContainer, BFormCheckbox,
  BFormGroup,
  BOverlay,
  BRow,
  BTable,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { mapActions, mapGetters } from 'vuex'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import '@core/scss/vue/libs/vue-good-table.scss'
import Ripple from 'vue-ripple-directive'
import VueHtml2pdf from 'vue-html2pdf'
import { getUser } from '@/auth/utils'

export default {
  name: 'AvgStudentClass',
  directives: {
    Ripple,
  },
  components: {
    BFormCheckbox,
    BTable,
    BButton,
    BFormGroup,
    BCard,
    BCol,
    BContainer,
    BOverlay,
    BRow,
    vSelect,
    VueHtml2pdf,
  },
  data() {
    return {
      currentUser: getUser(),
      trainingSystemSelected: undefined,
      courseSelected: undefined,
      majorSelected: undefined,
      classSelect: undefined,
      yearSelected: undefined,
      semesterSelected: undefined,
      firstExam: 0,
      isLoading: false,
      contentRendered: false,
      generatingPdf: false,
      pdfDownloaded: false,
      fieldsTemp: [],
      controlValue: {
        showLayout: false,
        floatLayout: true,
        enableDownload: true,
        previewModal: true,
        paginateElementsByHeight: 1100,
        manualPagination: false,
        filename: 'Danhsachphach',
        pdfQuality: 2,
        pdfFormat: 'a4',
        pdfOrientation: 'portrait',
        pdfContentWidth: '800px',
      },
      contentPdf: {
        heDaoTao: 'Đại học chính quy',
        lop: null,
        namHoc: null,
        hocKy: null,
        phongThi: null,
        monHoc: null,
        lanThi: 1,
      },
      fieldsTableTNH: [
        'Họ và',
        'Tên',
        'Năm sinh',
        'Giới tính',
        'Nơi sinh',
        'TBC10 HT',
        'TBC HT',
        'XLHL',
        'Điểm RL',
        'XL RL',
        'Ghi chú',
      ],
      itemsTable: [],
    }
  },
  computed: {
    ...mapGetters({
      dataLists: 'avgStudentClass/dataLists',
      dataListsTNH: 'avgStudentClass/dataListsTNH',
      allYears: 'avgStudentClass/allYears',
      allTrainingSystems: 'avgStudentClass/allTrainingSystems',
      listTrainingSystemCourses: 'avgStudentClass/listTrainingSystemCourses',
      listCourseMajors: 'avgStudentClass/listCourseMajors',
      listCourseMajorClasses: 'avgStudentClass/listCourseMajorClasses',
      listYearSemesters: 'avgStudentClass/listYearSemesters',
      listSubjectsOrder: 'avgStudentClass/listSubjectsOrder',
      listSubjectsOrderTNH: 'avgStudentClass/listSubjectsOrderTNH',
      dataExport: 'avgStudentClass/dataExport',
    }),
    htmlToPdfOptions() {
      return {
        margin: 0,
        filename: 'Danhsachphach.pdf',
        image: {
          type: 'jpeg',
          quality: 0.98,
        },
        enableLinks: true,
        html2canvas: {
          scale: this.controlValue.pdfQuality,
          useCORS: true,
        },
        jsPDF: {
          unit: 'in',
          format: this.controlValue.pdfFormat,
          orientation: this.controlValue.pdfOrientation,
        },
      }
    },

    trainingSystemsOptions() {
      return this.allTrainingSystems.map(item => ({
        value: item.id,
        label: item.name,
      }))
    },
    courseOptions() {
      return this.listTrainingSystemCourses.map(item => ({
        value: item.id,
        label: item.name,
      }))
    },
    majorOptions() {
      return this.listCourseMajors.map(item => ({
        value: item.id,
        label: item.name,
      }))
    },
    classOptions() {
      return this.listCourseMajorClasses.map(item => ({
        value: item.id,
        label: item.name,
      }))
    },
    yearOptions() {
      return this.allYears
    },
    semesterOptions() {
      return this.listYearSemesters.map(item => ({
        value: item.id,
        label: item.name,
      }))
    },
  },
  async created() {
    this.isLoading = true
    try {
      await Promise.all([this.begin()])
    } catch (e) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: `[${e.code}] ${e.message}`,
          icon: 'XCircleIcon',
          variant: 'danger',
        },
      })
    } finally {
      this.isLoading = false
    }
  },
  methods: {
    ...mapActions({
      begin: 'avgStudentClass/begin',
      getDataTNH: 'avgStudentClass/getDataTNH',
      getListTrainingSystemCourses:
        'avgStudentClass/getListTrainingSystemCourses',
      getListCourseMajors: 'avgStudentClass/getListCourseMajors',
      getListCourseMajorClasses: 'avgStudentClass/getListCourseMajorClasses',
      getListSubjectsOrderTNH: 'avgStudentClass/getListSubjectsOrderTNH',
      getListYearSemesters: 'avgStudentClass/getListYearSemesters',
      resetData: 'avgStudentClass/resetData',
      getDataExportTNH: 'avgStudentClass/downloadExportTNH',
    }),

    onChangeTrainingSystem() {
      if (this.trainingSystemSelected == null) {
        this.reset()
        this.$store.commit('avgStudentClass/SET_LISTTRAININGSYSTEMCOURSES', {
          data: [],
        })
        this.$store.commit('avgStudentClass/SET_LISTCOURSEMAJORS', {
          data: [],
        })
        this.$store.commit('avgStudentClass/SET_LISTCOURSEMAJORCLASSES', {
          data: [],
        })
        this.courseSelected = null
        this.majorSelected = null
        this.classSelect = null
      } else {
        this.getListTrainingSystemCourses({
          training_system_id: this.trainingSystemSelected,
        })
      }
    },
    onChangeCourse() {
      if (this.courseSelected == null) {
        this.reset()
        this.$store.commit('avgStudentClass/SET_LISTCOURSEMAJORS', {
          data: [],
        })
        this.$store.commit('avgStudentClass/SET_LISTCOURSEMAJORCLASSES', {
          data: [],
        })
        this.majorSelected = null
        this.classSelect = null
      } else {
        this.getListCourseMajors({
          course_id: this.courseSelected,
        })
      }
    },
    onChangeMajor() {
      if (this.majorSelected == null) {
        this.reset()
        this.$store.commit('avgStudentClass/SET_LISTCOURSEMAJORCLASSES', {
          data: [],
        })
        this.classSelect = null
      } else {
        this.getListCourseMajorClasses({
          course_id: this.courseSelected,
          major_id: this.majorSelected,
        })
      }
    },
    onChangeYear() {
      if (this.yearSelected == null) {
        this.reset()
        this.$store.commit('avgStudentClass/SET_LISTYEARSEMESTERS', {
          data: [],
        })
        this.semesterSelected = undefined
      } else {
        this.getListYearSemesters({
          year_no: this.yearSelected,
        })
      }
    },
    async reset() {
      await this.$store.commit('avgStudentClass/SET_DATA_TNH', { data: [] })
      this.itemsTable = this.dataListsTNH
      this.$refs.table.refresh()
      await this.$store.commit('avgStudentClass/SET_LISTSUBJECTSORDER_TNH', {
        data: null,
      })
      this.fieldsTableTNH = [
        'Họ và',
        'Tên',
        'Năm sinh',
        'Giới tính',
        'Nơi sinh',
        'TBC10 HT',
        'TBC HT',
        'XLHL',
        'Điểm RL',
        'XL RL',
        'Ghi chú',
      ]
      this.fieldsTemp = this.fieldsTableTNH
    },
    async onSelect() {
      this.isLoading = true
      try {
        const semesterId = this.semesterSelected == null ? 0 : this.semesterSelected
        await this.getListSubjectsOrderTNH({
          class_id: this.classSelect,
          course_id: this.courseSelected,
          year_no: this.yearSelected,
          semester_id: semesterId,
        })
        this.fieldsTableTNH = [
          'Họ và',
          'Tên',
          'Năm sinh',
          'Giới tính',
          'Nơi sinh',
          'TBC10 HT',
          'TBC HT',
          'XLHL',
          'Điểm RL',
          'XL RL',
          'Ghi chú',
        ]
        this.fieldsTemp = this.fieldsTableTNH
        if (this.listSubjectsOrderTNH !== null) {
          // eslint-disable-next-line prefer-spread
          this.fieldsTemp.splice.apply(
            this.fieldsTemp,
            [5, 0].concat(this.listSubjectsOrderTNH.split(',')),
          )
          await this.getDataTNH({
            class_id: this.classSelect,
            course_id: this.courseSelected,
            year_no: this.yearSelected,
            semester_id: semesterId,
            student_id: -1,
            first_value: this.firstExam,
          })
          this.itemsTable = this.dataListsTNH
        } else {
          await this.reset()
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Không có môn học nào trong kỳ này!',
              icon: 'XCircleIcon',
              variant: 'danger',
            },
          })
        }
      } catch (e) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: `[${e.code}] ${e.message}`,
            icon: 'XCircleIcon',
            variant: 'danger',
          },
        })
      } finally {
        this.isLoading = false
      }
    }, // <- Thêm dấu ngoặc nhọn ở đây để đóng hàm onSelect()
    formatJson(filterVal, jsonData) {
      return jsonData.map(v => filterVal.map(j => v[j]))
    },
    async exportBangDiemKetQuaHocTap() {
      const semesterId = this.semesterSelected == null ? 0 : this.semesterSelected
      await this.getDataExportTNH({
        class_id: this.classSelect,
        course_id: this.courseSelected,
        year_no: this.yearSelected,
        semester_id: semesterId,
        student_id: -1,
        first_value: this.firstExam,
      })
      const a = document.createElement('a')
      document.body.appendChild(a)
      const blob = new Blob([this.dataExport.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' })
      const url = window.URL.createObjectURL(blob)
      a.href = url
      a.download = 'bang_ket_qua_hoc_tap.xlsx'
      a.click()
      window.URL.revokeObjectURL(url)
    },

    async downloadPdf() {
      this.isLoading = true
      if (this.avgStudentClasss.length > 0) {
        try {
          if (!(await this.validateControlValue())) return
          this.$refs.html2Pdf.generatePdf()
        } catch (error) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: error,
              icon: 'XCircleIcon',
              variant: 'danger',
            },
          })
        } finally {
          this.isLoading = false
        }
      } else {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Chưa thực hiện lấy danh sách phách',
            icon: 'XCircleIcon',
            variant: 'danger',
          },
        })
        this.isLoading = false
      }
    },
    validateControlValue() {
      if (this.controlValue.pdfQuality > 2) {
        alert('pdf-quality value should only be 0 - 2')
        this.controlValue.pdfQuality = 2

        return false
      }

      if (!this.controlValue.paginateElementsByHeight) {
        alert('paginate-elements-by-height value cannot be empty')
        this.controlValue.paginateElementsByHeight = 1400

        return false
      }

      const paperSizes = [
        'a0',
        'a1',
        'a2',
        'a3',
        'a4',
        'letter',
        'legal',
        'a5',
        'a6',
        'a7',
        'a8',
        'a9',
        'a10',
      ]

      if (!paperSizes.includes(this.controlValue.pdfFormat)) {
        alert(`pdf-format value should only be ${paperSizes}`)
        this.controlValue.pdfFormat = 'a4'

        return false
      }

      if (!this.controlValue.pdfOrientation) {
        alert('pdf-orientation value cannot be empty')
        this.controlValue.pdfOrientation = 'portrait'

        return false
      }

      if (!this.controlValue.pdfContentWidth) {
        alert('pdf-content-width value cannot be empty')
        this.controlValue.pdfContentWidth = '800px'

        return false
      }

      return true
    },

    hasDownloaded(blobPdf) {
      this.pdfDownloaded = true
    },

    domRendered() {
      this.contentRendered = true
    },
  },
  beforeRouteUpdate(to, from, next) {
    // Call the API query method when the URL changes
    this.reset()
    next()
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
