<template src="./index.html" />

<script>
import {
  BFormCheckboxGroup,
  BAvatar,
  BBadge,
  BBreadcrumb,
  BButton,
  BCard,
  BCol, BDropdown, BDropdownItem,
  BForm, BFormDatepicker,
  BFormGroup, BFormRow,
  BFormInput, BFormInvalidFeedback, BFormSelect,
  BPagination, BFormCheckbox,
  BRow, BButtonGroup, ModalPlugin, VBTooltip,
} from 'bootstrap-vue'
import { mapActions, mapGetters } from 'vuex'
import vSelect from 'vue-select'
import { VueGoodTable } from 'vue-good-table'
import '@core/scss/vue/libs/vue-good-table.scss'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import flatPickr from 'vue-flatpickr-component'
import { getUser } from '@/auth/utils'
import FormConfigRegisterSubject from '@/views/config_register_subject/FormConfigRegisterSubject.vue'
import { hasPermissionForResource, isSystemAdmin } from '@/utils'
import { PermissionCode, ResourceCode } from '@/const/code'

const user = getUser()
export default {
  name: 'Index',
  components: {
    BButton,
    BCol,
    BRow,
    BForm,
    BBreadcrumb,
    BCard,
    BAvatar,
    BBadge,
    BPagination,
    BFormCheckbox,
    BFormRow,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BDropdown,
    BDropdownItem,
    BFormCheckboxGroup,
    VBTooltip,
    ModalPlugin,
    BFormDatepicker,
    BFormInvalidFeedback,
    vSelect,
    VueGoodTable,
    flatPickr,
    BButtonGroup,
    FormConfigRegisterSubject,
  },
  data() {
    return {
      filter: {
        organizationId: '',
        trainingSystemId: '',
        courseId: '',
        courseSemesterId: '',
      },
      itemsBC: [
        {
          text: 'Admin',
          href: '#',
        },
        {
          text: 'Config register subject',
          active: true,
        },
      ],
      serverParams: {
        columnFilters: {},
        sort: [
          {
            field: '',
            type: '',
          },
        ],
        page: 1,
        perPage: 10,
      },
      totalRecords: 0,
      table: {
        fields: [
          {
            field: 'trainingSystemName', label: 'Hệ đào tạo', sortable: false, type: 'string',
          },
          {
            field: 'courseName', label: 'Khóa học', sortable: false, type: 'string',
          },
          {
            field: 'semesterName', label: 'Học kỳ', sortable: false, type: 'string',
          },
          {
            field: 'openRegisterAt', label: 'Mở đăng ký môn học', sortable: false, type: 'string',
          },
          {
            field: 'closeRegisterAt', label: 'Kết thúc đăng ký môn học', sortable: false, type: 'string',
          },
          {
            field: 'action', label: 'Hành động', sortable: false, thClass: 'text-center', tdClass: 'text-center',
          },
        ],
        items: [],
      },
      dataSendForm: {},

    }
  },
  computed: {
    ...mapGetters({
      dataTable: 'courseSemester/courseSemesters',
      total: 'courseSemester/total',
      courses: 'dropdown/courses',
      trainingSystems: 'dropdown/trainingSystems',
      courseSemesters: 'dropdown/courseSemesters',
    }),
    updatable() {
      return isSystemAdmin() || hasPermissionForResource(PermissionCode.UPDATE, ResourceCode.COURSE_SEMESTER)
    },
  },
  async created() {
    await this.getInitData()
    await this.getData(this.serverParams)
    this.filter.organizationId = user.orgId
  },
  methods: {
    ...mapActions({
      getDataTable: 'courseSemester/getCourseSemesters',
      getTrainingSystems: 'dropdown/getTrainingSystems',
      getCourses: 'dropdown/getCourses',
      getCourseSemesters: 'dropdown/getCourseSemesters',
      updateCourseSemester: 'courseSemester/updateData',
    }),

    async onTrainingSystemsChange(event) {
      this.filter.courseId = null
      await this.getCourses({ organizationId: getUser().orgId, trainingSystemId: event })
    },

    async onCoursesChange(event) {
      this.filter.courseSemesterId = null
      await this.getCourseSemesters({ organizationId: getUser().orgId, courseId: event })
    },

    updateParams(newProps) {
      this.serverParams = { ...this.serverParams, ...newProps }
    },
    onPageChange(params) {
      this.updateParams({ page: params.currentPage })
      this.loadItems()
    },
    onPerPageChange(params) {
      this.updateParams({ perPage: params.currentPerPage })
      this.loadItems()
    },
    onSortChange(params) {
      this.updateParams({
        sort: [
          {
            type: params[0].type,
            field: params[0].field,
          },
        ],
      })
      this.loadItems()
    },

    onColumnFilter(params) {
      this.updateParams(params)
      this.loadItems()
    },

    // load items is what brings back the rows from server
    loadItems() {
      this.btnSearchClick()
    },

    showToast(title, icon, variant, text) {
      this.$toast({
        component: ToastificationContent,
        position: 'top-right',
        props: {
          title,
          icon,
          variant,
          text,
        },
      })
    },

    async btnSearchClick() {
      await this.getData()
    },

    openModal() {
      this.dataSendForm = {}
      this.$refs['modal-register'].show()
    },

    editItem(data) {
      const {
        rowNum,
        originalIndex,
        vgt_id,
        ...rest
      } = data
      this.dataSendForm = rest
      this.$refs['modal-register'].show()
    },

    async saveDataForm(data) {
      const dataSend = {
        id: data.courseSemesterId ? data.courseSemesterId : null,
        courseId: data.courseId,
        semesterId: data.semesterId,
        orderNo: data.orderNo,
        status: data.status,
        openRegisterAt: data.openRegisterAt,
        closeRegisterAt: data.closeRegisterAt,
        startDate: data.startDate,
        endDate: data.endDate,
        yearId: data.yearId,
        checkExaminationStartDate: data.checkExaminationStartDate,
        checkExaminationEndDate: data.checkExaminationEndDate,
      }
      const response = await this.updateCourseSemester(dataSend)
      if (response.isSuccessful) {
        this.showToast(response.message, 'CheckIcon', 'success', '')
        await this.getData()
        this.$refs['modal-register'].hide()
      } else {
        this.showToast(response.message, 'XCircleIcon', 'danger', '')
      }
    },

    async getInitData() {
      await this.getTrainingSystems(user.orgId)
      this.filter.trainingSystemId = this.trainingSystems[0].value
      await this.onTrainingSystemsChange(this.filter.trainingSystemId)
    },

    async getData() {
      const dataSend = {
        currentPage: this.serverParams.page,
        itemsPerPage: this.serverParams.perPage,
        courseId: this.filter.courseId,
        orgId: this.filter.organizationId,
        trainingSystemId: this.filter.trainingSystemId,
        status: 1,
        sort: '1_asc',
      }
      await this.getDataTable(dataSend)
      this.table.items = this.dataTable
    },
    deleteItem(data) {
      this.dataSendForm = data
      this.dataSendForm.isDelete = 1
      this.$refs['modal-register'].show()
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
