<template>
  <b-card no-body>
    <b-card-header>
      <div>
        <b-card-title>Số lượng lớp độc lập theo từng nhóm môn học</b-card-title>
      </div>
    </b-card-header>
    <b-card-body>
      <chartjs-component-horizontal-bar-chart
        ref="barChart1"
        :height="400"
        :data="horizontalBarChart.data"
        :options="horizontalBarChart.options"
      />
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard, BCardBody, BCardHeader, BCardTitle, BCardSubTitle,
} from 'bootstrap-vue'
import ChartjsComponentHorizontalBarChart
  from "@/views/admin/category/charts/component/ChartjsComponentHorizontalBarChart.vue";
import { $themeColors } from "@themeConfig";
import { mapActions, mapGetters } from "vuex";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
  components: {
    BCard,
    BCardBody,
    BCardHeader,
    BCardTitle,
    BCardSubTitle,
    ChartjsComponentHorizontalBarChart,
  },
  props: {
    filter: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      horizontalBarChart: {
        options: {
          elements: {
            rectangle: {
              borderWidth: 2,
              borderSkipped: 'top',
            },
          },
          tooltips: {
            shadowOffsetX: 1,
            shadowOffsetY: 1,
            shadowBlur: 8,
            shadowColor: chartColors.tooltipShadow,
            backgroundColor: $themeColors.light,
            titleFontColor: $themeColors.dark,
            bodyFontColor: $themeColors.dark,
          },
          responsive: true,
          maintainAspectRatio: false,
          responsiveAnimationDuration: 500,
          legend: {
            display: false,
          },
          scales: {
            xAxes: [
              {
                display: true,
                gridLines: {
                  zeroLineColor: chartColors.grid_line_color,
                  borderColor: 'transparent',
                  color: chartColors.grid_line_color,
                  drawTicks: false,
                },
                scaleLabel: {
                  display: true,
                },
                ticks: {
                  min: 0,
                  fontColor: chartColors.labelColor,
                },
              },
            ],
            yAxes: [
              {
                display: true,
                gridLines: {
                  display: false,
                },
                scaleLabel: {
                  display: true,
                },
                ticks: {
                  fontColor: chartColors.labelColor,
                },
              },
            ],
          },
        },
        data: {
          labels: [],
          datasets: [
            {
              data: [],
              backgroundColor: $themeColors.info,
              borderColor: 'transparent',
              barThickness: 15,
            },
          ],
        },
      },
    }
  },
  computed: {
    ...mapGetters( {
      countCreditClassBySubjectType: 'chart/countCreditClassBySubjectType',
    }),
  },
  watch: {
    'filter.trainingSystemId': async function () {
      await this.getDataFromStore()
    },
    'filter.yearId': async function () {
      await this.getDataFromStore()
    },
    'filter.semesterId': async function () {
      await this.getDataFromStore()
    }
  },
  async created() {
    await this.getDataFromStore()
  },
  methods:{
    ...mapActions({
      getCountCreditClassBySubjectType: 'chart/getCountCreditClassBySubjectType',
    }),
    async getDataFromStore(){
      this.isLoading = true
      try {
        await this.getCountCreditClassBySubjectType(this.filter)
        if (this.countCreditClassBySubjectType.length > 0) {
          this.horizontalBarChart.data.labels = []
          this.horizontalBarChart.data.datasets[0].data = []
          this.countCreditClassBySubjectType.forEach ((item, key) => {
            this.horizontalBarChart.data.labels.push(this.countCreditClassBySubjectType[key].subjectTypeName)
            this.horizontalBarChart.data.datasets[0].data.push(this.countCreditClassBySubjectType[key].countCreditClass)
          })
          this.$refs.barChart1.renderChart(this.horizontalBarChart.data, this.horizontalBarChart.options)
        }
      } catch (e) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: `[${e.code}] ${e.message}`,
            icon: 'XCircleIcon',
            variant: 'danger',
          },
        })
      } finally {
        this.isLoading = false
      }
    }
  }
}
const chartColors = {
  primaryColorShade: '#836AF9',
  yellowColor: '#ffe800',
  successColorShade: '#28dac6',
  warningColorShade: '#ffe802',
  warningLightColor: '#FDAC34',
  infoColorShade: '#299AFF',
  greyColor: '#4F5D70',
  blueColor: '#2c9aff',
  blueLightColor: '#84D0FF',
  greyLightColor: '#EDF1F4',
  tooltipShadow: 'rgba(0, 0, 0, 0.25)',
  lineChartPrimary: '#666ee8',
  lineChartDanger: '#ff4961',
  labelColor: '#6e6b7b',
  grid_line_color: 'rgba(200, 200, 200, 0.2)',
}
</script>
