<template>
  <div>
    <b-container fluid>
      <b-card no-body>
        <b-overlay
          :show="isLoading"
          rounded="sm"
        >
          <div class="m-2">
            <b-form>
              <b-row>
                <b-col
                  cols="12"
                  md="4"
                >
                  <b-form-group
                    label="Năm học"
                    label-for="year_id"
                  >
                    <v-select
                      v-model="filterData.year_id"
                      :options="yearsOptions"
                      :reduce="option => option.value"
                    />
                  </b-form-group>
                </b-col>
                <b-col
                  cols="12"
                  md="4"
                >
                  <b-form-group
                    label="Bộ môn"
                    label-for="department_id"
                  >
                    <v-select
                      v-model="filterData.department_id"
                      :options="departmentsOptions"
                      :reduce="option => option.value"
                    />
                  </b-form-group>
                </b-col>
                <b-col
                  cols="12"
                  md="4"
                >
                  <div class="text-right">
                    <b-button
                      v-show="this.filterData.year_id > 0 && this.filterData.department_id > 0"
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      variant="primary"
                      style="margin-top: 25px; margin-right: 5px"
                      @click="onSelect"
                    >Danh sách
                    </b-button>
                    <b-button
                        v-show="this.itemsTable.length > 0"
                        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                        variant="primary"
                        style="margin-top: 25px; margin-right: 5px"
                        @click="exportExcel"
                    >
                      <span class="text-nowrap text-right">Xuất excel</span>
                    </b-button>
                  </div>
                </b-col>
              </b-row>
            </b-form>
            <b-row class="mt-2">
              <b-col cols="12">
                <div class="text-center">
                  <b-table-simple
                    hover
                    small
                    responsive
                    bordered
                  >
                    <b-thead head-variant="light">
                      <b-tr>
                        <b-th rowspan="2" style="vertical-align: middle;">
                          STT
                        </b-th>
                        <b-th rowspan="2" style="vertical-align: middle;">
                          Tên giảng viên
                        </b-th>
                        <b-th rowspan="2" style="vertical-align: middle;">
                          Tên môn học
                        </b-th>
                        <b-th rowspan="2" style="vertical-align: middle;">
                          Lớp/Hp
                        </b-th>
                        <b-th colspan="3">
                          Thời lượng
                        </b-th>
                        <b-th colspan="2">
                          Giờ thực dạy
                        </b-th>
                        <b-th rowspan="2" style="vertical-align: middle;">
                          Tổng
                        </b-th>
                        <b-th rowspan="2" style="vertical-align: middle;">
                          Ghi chú
                        </b-th>
                      </b-tr>
                      <b-tr>
                        <b-th>Kì 1</b-th>
                        <b-th>Kì 2</b-th>
                        <b-th>Kì hè</b-th>
                        <b-th>LT</b-th>
                        <b-th>TH</b-th>
                      </b-tr>
                    </b-thead>
                    <b-tbody
                      v-for="(draft,index) in this.itemsTable"
                      :key="index"
                    >
                      <b-tr>
                        <b-td :rowspan="draft.item.length">
                          {{ index + 1 }}
                        </b-td>
                        <b-td :rowspan="draft.item.length">
                          {{ draft.teacher_name }}
                        </b-td>
                        <b-td>{{ draft.item[0].subject_name }}</b-td>
                        <b-td>{{ draft.item[0].class_code }}</b-td>
                        <b-td>{{ draft.item[0].tl_hk1 }}</b-td>
                        <b-td>{{ draft.item[0].tl_hk2 }}</b-td>
                        <b-td>{{ draft.item[0].tl_hkhe }}</b-td>
                        <b-td>{{ draft.item[0].gtd_lt }}</b-td>
                        <b-td>{{ draft.item[0].gtd_th }}</b-td>
                        <b-td :rowspan="draft.item.length">
                          {{ draft.gtd_sum }}
                        </b-td>
                        <b-td :rowspan="draft.item.length">
                          {{ draft.note }}
                        </b-td>
                      </b-tr>
                      <b-tr
                        v-for="(item, itemIndex) in draft.item.slice(1)"
                        :key="itemIndex"
                      >
                        <b-td>{{ item.subject_name }}</b-td>
                        <b-td >{{ item.class_code }}</b-td>
                        <b-td>{{ item.tl_hk1 }}</b-td>
                        <b-td>{{ item.tl_hk2 }}</b-td>
                        <b-td>{{ item.tl_hkhe }}</b-td>
                        <b-td>{{ item.gtd_lt }}</b-td>
                        <b-td>{{ item.gtd_th }}</b-td>
                      </b-tr>
                    </b-tbody>
                  </b-table-simple>
                </div>
              </b-col>
            </b-row>
          </div>
        </b-overlay>
      </b-card>
    </b-container>
  </div>
</template>

<script>
import {
  BButton,
  BCard,
  BCol,
  BContainer,
  BOverlay,
  BRow, BFormGroup, BForm, VBTooltip, BTableSimple, BTbody, BThead, BTr, BTd, BTh,
} from 'bootstrap-vue'
import { mapActions, mapGetters } from 'vuex'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import '@core/scss/vue/libs/vue-good-table.scss'
import Ripple from 'vue-ripple-directive'
// eslint-disable-next-line import/extensions
import { getUser } from '@/auth/utils'
import vSelect from 'vue-select'
import _ from 'lodash'

export default {
  name: 'ReportTeacherSpecializationAssignment',
  directives: {
    Ripple,
    'b-tooltip': VBTooltip,
  },
  components: {
    BButton,
    BCard,
    BCol,
    BContainer,
    BOverlay,
    BRow,
    BFormGroup,
    BForm,
    vSelect,
    BTableSimple,
    BTbody,
    BThead,
    BTr,
    BTd,
    BTh,
  },
  data() {
    return {
      isLoading: false,
      filterDepartment: {
        currentPage: 1,
        itemsPerPage: 1000,
        organizationId: 1,
      },
      filterData: {
        year_id: 0,
        department_id: 0,
      },
      itemsTable: [],
    }
  },
  computed: {
    ...mapGetters({
      dataLists: 'reportTeacherSpecializationAssignment/dataLists',
      allYears: 'reportTeacherSpecializationAssignment/allYears',
      allDepartments: 'reportTeacherSpecializationAssignment/allDepartments',
    }),
    yearsOptions() {
      return this.allYears.map(item => ({ value: item.id, label: item.name }))
    },
    departmentsOptions() {
      return this.allDepartments.map(item => ({ value: item.id, label: item.name }))
    },
  },

  async created() {
    this.isLoading = true
    try {
      await this.begin(this.filterDepartment)
    } catch (e) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: `[${e.code}] ${e.message}`,
          icon: 'XCircleIcon',
          variant: 'danger',
        },
      })
    } finally {
      this.isLoading = false
    }
  },
  methods: {
    ...mapActions({
      getData: 'reportTeacherSpecializationAssignment/getData',
      begin: 'reportTeacherSpecializationAssignment/begin',
    }),
    async onSelect() {
      this.isLoading = true
      try {
        await this.getData(this.filterData)
        this.itemsTable = _.chain(this.dataLists).groupBy('teacher_name').map((v, i) => ({
          teacher_name: i,
          gtd_sum: _.sumBy(v, 'gtd_sum'),
          note: _.get(_.find(v, 'note'), 'note'),
          item: v,
        })).value()
      } catch (e) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: `[${e.code}] ${e.message}`,
            icon: 'XCircleIcon',
            variant: 'danger',
          },
        })
      } finally {
        this.isLoading = false
      }
    },
    formatJson(filterVal, jsonData) {
      return jsonData.map(v => filterVal.map(j => v[j]))
    },
    exportExcel() {
      this.isLoading = true
      if (this.itemsTable.length > 0) {
        try {
          const tHeader = [
            'Tên giảng viên',
            'Tên môn học',
            'Lớp/Hp',
            'Thời lượng kì 1',
            'Thời lượng kì 2',
            'Thời lượng kì hè',
            'Giờ thực dạy lý thuyết',
            'Giờ thực dạy thực hành',
            'Tổng',
            'Ghi chú',
          ]

          const filterVal = ['teacher_name', 'subject_name', 'class_code', 'tl_hk1', 'tl_hk2', 'tl_hkhe', 'gtd_lt', 'gtd_th', 'gtd_sum', 'note']

          const dataJson = this.formatJson(filterVal, this.dataLists)
          import('@/utils/Export2Excel').then(excel => {
            excel.export_json_to_excel({
              header: tHeader,
              data: dataJson,
              filename: 'Phân_công_chuyên_môn_năm_học',
              autoWidth: true,
              bookType: 'xlsx',
            })
          })
        } catch (error) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: error,
              icon: 'XCircleIcon',
              variant: 'danger',
            },
          })
        } finally {
          this.isLoading = false
        }
      } else {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Chưa thực hiện lấy danh sách phách',
            icon: 'XCircleIcon',
            variant: 'danger',
          },
        })
        this.isLoading = false
      }
    },
  },
}
</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
td, th {
  vertical-align: middle;
}
</style>
