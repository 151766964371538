<template>
  <b-container fluid>
    <b-card no-body>
      <b-overlay
        :show="isLoading"
        rounded="sm"
      >
        <div class="m-2">
          <b-form>
            <b-row>
              <b-col
                sm="12"
                md="6"
                lg="6"
                xl="6"
              >
                <b-form-group
                  label="Hệ đào tạo"
                  label-for="trainingSystem"
                >
                  <v-select
                    id="trainingSystem"
                    v-model="trainingSystemId"
                    label="name"
                    :options="trainingSystems"
                    :reduce="option => option.id"
                    :clearable="false"
                  />
                </b-form-group>
              </b-col>
              <b-col
                sm="12"
                md="6"
                lg="6"
                xl="6"
              >
                <b-form-group
                  label="Khóa học"
                  label-for="course"
                >
                  <v-select
                    id="course"
                    v-model="courseId"
                    label="name"
                    :options="courses"
                    :reduce="option => option.id"
                    :clearable="false"
                  />
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col
                sm="12"
                md="6"
                lg="6"
                xl="6"
              >
                <b-form-group
                  label="Học kỳ"
                  label-for="semester"
                >
                  <v-select
                    id="semester"
                    v-model="filter.courseSemesterId"
                    label="name"
                    :options="semesters"
                    :reduce="option => option.id"
                    :clearable="false"
                  />
                </b-form-group>
              </b-col>
              <b-col
                sm="12"
                md="6"
                lg="6"
                xl="6"
              >
                <b-form-group
                  label="Môn học"
                  label-for="subject"
                >
                  <v-select
                    id="subject"
                    v-model="filter.subjectId"
                    label="name"
                    :options="subjects"
                    :reduce="option => option.id"
                  />
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col cols="12">
                <div class="w-100 d-flex justify-content-center">
                  <b-button
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="primary"
                    class="mr-1"
                    @click="getDataSourcesFromStore"
                  >
                    <span class="text-nowrap text-right">
                      <feather-icon icon="SearchIcon" /> Tìm kiếm
                    </span>
                  </b-button>

                  <b-button
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="outline-primary"
                    @click="exportExcel"
                  >
                    <span class="text-nowrap text-right">
                      <feather-icon icon="DownloadIcon" /> Xuất excel
                    </span>
                  </b-button>
                </div>
              </b-col>
            </b-row>
          </b-form>
          <b-row class="mt-2">
            <b-col cols="12">
              <div class="d-flex align-items-center mb-1 mt-0">
                  <span class="text-nowrap"> Hiển thị {{itemsPerPage * (filter.currentPage-1) +1}}
                    đến {{(filter.itemsPerPage * (filter.currentPage) >= dataSources.length)? dataSources.length : filter.itemsPerPage * (filter.currentPage)}} &nbsp; của {{ dataSources.length }} bản ghi
                  </span>
              </div>
              <vue-good-table
                :columns="columns"
                :rows="dataSources"
                :pagination-options="paginationOptions"
                :line-numbers="true"
              >
                <div
                  slot="emptystate"
                  style="text-align: center; font-weight: bold"
                >
                  Không có bản ghi nào !
                </div>

                <template
                  slot="table-row"
                  slot-scope="props"
                >
                  <!-- Column: Common -->
                  <span>{{ props.formattedRow[props.column.field] }}</span>
                </template>

                <!-- pagination -->
                <template
                  slot="pagination-bottom"
                  slot-scope="props"
                >
                  <div class="d-flex justify-content-between flex-wrap">
                    <div class="d-flex align-items-center mb-0 mt-1">
                      <span class="text-nowrap"> Hiển thị 1 đến </span>
                      <b-form-select
                        v-model="itemsPerPage"
                        :options="itemsPerPageOptions"
                        class="mx-1"
                        @input="(value) => props.perPageChanged({ currentPerPage: value })"
                      />
                      <span class="text-nowrap">của {{ dataSources.length }} bản ghi</span>
                    </div>
                    <div>
                      <b-pagination
                        :value="1"
                        :total-rows="props.total"
                        :per-page="itemsPerPage"
                        class="mt-1 mb-0"
                        @input="(value) => props.pageChanged({ currentPage: value })"
                      />
                    </div>
                  </div>
                </template>
              </vue-good-table>
            </b-col>
          </b-row>
        </div>
      </b-overlay>
    </b-card>
  </b-container>
</template>

<script>
import {
  BButton,
  BCard,
  BCol,
  BContainer,
  BForm,
  BFormGroup,
  BFormSelect,
  BOverlay,
  BPagination,
  BRow,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { Flag } from '@/const/flag'
import { mapActions, mapGetters, mapMutations } from 'vuex'
import Ripple from 'vue-ripple-directive'
import { getUser } from '@/auth/utils'
import { VueGoodTable } from 'vue-good-table'
import * as XLSX from 'xlsx'

export default {
  name: 'RetakeExamSecondTime',
  components: {
    BPagination,
    BFormSelect,
    BButton,
    BCard,
    BRow,
    BForm,
    BOverlay,
    vSelect,
    BFormGroup,
    BCol,
    BContainer,
    VueGoodTable,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      user: getUser(),
      isLoading: false,
      trainingSystemId: null,
      courseId: null,
      filter: {
        courseSemesterId: null,
        subjectId: null,
      },
      itemsPerPage: 20,
      itemsPerPageOptions: [10, 20, 30, 50, 80, 100],
      columns: [
        {
          label: 'Mã sinh viên',
          field: 'studentCode',
          filterOptions: {
            enabled: true,
            trigger: 'enter',
            placeholder: 'Nhập mã sinh viên',
          },
          thClass: 'text-center',
        },
        {
          label: 'Họ đệm',
          field: 'lastName',
          filterOptions: {
            enabled: true,
            trigger: 'enter',
            placeholder: 'Nhập họ đệm',
          },
          thClass: 'text-center',
        },
        {
          label: 'Tên',
          field: 'firstName',
          filterOptions: {
            enabled: true,
            trigger: 'enter',
            placeholder: 'Nhập tên',
          },
          thClass: 'text-center',
        },
        {
          label: 'Điểm TK',
          field: 'averagePoint',
          thClass: 'text-center',
        },
        {
          label: 'Lớp',
          field: 'classCode',
          filterOptions: {
            enabled: true,
            trigger: 'enter',
            placeholder: 'Nhập lớp',
          },
          thClass: 'text-center',
        },
        {
          label: 'Mã môn học',
          field: 'subjectCode',
          sortable: false,
          thClass: 'text-center',
        },
        {
          label: 'Tên môn học',
          field: 'subjectName',
          sortable: false,
          thClass: 'text-center',
        },
      ],
    }
  },
  computed: {
    ...mapGetters({
      trainingSystems: 'retakeExamSecondTime/trainingSystems',
      courses: 'retakeExamSecondTime/courses',
      semesters: 'retakeExamSecondTime/semesters',
      subjects: 'retakeExamSecondTime/subjects',
      dataSources: 'retakeExamSecondTime/students',
    }),
    paginationOptions() {
      return {
        enabled: true,
        perPage: this.itemsPerPage,
      }
    },
    isValid() {
      if (!this.filter.courseSemesterId) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Vui lòng chọn học kỳ!',
            icon: 'InfoIcon',
            variant: 'warning',
          },
        })
        return false
      }
      if (!this.filter.subjectId) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Vui lòng chọn môn học!',
            icon: 'InfoIcon',
            variant: 'warning',
          },
        })
        return false
      }
      return true
    },
  },
  watch: {
    async trainingSystemId(val) {
      if (val) {
        this.isLoading = true
        try {
          await this.getCoursesByTrainingSystemId({ trainingSystemId: val })
          if (this.courses.length > 0) {
            this.courseId = this.courses[0].id
          } else {
            this.courseId = null
            this.updateParams({
              courseSemesterId: null,
              subjectId: null,
            })
            this.setSemesters([])
            this.setSubjects([])
          }
        } catch (e) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: `[${e.code}] ${e.message}`,
              icon: 'XCircleIcon',
              variant: 'danger',
            },
          })
        } finally {
          this.isLoading = false
        }
      } else {
        this.courseId = null
        this.updateParams({
          courseSemesterId: null,
          subjectId: null,
        })
        this.setCourses([])
        this.setSemesters([])
        this.setSubjects([])
      }
    },
    async courseId(val) {
      if (val) {
        this.isLoading = true
        try {
          await this.getSemestersByCourseId({ courseId: val })
          if (this.semesters.length > 0) {
            const found = this.semesters.find(semester => semester.status === Flag.ACTIVE)
            if (found) {
              this.updateParams({ courseSemesterId: found.id })
            } else {
              this.updateParams({ courseSemesterId: this.semesters[0].id })
            }
          } else {
            this.updateParams({
              courseSemesterId: null,
              subjectId: null,
            })
            this.setSubjects([])
          }
        } catch (e) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: `[${e.code}] ${e.message}`,
              icon: 'XCircleIcon',
              variant: 'danger',
            },
          })
        } finally {
          this.isLoading = false
        }
      } else {
        this.updateParams({
          courseSemesterId: null,
          subjectId: null,
        })
        this.setSemesters([])
        this.setSubjects([])
      }
    },
    'filter.courseSemesterId': async function (val) {
      if (val) {
        this.isLoading = true
        try {
          await this.getSubjectsByCourseSemesterId({ courseSemesterId: val })
          if (this.subjects.length > 0) {
            this.updateParams({ subjectId: this.subjects[0].id })
          } else {
            this.updateParams({ subjectId: null })
          }
        } catch (e) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: `[${e.code}] ${e.message}`,
              icon: 'XCircleIcon',
              variant: 'danger',
            },
          })
        } finally {
          this.isLoading = false
        }
      } else {
        this.updateParams({ subjectId: null })
        this.setSubjects([])
      }
    },
  },
  async created() {
    this.setDataSources([])
    this.isLoading = true
    try {
      await this.getTrainingSystemsByOrganizationId({ organizationId: this.user.orgId })
      if (this.trainingSystems.length > 0) {
        this.trainingSystemId = this.trainingSystems[0].id
      }
    } catch (e) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: `[${e.code}] ${e.message}`,
          icon: 'XCircleIcon',
          variant: 'danger',
        },
      })
    } finally {
      this.isLoading = false
    }
  },
  methods: {
    ...mapMutations({
      setTrainingSystems: 'retakeExamSecondTime/SET_TRAINING_SYSTEMS',
      setCourses: 'retakeExamSecondTime/SET_COURSES',
      setSemesters: 'retakeExamSecondTime/SET_SEMESTERS',
      setSubjects: 'retakeExamSecondTime/SET_SUBJECTS',
      setDataSources: 'retakeExamSecondTime/SET_STUDENTS',
    }),
    ...mapActions({
      getTrainingSystemsByOrganizationId: 'retakeExamSecondTime/getTrainingSystemsByOrganizationId',
      getCoursesByTrainingSystemId: 'retakeExamSecondTime/getCoursesByTrainingSystemId',
      getSemestersByCourseId: 'retakeExamSecondTime/getSemestersByCourseId',
      getSubjectsByCourseSemesterId: 'retakeExamSecondTime/getSubjectsByCourseSemesterId',
      getStudentsRetakeExamSecondTime: 'retakeExamSecondTime/getStudentsRetakeExamSecondTime',
    }),
    updateParams(newProps) {
      this.filter = { ...this.filter, ...newProps }
    },
    async getDataSourcesFromStore() {
      if (this.isValid) {
        this.isLoading = true
        try {
          await this.getStudentsRetakeExamSecondTime(this.filter)
        } catch (e) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: `[${e.code}] ${e.message}`,
              icon: 'XCircleIcon',
              variant: 'danger',
            },
          })
        } finally {
          this.isLoading = false
        }
      }
    },
    exportExcel() {
      if (this.dataSources.length === 0) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Không có dữ liệu để xuất!',
            icon: 'InfoIcon',
            variant: 'warning',
          },
        })
        return
      }
      const data = []
      const header = {}
      this.columns.forEach(column => {
        header[column.field] = column.label
      })
      const headerKeys = Object.keys(header)
      const dataSourceKeys = Object.keys(this.dataSources[0])
      const duplicates = dataSourceKeys.filter(val => headerKeys.includes(val))
      duplicates.sort((a, b) => headerKeys.indexOf(a) - headerKeys.indexOf(b))
      this.dataSources.forEach(dataSource => {
        const elm = {}
        duplicates.forEach(key => {
          elm[header[key]] = dataSource[key]
        })
        data.push(elm)
      })
      const ws = XLSX.utils.json_to_sheet(data)
      const wb = XLSX.utils.book_new()
      XLSX.utils.book_append_sheet(wb, ws, 'SinhVien')
      XLSX.writeFile(wb, 'DanhSachSinhVienThiLai.xlsx')
    },
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-good-table.scss";
</style>
