<template>
  <validation-observer
      ref="saveFormRef"
      #default="{invalid}"
  >
    <b-modal
        id="credentialConditionSaveModal"
        body-class="position-static"
        centered
        :title="isCreated ? 'Thêm mới dữ liệu' : 'Cập nhật dữ lệu'"
        no-close-on-backdrop
        @show="onShow"
        @hidden="onHide"
    >
      <b-form>
        <b-form-group>
          <template v-slot:label>
            Tên điều kiện<span class="text-danger">*</span>
          </template>
          <validation-provider
              #default="{ errors}"
              name="Tên điều kiện"
              rules="required"
          >
            <b-form-input
                id="description"
                v-model="targetItem.name"
                placeholder="Tên điều kiện"
                :state="getElementState(errors)"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>

        <b-form-group>
          <template v-slot:label>
            Mô tả điều kiện <span class="text-danger">*</span>
          </template>
          <validation-provider
              #default="{ errors}"
              name="Mô tả điều kiện"
              rules="required"
          >
            <b-form-input
                id="description"
                v-model="targetItem.description"
                placeholder="Mô tả điều kiện"
                :state="getElementState(errors)"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
        <b-form-group label-for="certificateImplementId">
          <template v-slot:label>
            Khung thời gian triển khai <span class="text-danger">*</span>
          </template>
          <validation-provider
              #default="{ errors }"
              name="Khung thời gian triển khai"
              rules="required"
          >
            <v-select
                v-model="targetItem.certificateImplementId"
                :options="certificateImplements"
                :reduce="option => option.value"
            />
            <b-form-invalid-feedback :state="getElementState(errors)">
              {{ errors[0] }}
            </b-form-invalid-feedback>
          </validation-provider>
        </b-form-group>
        <b-form-group label-for="subjectTypeId">
          <template v-slot:label>
            Loại môn học <span class="text-danger">*</span>
          </template>
          <validation-provider
              #default="{ errors }"
              name="Loại môn học"
              rules="required"
          >
            <v-select
                v-model="targetItem.subjectTypeId"
                :options="subjectTypeOptions"
                :reduce="option => option.value"
            />
            <b-form-invalid-feedback :state="getElementState(errors)">
              {{ errors[0] }}
            </b-form-invalid-feedback>
          </validation-provider>
        </b-form-group>
        <b-form-group
            label="Môn học điều kiện"
            label-for="conditionSubject"
        >
          <v-select
              v-model="targetItem.subjectConditionId"
              multiple
              :options="subjectDropdown"
              :reduce="(option) => option.value"
          />
        </b-form-group>

        <b-form-group label-for="minSubject">
          <template v-slot:label>
            Số lượng môn học tối thiểu cần đạt <span class="text-danger">*</span>
          </template>
          <validation-provider
              #default="{ errors }"
              name="Số lượng môn học tối thiểu"
              rules="required"
          >
            <b-form-input
                id="minSubject"
                v-model="targetItem.minSubject"
                type="number"
                placeholder="Số lượng môn học tối thiểu"
                :state="getElementState(errors)"
            />
            <b-form-invalid-feedback :state="getElementState(errors)">
              {{ errors[0] }}
            </b-form-invalid-feedback>
          </validation-provider>
        </b-form-group>
        <b-form-group label-for="mincore">
          <template v-slot:label>
            Điểm số tối thiểu cần đạt <span class="text-danger">*</span>
          </template>
          <validation-provider
              #default="{ errors }"
              name="Điểm số tối thiểu"
              rules="required"
          >
            <b-form-input
                id="minScore"
                v-model="targetItem.minScore"
                type="number"
                placeholder="Điểm số tối thiểu"
                :state="getElementState(errors)"
            />
            <b-form-invalid-feedback :state="getElementState(errors)">
              {{ errors[0] }}
            </b-form-invalid-feedback>
          </validation-provider>
        </b-form-group>

        <b-form-group label-for="status">
          <template v-slot:label>
            Trạng thái <span class="text-danger">*</span>
          </template>
          <validation-provider
              #default="{ errors }"
              name="Trạng thái"
              rules="required"
          >
            <v-select
                v-model="targetItem.status"
                :options="statusOptions"
                :reduce="option => option.value"
            />
            <b-form-invalid-feedback :state="getElementState(errors)">
              {{ errors[0] }}
            </b-form-invalid-feedback>
          </validation-provider>
        </b-form-group>
      </b-form>
      <template #modal-footer>
        <div class="w-100 d-flex justify-content-end">
          <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-1"
              :disabled="invalid"
              @click="onSave('hide')"
          >
            <span class="text-right">
              <feather-icon icon="CheckIcon" /> Lưu lại
            </span>
          </b-button>

          <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="outline-secondary"
              @click="$bvModal.hide('credentialConditionSaveModal')"
          >
            <span class="text-right">
              <feather-icon icon="XIcon" /> Hủy
            </span>
          </b-button>
        </div>
      </template>
    </b-modal>
  </validation-observer>
</template>
<script>
import {
  BButton, BForm, BFormDatepicker, BFormGroup, BFormInput, BFormInvalidFeedback, BModal, BOverlay,
} from 'bootstrap-vue'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import { getUser } from '@/auth/utils'
import { mapActions, mapGetters } from 'vuex'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
export default {
  name: 'CredentialConditionSave',
  components: {
    BFormDatepicker,
    BFormGroup,
    BFormInput,
    BModal,
    BForm,
    BOverlay,
    BFormInvalidFeedback,
    BButton,
    BFormDatepicker,
    vSelect,
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  props: {
    item: {
      type: Object,
      default: undefined,
    },
    currentCredentialId: {
      type: Number,
      default: undefined,
    },
  },
  data() {
    return {
      user: getUser(),
      idLoading: false,
      filter: {
        currentPage: 1,
        itemsPerPage: 10,
      },
      filterAll: {
        currentPage: 1,
        itemsPerPage: 1000,
      },
      filterSubject: {
        currentPage: 1,
        itemsPerPage: 2000,
        organizationId: getUser().orgId,
        departmentId: '',
        subjectTypeId: '',
        name: '',
        code: '',
        status: 1,
        sort: '',
      },
      targetItem: {
        name: '',
        description: '',
        credentialId: this.currentCredentialId,
        subjectTypeId: 0,
        subjectType: '',
        minSubject: 0,
        minScore: 0,
        subjectConditionId: null,
        status: 1,
        certificateImplementId: null,
      },
      subjectDropdown: [],
    }
  },
  computed: {
    ...mapGetters({
      statuses: 'credentialImplement/statuses',
      schoolYears: 'dropdown/schoolYears',
      subjectTypes: 'credentialCondition/subjectTypes',
      subjects: 'subject/subjects',
      certificateImplements: 'dropdown/certificateImplements',
    }),
    statusOptions() {
      return this.statuses
    },
    subjectTypeOptions() {
      return this.subjectTypes.map(item => ({
        value: item.id,
        label: item.name,
      }))
    },
    isCreated() {
      return !this.item
    },
  },
  async created() {
  },
  methods: {
    ...mapActions({
      createCredentialCondition: 'credentialCondition/createCredentialCondition',
      updateCredentialCondition: 'credentialCondition/updateCredentialCondition',
      readSubjects: 'subject/readSubjects',
      getCertificateImplement: 'dropdown/getCertificateImplement',
    }),
    async onShow() {
      if (this.item) {
        this.targetItem = { ...this.item }
      }
      await this.readSubjects(this.filterSubject)
      await this.getCertificateImplement(this.filterAll)
      this.subjectDropdown = this.subjects.map(item => ({
        value: item.id,
        label: item.name,
      }))
    },
    async onSave(type = null) {
      const valid = this.$refs
        .saveFormRef
        .validate()
      if (valid) {
        this.isLoading = true
        try {
          const response = this.isCreated
            ? await this.createCredentialCondition(this.targetItem)
            : await this.updateCredentialCondition(this.targetItem)
          if (response) {
            const {
              isSuccessful,
              message,
            } = response
            if (isSuccessful) {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: message,
                  icon: 'CheckIcon',
                  variant: 'success',
                },
              })
              if (type === 'hide') {
                this.$bvModal.hide('credentialConditionSaveModal')
              }
              this.$emit('succeed')
            } else {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: message,
                  icon: 'XCircleIcon',
                  variant: 'danger',
                },
              })
            }
          }
        } catch (e) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: `[${e.code}] ${e.message}`,
              icon: 'XCircleIcon',
              variant: 'danger',
            },
          })
        } finally {
          this.isLoading = false
        }
      }
    },
    onHide() {
      this.targetItem = {
        name: '',
        description: '',
        credentialId: 0,
        subjectTypeId: 0,
        subjectType: '',
        minSubject: 0,
        minScore: 0,
        subjectConditionId: null,
        status: 1,
        certificateImplementId: null,
      }
    },
    getElementState(errors) {
      return errors.length > 0 ? false : null
    },
  },
}
</script>

<style scoped lang="scss">

</style>
