<template src="./index.html">

</template>

<script>
import * as XLSX from 'xlsx';
import {
  BBreadcrumb, BButton, BFormFile, BCard, BCol, BContainer, BFormSelect, BOverlay, BPagination, BRow,
} from 'bootstrap-vue'
import {VueGoodTable} from "vue-good-table";
import ToastificationContent from "@core/components/toastification/ToastificationContent";
import '@core/scss/vue/libs/vue-good-table.scss'
import {mapActions, mapGetters} from "vuex";
import { Workbook } from "@syncfusion/ej2-excel-export";

export default {
  name: "ProgrammeSubjectImport",
  components : {
    BBreadcrumb,
    BButton,
    BFormFile,
    BCard,
    BCol,
    BContainer,
    BFormSelect,
    BOverlay,
    BPagination,
    BRow,
    XLSX,
    VueGoodTable,
    ToastificationContent
  },

  props: {
    dataSend: {
      type: Object,
      default: {},
    },
  },
  data() {
    return {
      table: {
        fields: [
          {field: 'stt', label: 'STT', sortable: false, type: "string",},
          {field: 'maHocPhan', label: 'Mã môn học', sortable: false, type: "string",},
          {field: 'maHocKy', label: 'Mã học kỳ', sortable: false, type: "string",},
          {field: 'nhomMonHoc', label: 'Nhóm môn học', sortable: false, type: "string",},
          {field: 'tinChiToiThieu', label: 'Số TC', sortable: false, type: "string",},
          {field: 'isOptional', label: 'Bắt buộc', sortable: false, type: "string",},
        ],
        items: [],
      },
      tenFile: "",
      dataExcel: [],
      dataExcelHandle: [],
      serverParams: {
        perPage: 10,
        page: 1
      },
      arrCode: [],
      totalRecords: 0,
      loading: false
    }
  },

  computed : {
    ...mapGetters({
      total: "rooms/total",

    }),
    // calculate data from init data
  },

  watch : {
    // listening change data init

  },
  methods : {
    ...mapActions({
      importProgrammeSubject: "programmeSubject/importProgrammeSubject",
    }),
    async importExcel(e) {
      const files = e.target.files;
      if (!files.length) {
        return;
      } else if (!/\.(xls|xlsx)$/.test(files[0].name.toLowerCase())) {
        // show err
      }
      if( files[0].name ) {
        this.tenFile = files[0].name
      }
      this.readFile(files);
    },

    readFile(files) {
      const fileReader = new FileReader();
      fileReader.onload = async (ev) => {
        try {
          const data = ev.target.result;
          const workbook = XLSX.read(data, {
            type: "binary",
          });
          const wsname = workbook.SheetNames[0];
          const ws = XLSX.utils.sheet_to_json(workbook.Sheets[wsname],{
            header: 0,
            defval: ""
          });
          const excellist = [];
          // Edit data
          if (ws.length > 0) {
            for (var i = 0; i < ws.length; i++) {
              excellist.push(ws[i]);
            }
          } else {
            this.showToast("File không có dữ liệu", 'XCircleIcon',"danger", "");
            return ;
          }
          if (
              !excellist[0].hasOwnProperty("MA_HOC_PHAN")
              || !excellist[0].hasOwnProperty("MA_HOC_KY")
              || !excellist[0].hasOwnProperty("NHOM_MON_HOC")
              || !excellist[0].hasOwnProperty("TC_TOI_THIEU")
              || !excellist[0].hasOwnProperty("BAT_BUOC")
          ) {
            this.showToast("File chưa đúng định dạng", 'XCircleIcon',"danger", "")
            return;
          }

          this.dataExcel = [];
          for (let k = 0; k < excellist.length; k++) {
            let item = excellist[k];
            let obj = {
              stt: k+1,
              maHocPhan: item.MA_HOC_PHAN,
              maHocKy: item.MA_HOC_KY,
              nhomMonHoc: item.NHOM_MON_HOC,
              tinChiToiThieu: item.TC_TOI_THIEU,
              programmeId: this.dataSend.id,
              isOptional: item.BAT_BUOC ? item.BAT_BUOC : 0,
            };
            this.dataExcel.push(obj);
          }
          this.table.items = this.dataExcel
          this.totalRecords = this.dataExcel.length

          // insert ban ghi
        } catch (e) {
          return alert("Read failure!" + e);
        }
      };
      fileReader.readAsBinaryString(files[0]);
    },
    toFindDuplicates(array){
      return array.filter((item, index) => array.indexOf(item) !== index)
    },
    toRemoveDuplicate(array){
      return [...new Set(array)];
    },
    resetInputFile() {
      this.$refs.inputFileRef.reset()
    },

    async importStudent(){
      let data = this.dataExcel;
      await this.handleImportStudentCreditClass(data);
    },

    async handleImportStudentCreditClass(data){
      this.loading = true;
      let res = await this.importProgrammeSubject(data);
      this.loading = false;
      if (res.code == 1){
        this.showToast("Lưu thành công", 'CheckIcon',"success", "" );
        this.resetInputFile();
        this.$emit('succeed')
      }else{
        this.showToast("Có lỗi xảy ra", 'XCircleIcon',"danger", res.message )
        this.resetInputFile();
      }
    },


    downloadFile(){
      let columns = [
        { index: 1, width: 130 },
        { index: 2, width: 130 },
        { index: 3, width: 130 },
        { index: 4, width: 130 },
        { index: 5, width: 130 }

      ];
      let rows = [];
      let headerRow = {
        index: 1,
        cells: [
          {
            index: 1,
            value: "MA_HOC_PHAN",
            colSpan: 1,
            style: {
              borders: {
                color: "#00000000",
                lineStyle: "thin",
              },
            },
          },
          {
            index: 2,
            value: "MA_HOC_KY",
            colSpan: 1,
            style: {
              borders: {
                color: "#00000000",
                lineStyle: "thin",
              },
            },
          },
          {
            index: 3,
            value: "NHOM_MON_HOC",
            colSpan: 1,
            style: {
              borders: {
                color: "#00000000",
                lineStyle: "thin",
              },
            },
          },
          {
            index: 4,
            value: "TC_TOI_THIEU",
            colSpan: 1,
            style: {
              borders: {
                color: "#00000000",
                lineStyle: "thin",
              },
            },
          },
          {
            index: 5,
            value: "BAT_BUOC",
            colSpan: 1,
            style: {
              borders: {
                color: "#00000000",
                lineStyle: "thin",
              },
            },
          }
        ],
      };

      rows.push(headerRow);
      let worksheets = [{ columns, rows }];
      let workbook = new Workbook({ worksheets }, "xlsx");

      workbook.save("template.xlsx");
    },
    // handle even

    showToast(title, icon, variant, text) {
      this.$toast({
        component: ToastificationContent,
        position: "top-right",
        props: {
          title: title,
          icon: icon,
          variant: variant,
          text: text,
        },
      });
    },

  }
}
</script>

<style scoped>

</style>
