<template>
  <div class="row">
    <b-container fluid>
      <b-card no-body>
        <b-overlay
          :show="isLoading"
          rounded="sm"
        >
          <div class="m-2">
            <validation-observer
              ref="checkExaminationFormRef"
              #default="{invalid}"
            >
              <b-form>
                <b-row>
                  <b-col
                    sm="12"
                    md="4"
                    lg="4"
                    xl="4"
                  >
                    <b-form-group
                      label="Hệ đào tạo"
                      label-for="trainingSystem"
                    >
                      <v-select
                        id="trainingSystem"
                        v-model="filter.trainingSystemId"
                        label="name"
                        :options="trainingSystems"
                        :reduce="option => option.id"
                        :clearable="false"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col
                    sm="12"
                    md="4"
                    lg="4"
                    xl="4"
                  >
                    <b-form-group
                      label="Khóa học"
                      label-for="course"
                    >
                      <v-select
                        id="course"
                        v-model="filter.courseId"
                        label="name"
                        :options="courses"
                        :reduce="option => option.id"
                        :clearable="false"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col
                    sm="12"
                    md="4"
                    lg="4"
                    xl="4"
                  >
                    <b-form-group
                      label="Học kỳ"
                      label-for="semester"
                    >
                      <v-select
                        id="semester"
                        v-model="filter.courseSemesterId"
                        :options="semesters"
                        :reduce="option => option.value"
                        :clearable="false"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col
                    sm="12"
                    md="4"
                    lg="4"
                    xl="4"
                  >
                    <b-form-group
                      label="Môn học"
                      label-for="subject"
                    >
                      <v-select
                        id="subject"
                        v-model="filter.subjectCode"
                        label="name"
                        :options="courseSemesterSubject"
                        :reduce="option => option.code"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col
                    sm="12"
                    md="4"
                    lg="4"
                    xl="4"
                  >
                    <b-form-group
                      label="Số báo danh"
                      label-for="bibNumber"
                    >
                      <b-form-input
                        id="Số báo danh"
                        v-model="filter.bibNumber"
                        name="bibNumber"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col
                    sm="12"
                    md="4"
                    lg="4"
                    xl="4"
                  >
                    <b-form-group
                      label="Trạng thái"
                      label-for="status"
                    >
                      <v-select
                        id="status"
                        v-model="filter.status"
                        :options="statusOptions"
                        :reduce="option => option.value"
                        :clearable="false"
                      />
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col cols="12">
                    <div class="w-100 d-flex justify-content-center">
                      <b-button
                        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                        variant="primary"
                        class="mr-1"
                        @click="getDataSourcesFromStore"
                      >
                        <span class="text-nowrap text-right">
                          <feather-icon icon="SearchIcon" /> Tìm kiếm
                        </span>
                      </b-button>

                      <b-button
                        v-show="checkExaminationPaperCreatable"
                        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                        variant="success"
                        :disabled="invalid || dataSources.length === 0"
                        @click="onSave"
                      >
                        <span class="text-nowrap text-right">
                          <feather-icon icon="CheckIcon" /> Lưu điểm
                        </span>
                      </b-button>
                    </div>
                  </b-col>
                </b-row>
                <b-row class="mt-2">
                  <b-col cols="12">
                    <vue-good-table
                      :columns="columns"
                      :rows="dataSources"
                      :pagination-options="paginationOptions"
                      :line-numbers="true"
                    >
                      <div
                        slot="emptystate"
                        style="text-align: center; font-weight: bold"
                      >
                        Không có bản ghi nào !
                      </div>

                      <template
                        slot="table-row"
                        slot-scope="props"
                      >
                        <template v-if="props.row.status === inProgressCheckExamination">
                          <span v-if="props.column.field === 'newScore'">
                            <validation-provider
                              #default="{ errors }"
                              name="Điểm"
                              rules="required|between:0,10"
                            >
                              <b-form-input
                                v-model.number="props.row.newScore"
                                type="number"
                                :state="getElementState(errors)"
                                @change="changeCell(props.row.newScore, props.column.field, props.row.originalIndex)"
                              />
                              <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                          </span>
                          <span v-else-if="props.column.field === 'examinerOne'">
                            <validation-provider
                              #default="{ errors }"
                              name="Giám khảo 1"
                              rules="required"
                            >
                              <Select2
                                v-model="props.row.examinerOne"
                                :options="teacherOptions"
                                @change="changeCell(props.row.examinerOne, props.column.field, props.row.originalIndex)"
                              />
                              <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                          </span>
                          <span v-else-if="props.column.field === 'examinerTwo'">
                            <validation-provider
                              #default="{ errors }"
                              name="Giám khảo 2"
                              rules="required"
                            >
                              <Select2
                                v-model="props.row.examinerTwo"
                                :options="teacherOptions"
                                @change="changeCell(props.row.examinerTwo, props.column.field, props.row.originalIndex)"
                              />
                              <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                          </span>
                          <span v-else-if="props.column.field === 'note'">
                            <b-form-input
                              v-model="props.row.note"
                              @change="changeCell(props.row.note, props.column.field, props.row.originalIndex)"
                            />
                          </span>
                          <span v-else>{{ props.formattedRow[props.column.field] }}</span>
                        </template>
                        <span v-else-if="props.column.field === 'examinerOne'">
                          {{ getTeacherById(props.row.examinerOne) }}
                        </span>
                        <span v-else-if="props.column.field === 'examinerTwo'">
                          {{ getTeacherById(props.row.examinerTwo) }}
                        </span>
                        <!-- Column: Common -->
                        <span v-else>{{ props.formattedRow[props.column.field] }}</span>
                      </template>

                      <!-- pagination -->
                      <template
                        slot="pagination-bottom"
                        slot-scope="props"
                      >
                        <div class="d-flex justify-content-between flex-wrap">
                          <div class="d-flex align-items-center mb-0 mt-1">
                            <span class="text-nowrap"> Hiển thị 1 đến </span>
                            <b-form-select
                              v-model="itemsPerPage"
                              :options="itemsPerPageOptions"
                              class="mx-1"
                              @input="(value) => props.perPageChanged({ currentPerPage: value })"
                            />
                            <span class="text-nowrap">của {{ dataSources.length }} bản ghi</span>
                          </div>
                          <div>
                            <b-pagination
                              :value="1"
                              :total-rows="props.total"
                              :per-page="itemsPerPage"
                              class="mt-1 mb-0"
                              @input="(value) => props.pageChanged({ currentPage: value })"
                            />
                          </div>
                        </div>
                      </template>
                    </vue-good-table>
                  </b-col>
                </b-row>
              </b-form>
            </validation-observer>
          </div>
        </b-overlay>
      </b-card>
    </b-container>
  </div>
</template>

<script>
import Ripple from 'vue-ripple-directive'
import {
  BButton,
  BCard,
  BCol,
  BContainer,
  BFormSelect,
  BOverlay,
  BPagination,
  BRow,
  BForm,
  BFormGroup,
  BFormInput,
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import '@core/scss/vue/libs/vue-good-table.scss'
import { mapActions, mapGetters, mapMutations } from 'vuex'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { getUser } from '@/auth/utils'
import vSelect from 'vue-select'
import { hasPermissionForResource, isSystemAdmin } from '@/utils'
import { PermissionCode, ResourceCode } from '@/const/code'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { between, required } from '@validations'
import Select2 from 'v-select2-component'
import { CHECK_EXAMINATION_PAPERS } from '@/const/status'
import { Flag } from '@/const/flag'

export default {
  name: 'CheckExaminationPaper',
  directives: {
    Ripple,
  },
  components: {
    BButton,
    BCard,
    BCol,
    BContainer,
    BFormSelect,
    BOverlay,
    BPagination,
    BRow,
    BForm,
    BFormGroup,
    BFormInput,
    VueGoodTable,
    vSelect,
    ValidationObserver,
    ValidationProvider,
    Select2,
  },
  data() {
    return {
      user: getUser(),
      isLoading: false,
      filter: {
        organizationId: getUser().orgId,
        trainingSystemId: null,
        courseId: null,
        courseSemesterId: null,
        subjectId: null,
        subjectCode: null,
        bibNumber: null,
        status: Flag.IN_PROGRESS_CHECK_EXAMINATION,
        isSeparate: null,
      },
      inProgressCheckExamination: Flag.IN_PROGRESS_CHECK_EXAMINATION,
      itemsPerPage: 20,
      itemsPerPageOptions: [10, 20, 30, 50, 80, 100],
      columns: [
        {
          label: 'Họ đệm',
          field: 'lastName',
          thClass: 'text-center',
        },
        {
          label: 'Tên',
          field: 'firstName',
          thClass: 'text-center',
        },
        {
          label: 'Điểm cũ',
          field: 'oldScore',
          width: '10%',
          thClass: 'text-center',
        },
        {
          label: 'Điểm mới',
          field: 'newScore',
          width: '10%',
          thClass: 'text-center',
        },
        {
          label: 'Giám khảo một',
          field: 'examinerOne',
          thClass: 'text-center',
        },
        {
          label: 'Giám khảo hai',
          field: 'examinerTwo',
          thClass: 'text-center',
        },
        {
          label: 'Ghi chú',
          field: 'note',
          thClass: 'text-center',
        },
      ],
      required,
      between,
    }
  },
  computed: {
    ...mapGetters({
      dataSources: 'studentExaminationRoom/checkExaminationDataSources',
      trainingSystems: 'trainingSystem/trainingSystems',
      courses: 'course/courses',
      semesters: 'dropdown/courseSemesters',
      teachers: 'teacher/teachers',
      courseSemesterSubject: 'examinationRoom/listCourseSemesterSubject',
    }),
    paginationOptions() {
      return {
        enabled: true,
        perPage: this.itemsPerPage,
      }
    },
    checkExaminationPaperCreatable() {
      return (isSystemAdmin() || hasPermissionForResource(PermissionCode.CREATE, ResourceCode.CHECK_EXAMINATION_PAPER))
        && this.filter.status === Flag.IN_PROGRESS_CHECK_EXAMINATION
    },
    teacherOptions() {
      return this.teachers.map(element => ({ id: element.id, text: element.name }))
    },
    isValidSearch() {
      return !!(this.filter.trainingSystemId
        && this.filter.courseId
        && this.filter.courseSemesterId
      )
    },
    statusOptions() {
      return CHECK_EXAMINATION_PAPERS
    },
    selectedSubjectId() {
      if (!this.filter.subjectCode) return null
      const found = this.courseSemesterSubject.find(e => e.code === this.filter.subjectCode)
      return found ? found.id : null
    },
    isSeparateRoom() {
      if (!this.filter.subjectCode) return null
      return this.filter.subjectCode.includes('_THCLASS_') ? 1 : 0
    },
  },
  watch: {
    'filter.trainingSystemId': async function (val) {
      if (val) {
        this.isLoading = true
        try {
          this.updateParams({ courseId: null })
          await this.getCoursesByTrainingSystemId({ organizationId: getUser().orgId, trainingSystemId: val })
          if (this.courses.length > 0) {
            this.updateParams({ courseId: this.courses[0].id })
          } else {
            this.updateParams({
              courseId: null,
              courseSemesterId: null,
              subjectId: null,
            })
            this.setCourses([])
            this.setSemesters([])
            this.setSubjects([])
          }
        } catch (e) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: `[${e.code}] ${e.message}`,
              icon: 'XCircleIcon',
              variant: 'danger',
            },
          })
        } finally {
          this.isLoading = false
        }
      }
    },
    'filter.courseId': async function (val) {
      if (val) {
        this.isLoading = true
        try {
          this.updateParams({ courseSemesterId: null })
          await this.getSemestersByCourseId({ organizationId: getUser().orgId, courseId: val })
          if (this.semesters.length > 0) {
            this.updateParams({ courseSemesterId: this.semesters[0].value })
          } else {
            this.updateParams({
              courseSemesterId: null,
              subjectId: null,
            })
            this.setSemesters([])
            this.setSubjects([])
          }
        } catch (e) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: `[${e.code}] ${e.message}`,
              icon: 'XCircleIcon',
              variant: 'danger',
            },
          })
        } finally {
          this.isLoading = false
        }
      }
    },
    'filter.courseSemesterId': async function (val) {
      if (val) {
        this.isLoading = true
        try {
          this.updateParams({ subjectId: null, subjectCode: null })
          this.filter.subjectId = null
          this.filter.subjectCode = null
          await this.getCourseSemesterSubjectSelect({
            organizationId: getUser().orgId,
            courses_semester_id: val,
            hasExam: 1,
          })
        } catch (e) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: `[${e.code}] ${e.message}`,
              icon: 'XCircleIcon',
              variant: 'danger',
            },
          })
        } finally {
          this.isLoading = false
        }
      } else {
        this.updateParams({ subjectId: null })
        this.setSubjects([])
      }
    },
    'filter.status': async function (val) {
      await this.getDataSourcesFromStore()
    },
  },
  async created() {
    this.updateParams({ organizationId: this.user.orgId })
    this.isLoading = true
    try {
      await Promise.all([
        this.getTeachersByOrganizationId(),
        this.getTrainingSystemsByOrganizationId({ organizationId: this.user.orgId }),
      ])
      if (this.trainingSystems.length > 0) {
        this.updateParams({ trainingSystemId: this.trainingSystems[0].id })
      }
    } catch (e) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: `[${e.code}] ${e.message}`,
          icon: 'XCircleIcon',
          variant: 'danger',
        },
      })
    } finally {
      this.isLoading = false
    }
  },
  methods: {
    ...mapMutations({
      setCourses: 'course/SET_COURSES',
      setSemesters: 'dropdown/SET_COURSE_SEMESTERS',
      setSubjects: 'examinationRoom/SET_COURSE_SEMESTER_SUBJECT',
    }),
    ...mapActions({
      getCheckExaminationPapers: 'studentExaminationRoom/getCheckExaminationPapers',
      getTrainingSystemsByOrganizationId: 'trainingSystem/getTrainingSystemsByOrganizationId',
      getCoursesByTrainingSystemId: 'course/getCoursesByTrainingSystemId',
      getSemestersByCourseId: 'dropdown/getCourseSemesters',
      createCheckExaminationPaper: 'studentExaminationRoom/createCheckExaminationPaper',
      getTeachersByOrganizationId: 'teacher/getTeachersByOrganizationId',
      getCourseSemesterSubjectSelect: 'examinationRoom/getCourseSemesterSubject',
    }),
    updateParams(newProps) {
      this.filter = { ...this.filter, ...newProps }
    },
    async getDataSourcesFromStore() {
      if (!this.isValidSearch) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Vui lòng chọn đủ dữ liệu tìm kiếm',
            icon: 'InfoIcon',
            variant: 'warning',
          },
        })
        return
      }
      this.isLoading = true
      try {
        this.updateParams({ subjectId: this.selectedSubjectId, isSeparate: this.isSeparateRoom })
        await this.getCheckExaminationPapers(this.filter)
      } catch (e) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: `[${e.code}] ${e.message}`,
            icon: 'XCircleIcon',
            variant: 'danger',
          },
        })
      } finally {
        this.isLoading = false
      }
    },
    getElementState(errors) {
      return errors.length > 0 ? false : null
    },
    changeCell(data, column, row) {
      this.dataSources[row][column] = data
    },
    async onSave() {
      const valid = await this.$refs
        .checkExaminationFormRef
        .validate()
      if (valid) {
        const checkExaminationPapers = this.dataSources.map(element => ({
          studentExaminationRoomId: element.studentExaminationRoomId,
          studentCheckExaminationId: element.studentCheckExaminationId,
          studentCreditClassId: element.studentCreditClassId,
          oldScore: element.oldScore,
          newScore: element.newScore,
          examinerOne: element.examinerOne,
          examinerTwo: element.examinerTwo,
          note: element.note,
          logCheckExaminationId: element.logCheckExaminationId,
        }))
        this.isLoading = true
        try {
          const { isSuccessful, message } = await this.createCheckExaminationPaper({ checkExaminationPapers })
          if (isSuccessful) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: message,
                icon: 'CheckIcon',
                variant: 'success',
              },
            })
            await this.getDataSourcesFromStore()
          } else {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: message,
                icon: 'XCircleIcon',
                variant: 'danger',
              },
            })
          }
        } catch (e) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: `[${e.code}] ${e.message}`,
              icon: 'XCircleIcon',
              variant: 'danger',
            },
          })
        } finally {
          this.isLoading = false
        }
      }
    },
    getTeacherById(id) {
      if (!id || this.teachers.length === 0) return ''
      const found = this.teachers.find(teacher => teacher.id === id)
      return found ? found.name : ''
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
